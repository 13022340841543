import { Box } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import UserProfile from './UserProfile';

const LoggedUserProfile = () => {
    const menuDispatch = useDispatch();
    menuDispatch({
        type: 'menuSelect',
        payload: 'logged-user-profile'
    });
  return (
    <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
        <UserProfile />
    </Box>
  );
}

export default LoggedUserProfile;
