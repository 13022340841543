import { useState, useEffect } from 'react';
import { Badge,Card,Chip, Typography, Divider,MenuItem, Stack, TextField, Grid, Button, Box, Toolbar, TableCell, FormControl, InputLabel, Select, Tooltip, IconButton, Modal, Switch, Avatar, styled, InputAdornment } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Swal from 'sweetalert2';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { isMobile } from 'react-device-detect';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import { useForm, Controller } from 'react-hook-form';
import DeleteIcon from '@mui/icons-material/Delete';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SearchIcon from '@mui/icons-material/Search';
import { useLocation, useNavigate } from 'react-router-dom';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Helpers } from '../../../services/Helpers';
import AddMember from './AddMember';
import { RoleAndPermissionServices } from '../RoleAndPermissionServices';
import { PermissionGuard } from '../../../components/PermissionGuard';
import { Close, PanoramaSharp } from '@mui/icons-material';
import PageHeaderLayout from '../../../components/Layout/PageHeaderLayout';

const style = {
    position: 'absolute',
    top: (isMobile) ? '80%' : '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #ddd',
    boxShadow: 24,
    p: 2,
};
// Define the keyframes animation
const rippleAnimation = {
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    }
  };
  
const MemberList = () => {
    
    const { control, handleSubmit, reset, setValue, getValues, formState: { errors } } = useForm({
        mode: "onChange"
    });
    const [isEditable, setIsEditable] = useState(null);
    const onCancelRoleEdit = ()=>{
      setIsEditable(0);
    }
    
    /************************  selected service/subservice data for edit**************************** */

    const [allRowCount, setAllRowCount] = useState(0);
    const [list, setList] = useState([]);
    const [deleteRowData, setDeleteRowData] = useState({});
    const [controller, setController] = useState({
        page: 0,
        pageSize: 20,
        filters: {
        searchKey: '',
        status: '',
        roleId:''
        }
    });
    const clearFilters = () => {
      reset();
      setController({
          ...controller,
          filters: {
              ...controller.filters,
              searchKey: "",
              status: "",
              roleId:'',
          }
      });
    }
    function submitFilters(data) {
      const filtersData = getValues();
      setController({
          ...controller,
          filters: {
              ...controller.filters,
              searchKey: filtersData.searchKey,
              status: filtersData.status,
              roleId: filtersData.roleId,
          }
      });
  }
    const handlePaginationChange = (newPagination) => {
      setController({
          ...controller,
          page: newPagination.page,
          pageSize: newPagination.pageSize
      });
  }

  const [loading, setLoading] = useState(0);

  const fetchMemberList = async ()=>{
    try {
        setLoading(true)
        const listResponse = await RoleAndPermissionServices.memberList({
          page: controller.page+1,
          limit: controller.pageSize,
          filters: {
            searchKey: controller.filters.searchKey,   // Use the title filter from the controller
            status: controller.filters.status,
            roleId: controller.filters.roleId,
          }
        });
        // console.log("Member list Response:",listResponse)
        setValue('status', controller.filters.status)
        if (listResponse.success=='1') {
          setList(listResponse.data?.customers || []);
          setAllRowCount(listResponse.data.totalItems);
        } 
        else {
        //   Swal.fire('Request failed','','error')
        }
      } catch (error) {
        Swal.fire(error.message,'','error')
      }
      setLoading(false)
    };
  
  /************************  Manage Use Effect **************************** */
  useEffect(() => {
    fetchMemberList();
  }, [controller, deleteRowData]);

  
    /************************  Table Column Manage **************************** */
    const columns = [
        {
          field: 'member',
          headerName: 'Members',
          headerAlign:'center',
          flex: 2,
          valueGetter: (params) => {
            const firstName = params.row?.firstName || '';
            const lastName = params.row?.lastName || '';
            const email = params.row?.email || '';
            
            // Combine all profile information into a single searchable string
            return `${firstName} ${lastName} ${email} `.trim();
          },
          renderCell: (params) => (
            <Box sx={{ p: 1,display:'flex',gap:'5%',justifyContent:'flex-start',alignItems:'flex-start',width:'100%' }}>
              <Badge
                  sx={{
                      '& .MuiBadge-badge': {
                      backgroundColor: params.row.isActive?'#44b700':'red',
                      color: params.row.isActive?'#44b700':'red',
                      boxShadow: (theme) => `0 0 0 2px ${theme.palette.background.paper}`,
                      '&::after': {
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          borderRadius: '50%',
                          animation: 'ripple 1.2s infinite ease-in-out',
                          border: '1px solid currentColor',
                          content: '""',
                      },
                      },
                      // Include the keyframes animation
                      ...rippleAnimation
                  }}
                  variant="dot"
                  // other badge props
                  >
                  <Avatar alt={params.row.id+'_image'} src={params.row.image} />
              </Badge>
              <Box>
                <Typography variant='body2' sx={{textTransform:'capitalize',fontWeight:'600'}}>{params.row.firstName} {params.row.lastName}</Typography>     
                <Typography variant='body2'>{params.row.email}</Typography>
              </Box>
            </Box>
          ),
        },     
        { 
            field: 'roles', 
            headerName: 'Roles', 
            headerAlign: 'center',
            flex: 1.5,
            valueGetter: (params) => {
              const roleName = params.row.role.title;
              
              // Combine all information into a single searchable string
              return `${roleName}`.trim();
            },
            renderCell: (params) => {
              const handleRoleChange = async (event) => {
                const roleId = event.target.value;
                const result = await Swal.fire({
                  text: "Do you want to Change Role now?",
                  icon: 'question',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Yes, Change now!',
                  cancelButtonText: 'No, Cancel',
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  showCloseButton: false,
                });
              
                if (result.isConfirmed) {
                  try {
                    // Show loading state
                    Swal.fire({
                      title: 'Processing...',
                      text: 'Please wait while we assign the role',
                      allowOutsideClick: false,
                      allowEscapeKey: false,
                      showConfirmButton: false,
                      didOpen: () => {
                        Swal.showLoading();
                      }
                    });
              
                    const updateRelatedData = {
                      roleId:roleId,
                      memberId:params.row.id,
                    }
                    // Make the API call
                    const statusChangeResponse = await RoleAndPermissionServices.changeMemberRole(updateRelatedData);
                    // console.log("role status change response:", statusChangeResponse);
              
                    // Close loading dialog and show success/error message
                    if (statusChangeResponse.success === 1) {
                      await fetchMemberList();
                      setIsEditable(0);
                      await Swal.fire({
                        icon: 'success',
                        title: 'Success!',
                        text: statusChangeResponse.message || 'Role Changed updated successfully',
                        timer: 1500
                      });
                      
                      return true;
                    } else {
                      setIsEditable(0);
                      await Swal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: statusChangeResponse.message || 'Failed to Change Role'
                      });
                      return false;
                    }
              
                  } catch (error) {
                    // console.error("Error changing Role :", error);
                    setIsEditable(0);
                    await Swal.fire({
                      icon: 'error',
                      title: 'Error!',
                      text: 'An error occurred while Changing the role'
                    });
                    return false;
                  }
                } else {
                  return false;
                }
              };     
              return (
                <Box sx={{ p: 1, minWidth: 200, textAlign: 'center' }}>
                  {isEditable === params.row.id ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <FormControl size="small" sx={{ width: '70%' }}>
                        <Select
                          value={params.row.roleId || 'guest'}
                          onChange={handleRoleChange}
                        >
                          {roleList.map((role, index) => (
                            role.isActive && (
                              <MenuItem 
                                value={role.id} 
                                sx={{ textTransform: 'uppercase' }} 
                                key={index}
                              >
                                {role.title}
                              </MenuItem>
                            )
                          ))}
                        </Select>
                      </FormControl>
                      <IconButton 
                        onClick={()=>setIsEditable(0)}
                        size="small"
                        sx={{ 
                          color: 'error.main',
                          '&:hover': { 
                            backgroundColor: 'error.lighter'
                          } 
                        }}
                      >
                        <Close sx={{ fontSize: '1.2rem' }} />
                      </IconButton>
                    </Box>
                  ) : (
                    <TextField
                      sx={{ width: '70%' }}
                      value={params.row.role.title}
                      size='small'
                      InputProps={{
                        readOnly: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <PermissionGuard feature='register-admin' permission='canEdit'>
                            <IconButton 
                              onClick={() => setIsEditable(params.row.id)}
                              edge="end"
                              size="small"
                              sx={{ 
                                '&:hover': { 
                                  backgroundColor: 'rgba(0, 0, 0, 0.04)' 
                                } 
                              }}
                            >
                              <EditIcon sx={{ fontSize: '1.2rem' }} />
                            </IconButton>
                            </PermissionGuard>
                          </InputAdornment>
                        )
                      }}
                    />
                  )}
                </Box>
              );
            },
        },
        { 
          field: 'isActive', 
          headerName: 'Status', 
          headerAlign:'center',
          flex: 1,
          // filterable: false,
          renderCell: (params) => {
            const handleStatusChange = async () => {
              const result = await Swal.fire({
                text: "Do you want to Change Status now?",
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Change now!',
                cancelButtonText: 'No, Cancel',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showCloseButton: false,
              });
            
              if (result.isConfirmed) {
                try {
                  // Show loading state
                  Swal.fire({
                    title: 'Processing...',
                    text: 'Please wait while we update the status',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    showConfirmButton: false,
                    didOpen: () => {
                      Swal.showLoading();
                    }
                  });
            
                  // Make the API call
                  const statusChangeResponse = await RoleAndPermissionServices.changeMemberStatus(params.row.id);
                  // console.log("role status change response:", statusChangeResponse);
            
                  // Close loading dialog and show success/error message
                  if (statusChangeResponse.success === 1) {
                    await fetchMemberList();
                    await Swal.fire({
                      icon: 'success',
                      title: 'Success!',
                      text: statusChangeResponse.message || 'Status updated successfully',
                      timer: 1500
                    });
                    
                    return true;
                  } else {
                    await Swal.fire({
                      icon: 'error',
                      title: 'Error!',
                      text: statusChangeResponse.message || 'Failed to update status'
                    });
                    return false;
                  }
            
                } catch (error) {
                  // console.error("Error changing role status:", error);
                  await Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'An error occurred while updating the status'
                  });
                  return false;
                }
              } else {
                return false;
              }
            };
            return(
              <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
              <PermissionGuard feature='register-admin' permission='canEdit'>
                <Switch
                  checked={params.row.isActive}
                  onChange={handleStatusChange}
                  sx={{
                    '& .MuiSwitch-switchBase.Mui-checked': {
                        color: 'success.main',
                        // '&:hover': {
                        //     backgroundColor: 'success.light'
                        // }
                    },
                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                        backgroundColor: 'success.main'
                    },
                    '& .MuiSwitch-switchBase': {
                        color: 'error.main',
                        // '&:hover': {
                        //     backgroundColor: 'error.light'
                        // }
                    },
                    '& .MuiSwitch-track': {
                        backgroundColor: 'error.main'
                    }
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                </PermissionGuard>
                <Typography
                  variant="body2"
                  sx={{
                    color: params.row?.isActive ? 'success.main' : 'error.main',
                    fontWeight: 'medium'
                  }}
                >
                  {params.row?.isActive ? 'Active' : 'Inactive'}
                </Typography>   
            </Box>
          )},
        },
        {
          field: 'actions',
          headerName: 'Actions',
          headerAlign: 'center',
          flex: 1,
          filterable: false,
          renderCell: (params) => {
            
            const  handleMemberDelete= async (roleId) => {
              const result = await Swal.fire({
                text: "Do you want to Delete Role now?",
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Delete now!',
                cancelButtonText: 'No, Cancel',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showCloseButton: false,
              });
            
              if (result.isConfirmed) {
                try {
                  // Show loading state
                  Swal.fire({
                    title: 'Processing...',
                    text: 'Please wait while we deleting member',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    showConfirmButton: false,
                    didOpen: () => {
                      Swal.showLoading();
                    }
                  });
            
                  // Make the API call
                  const memberDeleteResponse = await RoleAndPermissionServices.deleteMember(params.row.id);
                  // console.log("member delete response:", memberDeleteResponse);
            
                  // Close loading dialog and show success/error message
                  if (memberDeleteResponse.success === 1) {
                    await fetchMemberList();
                    await Swal.fire({
                      icon: 'success',
                      title: 'Success!',
                      text: memberDeleteResponse.message || 'member deleted successfully',
                      timer: 1500
                    });
                    
                    return true;
                  } else {
                    await Swal.fire({
                      icon: 'error',
                      title: 'Error!',
                      text: memberDeleteResponse.message || 'Failed to delete member'
                    });
                    return false;
                  }
            
                } catch (error) {
                  // console.error("Error deleting member:", error);
                  await Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'An error occurred while deleting the member'
                  });
                  return false;
                }
              } else {
                return false;
              }
            };
            return(
              <Box sx={{ p: 1, display: 'flex', justifyContent: 'space-between' }}>
                <PermissionGuard feature='register-admin' permission='canDelete'>
                  <IconButton onClick={() => handleMemberDelete(params.row.id)} size="small" color='error'>
                    <DeleteIcon />
                  </IconButton>
                </PermissionGuard>
            </Box>
            )
          }
        },
    ];
    
    const [openModel,setOpenModel] = useState(false);
    /************************ add/Edit/view Modal Manage **************************** */
    const modalOpen = (rowData,action) => {
        if(action=='add'){
        //   setSelectedServiceData({})
          setOpenModel(true);
        }else if(action=='edit'){
        //   manageData();
        setOpenModel(true)
        }
        
      }
    const handleClose=()=>{
      fetchMemberList();
      setOpenModel(false);
    }

    const [roleList,setRoleList] = useState([]);
    const fetchRoleList= async()=>{
        try {
            const roleListResponse = await RoleAndPermissionServices.roleList();
            // console.log("fetching role list response:",roleListResponse);
            const data = roleListResponse.data;
            if(roleListResponse.success=='1'){
              setRoleList(data);
            }
        } catch (error) {
          
        }
    }
    
    /*================= on component load==================== */
    
    useEffect(() => {
      // fetchMemberList();
      fetchRoleList();
    }, []);
    return (
        <>
        {/* =========== pop up models like edit services and sub services=============== */} 
        <Modal
          open={openModel}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ overflow: "scroll" }}
        >
          <Box sx={style} width={(isMobile) ? "95%" : "50%"}>
              <AddMember
                  closeEvent={handleClose} 
                  memberData = {''}
              />
          </Box>
        </Modal>
        {/* ================= page title section and containing add service/sub service button============ */}
        <PageHeaderLayout headerTitle={'Manage Members'}>
          <PermissionGuard feature='register-admin' permission='canCreate'>
            <Button variant='contained' onClick={() => modalOpen({},'add')} sx={{ height: "30px", marginTop: "10px" }} startIcon={<AddCircleIcon />}>
                Add Member
            </Button>
          </PermissionGuard>
        </PageHeaderLayout>
        {/* <Card sx={{ minWidth: "100%", marginBottom: "10px" }}>
          <Stack direction="row" spacing={2} className="" alignItems={'center'} sx={{p:'2%'}}>
              <Typography
                  gutterBottom
                  variant='h5'
                  component='div'
                  // sx={{ padding: '20px' }}
              >
                  Manage Members
              </Typography>
              <Typography
                  variant='h3'
                  component='div'
                  sx={{ flexGrow: 1 }}
              ></Typography>
              <PermissionGuard feature='register-admin' permission='canCreate'>
                <Button variant='contained' onClick={() => modalOpen({},'add')} sx={{ height: "30px", marginTop: "10px" }} startIcon={<AddCircleIcon />}>
                    Add Member
                </Button>
              </PermissionGuard>
          </Stack>
        </Card> */}

            {/* =================== Searching and sorting section ================== */}
            {/* <Card sx={{ minWidth: "100%", marginBottom: "10px" }}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2} sx={{ p: 2 }}> */}
                    {/* <Grid item xs={12} lg={2}>
                        <Controller
                            name="searchKey"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    placeholder="Search here....."
                                    label="Search"
                                    size="small"
                                    fullWidth
                                />
                            )}
                        />
                    </Grid> */}
                    {/* <Grid item xs={6} lg={2}>
                        <FormControl sx={{ minWidth: "100%" }} size="small">
                            <InputLabel id="demo-simple-select-autowidth-label">Select Status</InputLabel>
                            <Controller
                                name="status"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        label="Select Status"
                                    >
                                        <MenuItem value="2">All</MenuItem>
                                        <MenuItem value="1">Active</MenuItem>
                                        <MenuItem value="0">Inactive</MenuItem>
                                    </Select>
                                )}
                            />
                        </FormControl>
                    </Grid> */}
                    {/* <Grid item xs={12} lg={2}>
                    <Tooltip title="Search Button">
                            <IconButton
                                color="primary"
                                type="submit"
                                sx={{ border: "1px solid", borderRadius: "7px", padding: "6px 6px" }}
                            >
                                <SearchIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Clear All Filters">
                            <IconButton
                                color="primary"
                                sx={{ marginLeft: "10px", border: "1px solid", color: "#f00", borderRadius: "7px", padding: "6px 6px" }}
                                onClick={clearFilters}
                            >
                                <RestartAltIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid> */}
                {/* </Grid>
            </form>
            </Card> */}

            <Box sx={{ overflow: "auto" }}>
                <Box sx={{ width: "100%", display: "table", tableLayout: "fixed", backgroundColor: "#fff", borderRadius: '.5rem' }}>
                    <DataGrid
                    rows={list}
                    columns={columns}
                    getRowId={(row) => row.id}
                    //checkboxSelection={true}
                    rowCount={allRowCount}  // Use the length of the filtered list
                    pageSizeOptions={[5, 10, 20, 50, 100]}
                    paginationModel={controller}
                    paginationMode="server"
                    onPaginationModelChange={handlePaginationChange}

                    loading={loading}

                    disableColumnMenu
                    disableRowSelectionOnClick
                    slots={{
                        toolbar: GridToolbar,
                    }}
                    autoHeight
                    getRowHeight={() => 'auto'}
                    sx={{
                        '& .MuiDataGrid-cell': {
                        border: 1,
                        borderRight: 0,
                        borderTop: 0,
                        borderColor: 'lightgrey',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        },
                        '& .MuiDataGrid-columnHeaders': {
                        borderBottom: '2px solid rgba(224, 224, 224, 1)',
                        borderTop: '2px solid rgba(224, 224, 224, 1)',
                        },
                        '& .MuiDataGrid-columnHeader': {
                        borderLeft: '1px solid rgba(224, 224, 224, 1)',
                        '&:last-child': {
                            borderRight: 'none',
                        },
                        },
                        '& .MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 'bold',
                        color: '#000000'
                        },
                        '& .MuiDataGrid-row': {
                        '&:last-child .MuiDataGrid-cell': {
                            borderBottom: '1px solid rgba(224, 224, 224, 1)',
                        },
                        '& .MuiDataGrid-cell:last-child': {
                            borderRight: 'none',
                        },
                        },
                        '& .MuiDataGrid-columnSeparator': {
                        display: 'none',
                        },
                    }}
                    />
                </Box>
            </Box>
        </>
    )
}

export default MemberList;
