import { AccountCircle, Assignment, Close, CloseOutlined, HighlightOffRounded, PersonOutline } from '@mui/icons-material';
import { Box, Card, Chip, Dialog, DialogContent, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { ProviderServices } from '../Provider/CrudServices';

dayjs.extend(customParseFormat);

const Viewdetails = ({selectedBookingDetails,setSelectedBookingDetails}) => {
    const [dialogOpen,setDialogOpen] = useState(true);

    const [providerDetails,setProviderDetails] = useState(null);
    const fetchProviderDetails = async (providerId)=>{
        const providerDetailResposne = await ProviderServices.fetchProviderDetailsById(providerId);
        setProviderDetails(providerDetailResposne.data);
    }
    useEffect(()=>{
        if(selectedBookingDetails?.providerId){
            fetchProviderDetails(selectedBookingDetails?.providerId);
        }
    },[selectedBookingDetails])
  return (
    <Dialog
        open={dialogOpen}
        fullWidth={true} 
        maxWidth="md" 
        PaperProps={{
        sx: {
            width: 'fit-content', 
            maxWidth: '85%' 
        },
        }}
    >
        {/* <Card sx={{display:'flex',justifyContent:'space-between',alignItems:'center',p:'1% 2%'}}>
            <Typography variant={'h5'}>Booking Detail's </Typography>
            <IconButton size='small' color='error' onClick={()=>setSelectedBookingDetails(null)}><Close/></IconButton>
        </Card> */}
        <Card sx={{display:'flex',justifyContent:'space-between',alignItems:'center',p:'1% 2%',borderBottomLeftRadius:0,borderBottomRightRadius:0}}>
            <Box sx={{display:'flex',alignItems:'center',gap:2}}>
                <Typography sx={{fontWeight:600,fontSize:'larger',color:'#464166'}}>Booking Details </Typography>
                <Assignment/>
            </Box>
            <IconButton size='small'  onClick={()=>setSelectedBookingDetails(null)}><HighlightOffRounded/></IconButton>
        </Card>
        <Box sx={{display:'flex',justifyContent:'space-between',alignItems:'center',borderBottom:'1px solid lightGray',p:'2%'}}>
            <Typography fontWeight={'600'} sx={{fontSize:'small'}}>Booking Number : <Box component={'span'} sx={{color:'#1976d2',ml:1}}>{selectedBookingDetails.bookingNumber}</Box></Typography>
            <Typography fontWeight={'600'} sx={{fontSize:'small'}}>Status: 
                <Box component={'span'}>
                    {/* //  sx={{textTransform:'uppercase',color:selectedBookingDetails.bookingStatus === 'requested' ? '#E8832A' : selectedBookingDetails.bookingStatus === "rejected"?'#F80000':selectedBookingDetails.bookingStatus === 'completed'?'#009EB4':selectedBookingDetails.bookingStatus === 'accepted'?'#40B962': selectedBookingDetails.bookingStatus === 'cancelled'?'#D81A23':selectedBookingDetails.bookingStatus === 'ongoing'?'#1A44B8':'default',fontSize:'smaller'}}>{selectedBookingDetails.bookingStatus} */}
                    <Chip
                        label={selectedBookingDetails.bookingStatus}
                        size="small"
                        sx={{
                            ml:1,
                            fontWeight:'500',
                            color:selectedBookingDetails.bookingStatus === 'requested' ? '#E8832A' : selectedBookingDetails.bookingStatus === "rejected"?'#F80000':selectedBookingDetails.bookingStatus === 'completed'?'#009EB4':selectedBookingDetails.bookingStatus === 'accepted'?'#40B962': selectedBookingDetails.bookingStatus === 'cancelled'?'#D81A23':selectedBookingDetails.bookingStatus === 'ongoing'?'#1A44B8':'default',
                            bgcolor:selectedBookingDetails.bookingStatus === 'requested' ? '#FFEDD5' : selectedBookingDetails.bookingStatus === "rejected"?'#f800001f':selectedBookingDetails.bookingStatus === 'completed'?'#009eb442':selectedBookingDetails.bookingStatus === 'accepted'?'#40b96236':selectedBookingDetails.bookingStatus === 'cancelled'?'#d81a233b':selectedBookingDetails.bookingStatus === 'ongoing'?'#1a44b838': 'default'
                        }}
                    />
                </Box>
            </Typography>
        </Box>
        <DialogContent>
            <Box sx={{}}>
                <TableContainer component={Paper}>
                    <Table size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{fontWeight:600}}>Requested Services</TableCell>
                                <TableCell sx={{fontWeight:600}}>Quantity</TableCell>
                                <TableCell sx={{fontWeight:600}}>Time Slot</TableCell>
                                <TableCell sx={{fontWeight:600,textAlign:'right'}}>Price</TableCell>
                                <TableCell sx={{fontWeight:600,textAlign:'right'}}>Total</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {selectedBookingDetails.bookingItems.map((item,index)=>(
                                <TableRow key={index}>
                                    <TableCell sx={{fontWeight:'500'}}>{item.serviceName}</TableCell>
                                    <TableCell sx={{pl:'3%'}}>{item.quantity}</TableCell>
                                    <TableCell sx={{}}>
                                        {dayjs(selectedBookingDetails.bookingStartTime, 'HH:mm:ss').format('h:mm A')} - 
                                        {dayjs(selectedBookingDetails.bookingEndTime, 'HH:mm:ss').format('h:mm A')}
                                    </TableCell>
                                    <TableCell sx={{textAlign:'right'}}>₹{item.price}</TableCell>
                                    <TableCell sx={{textAlign:'right'}}>₹{(item.price * item.quantity).toFixed(2)}</TableCell>
                                </TableRow>
                            ))}
                            
                            {/* Charges Section */}
                            <TableRow sx={{}}>
                                <TableCell rowSpan={selectedBookingDetails?.chargeDetails?.additionalCharge > 0 ?'4':'3'} />
                                <TableCell colSpan={3} sx={{textAlign:'right', fontWeight:'600',borderBottom:'none'}}>SubTotal:</TableCell>
                                <TableCell sx={{textAlign:'right',borderBottom:'none'}}>₹{selectedBookingDetails.subTotal}</TableCell>
                            </TableRow>
                            
                            <TableRow>
                                <TableCell colSpan={3} sx={{textAlign:'right',borderBottom:'none'}}>Visiting Charge:</TableCell>
                                <TableCell sx={{textAlign:'right',borderBottom:'none',}}>
                                ₹{selectedBookingDetails.subTotal>=selectedBookingDetails?.waiveVisitCharge ? parseFloat(0).toFixed(2):selectedBookingDetails?.chargeDetails?.vistingCharge}   
                                {/* ₹{parseFloat(selectedBookingDetails.subTotal) >= parseFloat(selectedBookingDetails?.waiveVisitCharge) ? (selectedBookingDetails?.chargeDetails?.vistingCharge) : 0} */}
                                </TableCell>
                            </TableRow>
                            {/* <TableRow>
                                <TableCell colSpan={3}  sx={{textAlign:'right',borderBottom:'none'}}>Waive Visiting Charge:</TableCell>
                                <TableCell sx={{textAlign:'right',borderBottom:'none',}}>
                                    <Box component={'span'}><Typography variant='caption' color={'darkseagreen'}>(-)</Typography></Box>₹{parseFloat(selectedBookingDetails.subTotal) >= parseFloat(selectedBookingDetails?.waiveVisitCharge) ? (selectedBookingDetails?.chargeDetails?.vistingCharge) : 0}
                                </TableCell>
                            </TableRow> */}
                            
                            {selectedBookingDetails?.chargeDetails?.additionalCharge > 0 && (
                                <TableRow>
                                    <TableCell colSpan={3}  sx={{textAlign:'right',borderBottom:'none'}}>Additional Charge:</TableCell>
                                    <TableCell 
                                        sx={{textAlign:'right',borderBottom:'none', 
                                            
                                            // position: 'relative',
                                            // '&::before': {
                                            // content: '"₹"',
                                            // }
                                        }}
                                    >
                                        ₹{parseFloat(selectedBookingDetails?.additionalCharge||0).toFixed(2)}
                                    </TableCell>
                                </TableRow>
                            )}
                            
                            <TableRow>
                                <TableCell colSpan={3} sx={{textAlign:'right'}}>Tax:</TableCell>
                                <TableCell sx={{textAlign:'right'}}>₹{selectedBookingDetails.tax}</TableCell>
                            </TableRow>
                            
                            <TableRow>
                                <TableCell colSpan={4} sx={{
                                    textAlign:'right', 
                                    fontWeight:'600',
                                    borderBottom: 'none'
                                }}>Total Amount:</TableCell>
                                <TableCell sx={{
                                    textAlign:'right',
                                    fontWeight:'600',
                                    borderBottom: 'none'
                                }}>
                                    {/* {parseFloat(
                                        parseFloat(selectedBookingDetails?.subTotal) + 
                                        parseFloat(selectedBookingDetails?.chargeDetails?.additionalCharge) +
                                        parseFloat(selectedBookingDetails?.tax) + 
                                        parseFloat(selectedBookingDetails?.chargeDetails?.vistingCharge)
                                    ).toFixed(2)} */}
                                    ₹{selectedBookingDetails?.total}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            {(selectedBookingDetails?.bookingStatus == 'rejected' || selectedBookingDetails?.bookingStatus == 'cancelled') && (
                <Box sx={{ my: '2%' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Typography sx={{ fontWeight: '600' }}>{`Booking ${selectedBookingDetails.bookingStatus == 'rejected' ? 'Rejection' : 'Cancellation'} Details`}</Typography>
                    </Box>
                    <Paper sx={{ p: '2%' }}>
                    <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                        <Typography fontWeight={'bold'} sx={{ width: '30%', color: '#3E3E3E', fontSize: 'smaller' }}>Cancelled By</Typography>
                        <Typography fontWeight={'bold'} sx={{ mr: '1%' }}>:</Typography>
                        <Typography fontWeight={'medium'} sx={{ fontSize: 'small', textTransform: 'capitalize', color: '#181818' }}>{selectedBookingDetails.cancelBy}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                        <Typography fontWeight={'bold'} sx={{ width: '30%', color: '#3E3E3E', fontSize: 'smaller' }}>Reason for {selectedBookingDetails.bookingStatus == 'rejected' ? 'Rejection' : 'Cancellation'}</Typography>
                        <Typography fontWeight={'bold'} sx={{ mr: '1%' }}>:</Typography>
                        <Typography fontWeight={'medium'} sx={{ fontSize: 'small', width: '68%', color: '#181818' }}>{selectedBookingDetails?.cancelMessage || selectedBookingDetails?.message || ''}</Typography>
                    </Box>
                    </Paper>
                </Box>
            )}
            <Box sx={{my:'2%'}}>
                <Box sx={{display:'flex',alignItems:'center',gap:2}}>
                    <Typography sx={{fontWeight:'600'}}>Customer Details</Typography>
                    <AccountCircle/>
                </Box>
                <Paper sx={{p:'2%'}}>
                    <Box sx={{display:'flex',alignItems:'baseline'}}>
                        <Typography fontWeight={'bold'} sx={{width:'30%',color:'#3E3E3E',fontSize:'smaller'}}>Name</Typography>
                        <Typography fontWeight={'bold'} sx={{mr:'1%'}}>:</Typography>
                        <Typography fontWeight={'medium'} sx={{fontSize:'small',textTransform:'capitalize',color:'#181818'}}>{selectedBookingDetails.add_customerFullName}</Typography>    
                    </Box>
                    <Box sx={{display:'flex',alignItems:'baseline'}}>
                        <Typography fontWeight={'bold'} sx={{width:'30%',color:'#3E3E3E',fontSize:'smaller'}}>Contact</Typography>
                        <Typography fontWeight={'bold'} sx={{mr:'1%'}}>:</Typography>
                        <Typography fontWeight={'medium'} sx={{fontSize:'small',width:'68%',color:'#181818'}}>{selectedBookingDetails.add_mobileNumber}</Typography>         
                    </Box>
                    <Box sx={{display:'flex',alignItems:'baseline'}}>
                        <Typography fontWeight={'bold'} sx={{width:'30%',color:'#3E3E3E',fontSize:'smaller'}}>Address</Typography>
                        <Typography fontWeight={'bold'} sx={{mr:'1%'}}>:</Typography>
                        <Typography fontWeight={'medium'} sx={{fontSize:'small',width:'68%',color:'#181818'}}>{selectedBookingDetails.add_address}</Typography>         
                    </Box>
                    <Box sx={{display:'flex',alignItems:'baseline'}}>
                        <Typography fontWeight={'bold'} sx={{width:'30%',color:'#3E3E3E',fontSize:'smaller'}}>Landmark</Typography>
                        <Typography fontWeight={'bold'} sx={{mr:'1%'}}>:</Typography>
                        <Typography fontWeight={'medium'} sx={{fontSize:'small',width:'68%',color:'#181818'}}>{selectedBookingDetails.add_landmark}</Typography>         
                    </Box>
                </Paper>
            </Box>
            <Box sx={{my:'2%'}}>
                <Box sx={{display:'flex',alignItems:'center',gap:2}}>
                    <Typography sx={{fontWeight:'600'}}>Provider Details</Typography>
                    <PersonOutline/>
                </Box>
                <Paper sx={{p:'2%'}}>
                    <Box sx={{display:'flex',alignItems:'flex-end'}}>
                        <Typography fontWeight={'bold'} sx={{width:'30%',color:'#3E3E3E',fontSize:'smaller'}}>Name</Typography>
                        <Typography fontWeight={'bold'} sx={{mr:'1%'}}>:</Typography>
                        <Typography fontWeight={'medium'} sx={{fontSize:'small',textTransform:'capitalize',color:'#181818'}}>{selectedBookingDetails?.providerName}</Typography>    
                    </Box>
                    <Box sx={{display:'flex',alignItems:'flex-end'}}>
                        <Typography fontWeight={'bold'} sx={{width:'30%',color:'#3E3E3E',fontSize:'smaller'}}>Contact</Typography>
                        <Typography fontWeight={'bold'} sx={{mr:'1%'}}>:</Typography>
                        <Typography fontWeight={'medium'} sx={{fontSize:'small',width:'68%'}}>{providerDetails?.mobileNumber||''}</Typography>         
                    </Box>
                    <Box sx={{display:'flex',alignItems:'flex-end'}}>
                        <Typography fontWeight={'bold'} sx={{width:'30%',color:'#3E3E3E',fontSize:'smaller'}}>waiveVisitCharge</Typography>
                        <Typography fontWeight={'bold'} sx={{mr:'1%'}}>:</Typography>
                        <Typography fontWeight={'medium'} sx={{fontSize:'small',width:'68%'}}>₹{providerDetails?.UserProfile?.waiveVisitCharge||'0.00'}</Typography>         
                    </Box>
                </Paper>
            </Box>
        </DialogContent>
    </Dialog>
  );
}

export default Viewdetails;
