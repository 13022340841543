import React, { useContext, useState } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  TextField,
  Typography,
  FormControl,
  FormControlLabel,
  Grid,
  Divider,
  IconButton,
  Box,
  CircularProgress,
} from '@mui/material';
import { Cancel as CancelIcon } from '@mui/icons-material';
import { useForm, Controller } from 'react-hook-form';
import { RoleAndPermissionServices } from '../RoleAndPermissionServices';
import Swal from 'sweetalert2';
import { LocalStore } from '../../../storage/authStore';

const AddFeature = ({ onClose }) => {
  const loggedInUSer = LocalStore.getCurrentUserDetails();
  const { control, watch, reset, handleSubmit, formState: { errors, isDirty } } = useForm({
    mode: 'onChange',
    defaultValues: {
      title: '',
    }
  });
  const [isSubmitted,setIsSubmitted] = useState(false);


  const onSubmit = async(data) => {
    setIsSubmitted(true);
    // console.log("Submitted data:", data);
    try {
      const createResponse = await RoleAndPermissionServices.createFeature(data);
      // console.log("feature creation response:",createResponse);
      if (createResponse.success=='1') {
        Swal.fire('feature created successfully','','success');
        setIsSubmitted(false);
        onClose();
        reset();
      }else{
        Swal.fire('Something Went wrong','','error');
        setIsSubmitted(false);
      }
    } catch (error) {
      Swal.fire('Internal Server Error','','error');
    }
    
  };

  return (
    <Card sx={{ width: '100%', maxWidth: '1000px', mx: 'auto' }}>
      <CardHeader 
        title={'Create Feature'}
        action={
          <IconButton onClick={onClose}>
            <CancelIcon />
          </IconButton>
        }
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          '& .MuiCardHeader-title': {
            fontSize: '1.25rem',
            fontWeight: 600
          }
        }}
      />
      
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardContent>
          <Controller
            name="title"
            control={control}
            rules={{ required: 'Enter feature name' }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label="Feature name"
                error={!!error}
                helperText={error?.message}
                size="small"
                sx={{ mb: 1 }}
              />
            )}
          />
        </CardContent>

        <CardActions sx={{ 
          justifyContent: 'flex-end', 
          p: 2, 
          borderTop: 1, 
          borderColor: 'divider' 
        }}>
          {isSubmitted && <CircularProgress/>}
          <Button 
            variant="outlined" 
            color="inherit"
            onClick={onClose}
            sx={{ mr: 1 }}
            disabled={isSubmitted}
          >
            Discard
          </Button>
          <Button 
            variant="contained" 
            color="primary"
            type="submit"
            disabled={!isDirty || isSubmitted}
          >
            Submit
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

export default AddFeature;