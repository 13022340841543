import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { createContext, useContext, useEffect, useState } from "react";
import { Auth } from "../../../services/AuthServices";
import { LocalStore } from "../../../storage/authStore";
import Loading from "../../../components/Loader/Loading";
import Otp from "./Otp";
import MobileNumberRegistration from "./MobileNumberRegistration.jsx";
import { UserProfileContext } from "../UserProfile.jsx";


const UserContext = createContext();
export default function OtrDetails(props) {
  const {otrDetail,setOtrDetail} = props;
  const navigate = useNavigate();
  const [isLoading,setIsLoading] = useState(false);
  const [step,setStep] = useState(0);    
  const [mobile, setMobile] = useState("");
    
  const sendOtp = async (mobile_number) => {
    setIsLoading(true);
    // console.log("submitted Data:",{mobileNumber: mobile_number})
    const response = await Auth.checkMobile({mobileNumber: mobile_number});
    // console.log("mobile number submission response:",response);
    if(response.success){
        const userDetail = response.data.userDetail;
        const isNewUser = response.data.newUser;
        if(isNewUser) {
            setOtrDetail(userDetail);
            setStep(1);
        }else{
            toast.error("Provided Number is already Registered with us");
        }               
        setIsLoading(false);
    }else{               
        toast.error(response.message);
        setIsLoading(false);
    }      
  };

  return (
      <UserContext.Provider value={{isLoading,setIsLoading,sendOtp,setMobile,step,setStep,otrDetail}}>
          <Container component="main" maxWidth="lg">
              <Box >
                  <Grid container
                      spacing={0}
                      direction="column"
                      alignItems="center"
                      // justifyContent="center"
                      sx={{ minHeight: '90vh' }}>
                      <CssBaseline />

                      <Grid
                          item
                          // xs={12}
                          // sm={8}
                          // md={5}
                          component={Paper}
                          elevation={6}
                          square
                          sx={{
                              width: { xs: "100%", sm: "75%", md: "50%" },  // Responsive widths
                              maxWidth: "600px",  // You can add a maximum width to control it on larger screens
                              // padding: 2,  // Add padding to improve spacing
                              borderRadius:'1rem'
                          }}
                      >
                      {/* <LogoSection/> */}
                      {step == 0 && <MobileNumberRegistration/>}
                      {step == 1 && <Otp/>}
                      </Grid>
                  </Grid>
              </Box>
          </Container>
      </UserContext.Provider>
      
  );
}

export {UserContext}