import React, { useEffect, useState } from 'react';
import {  Box,  Chip,  Tooltip,  Menu,  MenuItem,  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField, IconButton, Stack, FormControl, InputLabel, Select} from '@mui/material';
import {  CheckCircle,  Warning,  Block,  Pending, KeyboardArrowDown,  Edit, Cancel} from '@mui/icons-material';
import { PermissionGuard } from '../../../components/PermissionGuard';
import Swal from 'sweetalert2';
import { Controller,useForm } from 'react-hook-form';

const ReviewStatusIndicator = ({ status, onStatusChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [changeStatus, setChangeStatus] = useState(false);
//   const [selectedStatus, setSelectedStatus] = useState(null);
  const [reason, setReason] = useState('');

  const { control,watch,handleSubmit,setValue,reset } = useForm({
    mode: "onChange",
    defaultValues: {
      reviewStatus: status,
      reviewComment:  '',
    }
  });

  useEffect(() => {
    setValue('reviewStatus',status);
  }, [status]);
  const selectedStatus  = watch('reviewStatus');
  
  const statusConfigs = [
    // active: {
    //   label: 'Active',
    //   color: 'success',
    //   icon: <CheckCircle sx={{ fontSize: '0.65rem' }} />,
    //   tooltip: 'Account is active and verified'
    // },
    {
      label: 'Review Pending',
      value: '0',
      color: 'warning',
      colorCode:'#ed6c02',
      icon: <Pending sx={{ fontSize: '0.65rem' }} />,
      tooltip: 'Account is under review'
    },
    {
        label: 'Approved',
        value: '1',
        color: 'success',
        colorCode:'#2e7d32',
        icon: <CheckCircle sx={{ fontSize: '0.65rem' }} />,
        tooltip: 'Account has been Approved Already'
      },
    {
      label: 'Rejected',
      value: '2',
      color: 'error',
      colorCode:'#d32f2f',
      icon: <Warning sx={{ fontSize: '0.65rem' }} />,
      tooltip: 'Account has been rejected'
    },
    // blocked: {
    //   label: 'Blocked',
    //   color: 'error',
    //   icon: <Block sx={{ fontSize: '0.65rem' }} />,
    //   tooltip: 'Account has been blocked'
    // }
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
    // on change status first check form value is different from status received in props  
  useEffect(() => {
    if(selectedStatus != status){
        handleStatusSelect(selectedStatus);
    }
    }, [selectedStatus]);

  const handleStatusSelect = async(newStatus) => {
    // setSelectedStatus(newStatus);
    handleClose();
    
    // Show confirmation dialog for blocking or rejecting
    if (newStatus === '0' || newStatus === '2') {
      setConfirmDialog(true);
    } else {
        const result = await Swal.fire({
            text: `Are you sure you want to change the status to ${statusConfigs[selectedStatus]?.label} now?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, change now!',
            cancelButtonText: 'No, Cancel',
            allowOutsideClick: false,
            allowEscapeKey: false,
            showCloseButton: false,
        });
        if (result.isConfirmed) {
          setChangeStatus(false);
          handleStatusChange(newStatus);
        } else {
          setValue('reviewStatus',status);
          return false;
        }
    }
  };

  const handleStatusChange = (newStatus) => {
    onStatusChange(newStatus, reason);
    setConfirmDialog(false);
    setReason('');
  };

//   const currentConfig = statusConfigs[status?.toLowerCase()];

  return (
    <>
      

      <Stack
        direction="row" 
        spacing={1} 
        alignItems="center" 
        justifyContent="center"
        sx={{ mt:1,ml:'5%'}}
      >
      { changeStatus ?
        <PermissionGuard feature="service-expert" permission="canEdit">
        {/* <Tooltip title={currentConfig.tooltip}>
          <Chip
            icon={currentConfig.icon}
            label={currentConfig.label}
            color={currentConfig.color}
            size="small"
            variant="outlined"
            onClick={handleClick}
            onDelete={handleClick}
            deleteIcon={<KeyboardArrowDown />}
            sx={{ ml: 1 }}
          />
        </Tooltip> */}
            <FormControl variant='standard' size="small"
                sx={{
                    width: "fit-content",
                    border:`1px solid ${statusConfigs[selectedStatus]?.colorCode}`,
                    borderRadius: '4rem',
                    '& .MuiInput-underline': {
                    '&:before': {
                        borderBottom: 'none'  // Remove default bottom border
                    },
                    '&:after': {
                        borderBottom: 'none'  // Remove focus bottom border
                    },
                    '&:hover:not(.Mui-disabled):before': {
                        borderBottom: 'none'  // Remove hover bottom border
                    }
                    },
                    // Style the dropdown icon (arrow)
                    '& .MuiSelect-icon': {
                        color: statusConfigs[selectedStatus]?.colorCode, // Change arrow color
                        right: 0,
                    }
                }}                 
            >
                {/* <InputLabel id="demo-simple-select-autowidth-label">Select Status</InputLabel> */}
                <Controller
                    name="reviewStatus"
                    control={control}
                    // defaultValue=""
                    render={({ field }) => (
                    <Select
                        {...field}
                        // label="Select Status"
                        renderValue={(value) => (
                            <Box sx={{ display: 'flex', justifyContent:'center',height:'1rem'}}>
                              
                                <Chip

                                    icon={statusConfigs[value]?.icon}
                                    label={statusConfigs[value]?.label}
                                    color={statusConfigs[value]?.color}
                                    size="small"
                                    variant="outlined"
                                    sx={{ml:1,fontSize:'0.5rem',height:'fit-content',py:'1%',border:'none',
                                      '& .MuiChip-icon':{
                                        fontSize:'.6rem'
                                      }
                                    }}
                                />
                              
                            </Box>
                        )}
                        sx={{
                          minHeight: '0px',
                          height: '1rem',
                          fontSize: '0.75rem',
                          // fontWeight: '600',
                          '& .MuiSelect-select': {
                              padding: '0 .5rem', // Adjust horizontal padding as needed
                              display: 'flex',
                              alignItems: 'center',
                          },
                        }}
                    >
                        {
                            statusConfigs.map((config, key) => (
                                <MenuItem sx={{fontSize:'0.6rem'}} value={config?.value}>{config?.label}</MenuItem>
                            ))
                        }
                        {/* <MenuItem value="0">Review Pending</MenuItem>
                        <MenuItem value="1">Reviewed</MenuItem>
                        <MenuItem value="2">Rejected</MenuItem> */}
                    </Select>
                    )}
                />
            </FormControl>
        </PermissionGuard>
        : 
        <PermissionGuard feature="service-expert" permission="canView" fallback>
            <Tooltip title={statusConfigs[status]?.tooltip}>
            <Chip
                icon={statusConfigs[status]?.icon}
                label={statusConfigs[status]?.label}
                color={statusConfigs[status]?.color}
                size="small"
                variant="outlined"
                // sx={{ ml: 1 }}
                // size="small" 
                sx={{ml:1,fontSize:'0.5rem',height:'fit-content',py:'1%',
                  '& .MuiChip-icon':{
                    fontSize:'.6rem'
                  }
                }}
            />
            </Tooltip>
        </PermissionGuard>
        
      }
     { <IconButton size='small' sx={{p:0,ml:'1% !important'}} color={changeStatus?'error':'info'}>{changeStatus? <Cancel onClick={()=>setChangeStatus(false)} fontSize='.85rem'/>:<Edit onClick={()=>setChangeStatus(true)} sx={{fontSize:'.85rem'}}/>}</IconButton>}
     </Stack>
      {/* <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {statusConfigs.map((config,key ) => (
          <MenuItem 
            key={key} 
            onClick={() => handleStatusSelect(config.value)}
            selected={status === config.value}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {config.icon}
              {config.label}
            </Box>
          </MenuItem>
        ))}
      </Menu> */}

      <Dialog 
        open={confirmDialog} 
        onClose={() => setConfirmDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          Confirm Status Change
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to change the status to {selectedStatus != '1' ? statusConfigs[selectedStatus]?.label : 'Reviewed'}? 
            This action will affect the user's ability to access the platform.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Reason"
            fullWidth
            multiline
            rows={3}
            variant="outlined"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            required
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {setConfirmDialog(false);setValue('reviewStatus',status)}} color="inherit">
            Cancel
          </Button>
          <Button 
            onClick={() => {setChangeStatus(false);handleStatusChange(selectedStatus)}} 
            color="error" 
            variant="contained"
            disabled={!reason.trim()}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ReviewStatusIndicator;