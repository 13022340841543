// import axios from "axios";
// import { LocalStore } from "../storage/authStore";
// import { useLocation } from "react-router-dom";

// export const commonRequest = async(methods, url, body, token=0) => {
//     let header = {};
//     // Check if the body is FormData
//     const isFormData = body instanceof FormData;

//     if (isFormData) {
//         header = {
//             "Content-Type": "multipart/form-data"
//         };
//     } else {
//         header = {
//             "Content-Type": "application/json"
//         };
//     }
//     if(token){
//         header.Authorization = `Bearer ${token}`;
//     }
//     let config = {
//         method: methods,
//         url,
//         headers: header,
//         data: body
//     }
    
//     // axios instance
//     return axios(config).then((data) => {
//         if(data.data.success===0){
//             if(data.data.message==="Invalid Token"){
//                 LocalStore.doLogout();
//             }
//             if(data.data.message==="Token is expired"){
//                 LocalStore.doLogout();
//             }
//         }
//         return data.data;
//     }).catch((error)=>{
//         if(error.response){
//             if(error.response.data.message==="Invalid Token"){
//                 LocalStore.doLogout();
//             }
//             if(error.response.data.message==="Token is expired"){
//                 LocalStore.doLogout();
//             }
//             return error.response.data;
//         }
//         return error.message
//     })
// }

import axios from "axios";
import { LocalStore } from "../storage/authStore";
import Swal from "sweetalert2";

export const commonRequest = async (methods, url, body, token = 0, progressCallback = null) => {
    let header = {};
    const isFormData = body instanceof FormData;

    if (isFormData) {
        header = {
            "Content-Type": "multipart/form-data"
        };
    } else {
        header = {
            "Content-Type": "application/json"
        };
    }
    if (token) {
        header.Authorization = `Bearer ${token}`;
    }

    let config = {
        method: methods,
        url,
        headers: header,
        data: body
    };

    if (progressCallback && isFormData) {
        config.onUploadProgress = (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            progressCallback(percentCompleted);
        };
    }

    return axios(config)
        .then((data) => {
            // console.log("api response:",data);
            if (data.data.success === 0) {
                if (data.data.message === "Invalid Token" || data.data.message === "Token is expired") {
                    LocalStore.doLogout();
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: data.data.message,
                        confirmButtonText: 'OK'
                    });
                }
            }
            return data.data;
        })
        .catch((error) => {
            console.log("api error :",error);
             // Network connectivity error handling
        if (error.code === 'ECONNABORTED') {
            console.log("api error if code is ECONNABORTED:",error);
            // Request timeout
            Swal.fire({
                icon: 'error',
                title: 'Request Timeout',
                text: 'The request took too long. Please check your internet connection.',
                confirmButtonText: 'OK'
            });

            return {
                success:0,
                message:'The request took too long. Please check your internet connection.'
            }
        }
         else if (error.code === 'ERR_NETWORK') {
            
            console.log("api error if code is ERR_NETWORK:",error);
            // No internet connection or server unreachable
            Swal.fire({
                icon: 'error',
                title: 'Network Error',
                text: 'Unable to connect to the server. Please check your internet connection.',
                confirmButtonText: 'OK',
                // showCancelButton: true,
                // cancelButtonText: 'Close'
            });
            return {
                success:0,
                message:'Unable to connect to the server. Please check your internet connection.'
            }
        } 
        else if (error.response) {
            console.log("api error if there is response:",error.response);
            // Token-related errors
            if (error.response.data.message === "Invalid Token" || error.response.data.message === "Token is expired") {
                LocalStore.doLogout();
                
                Swal.fire({
                    icon: 'warning',
                    title: 'Session Expired',
                    text: 'Your session has expired. Please log in again.',
                    confirmButtonText: 'OK'
                });
                return error.response.data;
            }
            else if (error.response?.statusText == "Bad Request") {
                // Swal.fire({
                //     icon: 'error',
                //     title: 'Unauthorized',
                //     text: 'You are not authorized to access this resource.',
                //     confirmButtonText: 'OK'
                // });
                console.log("Bad Request error:",error.response.data);
                return error.response.data;
            }
            
            return error.response.data;
        }
        
        // // For any other errors, return the error message
        else{
            console.log("un handled error:",error.message);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.message,
                confirmButtonText: 'OK'
            });
             return {
                success:0,
                message:error.message
            }
        }
        
    });
};