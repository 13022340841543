import React, { useEffect, useState } from 'react';
import { TextField, Button, Typography, IconButton, Grid, FormControl, InputLabel, Select, MenuItem, FormLabel, RadioGroup, FormControlLabel, Radio, Autocomplete, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useForm ,Controller} from 'react-hook-form';
import Swal from "sweetalert2";
import ImgUploadDialog from "../../components/ImgUpload/imgUpload";
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import { CrudServices } from "./CrudServices";
import { isMobile } from "react-device-detect";
import { BookingService } from "../Booking/Service";
import { Helpers } from '../../services/Helpers';
import Loading from '../../components/Loader/Loading';
import { Edit } from '@mui/icons-material';
import { PermissionGuard } from '../../components/PermissionGuard';


export default function ViewService({ closeEvent,serviceData,handleEdit }) {
    const [serviceInitialState,setServiceInitialState] = useState({  
        title:'',
        shortTitle:'',
        description:'',
        image:'',
        categoryId:'',
        serviceCategoryId:'',
        parentServiceId:0,
        serviceType:'parent',
        
    })
    const { control, reset, setValue, watch, formState: { errors,isDirty } } = useForm({
        mode: "onChange",
        defaultValues:serviceInitialState,setServiceInitialState
    });
    const [isLoading,setIsLoading] = useState (false);

    
    const [categoryList,setCategoryList] = useState([]);
    const fetchCategoryList= async()=>{
        try {
          const categoryListResponse = await BookingService.categoryList();
        //   console.log("category list Response:",categoryListResponse);
          if(categoryListResponse.success===1){
            setCategoryList(categoryListResponse.data)
          }
        } catch (error) {
          
        }
    }
    
    /*================= on component load==================== */
    
    useEffect(() => {
      fetchCategoryList();
    }, []);
    /*================= on category selection==================== */
    const [subCategoryList,setSubCategoryList] = useState([]);
    const categoryId = watch('categoryId');
    const fetchSubCategoryList= async()=>{
        setIsLoading(true);
        try {
          const subCategoryListResponse = await BookingService.subCategoryList({categoryId:categoryId});
        //   console.log("sub category list Response:",subCategoryListResponse);
          if(subCategoryListResponse.success===1){
            setSubCategoryList(subCategoryListResponse.data)
          }
        } catch (error) {
          
        }finally{
            setIsLoading(false);
        }
    }
    useEffect(()=>{
        if(categoryId){
            fetchSubCategoryList();
        }
    },[categoryId])
    /*============== on SubCategory selection ============ */
    const subCategoryId=watch('serviceCategoryId');
    const serviceType = watch('serviceType', 'parent');
    const [parentServices,setParentServices] = useState([]);
    useEffect(()=>{
      if(subCategoryId && serviceType=='child'){
        // setValue('parentServiceId',0)
        setParentServices([]);
        const fetchServiceList= async()=>{
            setIsLoading(true);
          try {
            const serviceListResponse = await CrudServices.serviceListBySubCategory(subCategoryId);
            // console.log("service list Response:",serviceListResponse);
            if(serviceListResponse.success===1){
                setParentServices(serviceListResponse.data)
            }
          } catch (error) {
            
          }finally{
            setIsLoading(false);
          }
        }
        fetchServiceList();
      }
    },[subCategoryId,serviceType])
    /*============================================*/
    useEffect(() => {
        // categoryList();
        if (serviceData.id>0) {
            if (serviceData.parentServiceId>0) {
                setServiceInitialState({serviceType:'child', categoryId: serviceData.categoryId,serviceCategoryId:serviceData?.serviceCategoryId,...serviceData});
            }else{
                setServiceInitialState(pre=>({...pre,...serviceData}));
            }
        }

    }, [serviceData])
    useEffect(()=>{
        reset(serviceInitialState);
    },[serviceInitialState,reset]) 
    
    return (
        <>
            
            <Box sx={{display:'flex',justifyContent:'space-between'}}>
                <Typography variant="h5">Service Details</Typography>
                <Box sx={{display:'flex',gap:'2%'}}>
                    <PermissionGuard feature='service' permission='canEdit'>
                        <Button size='small' startIcon={<Edit/>} onClick={()=>handleEdit()}>
                            Edit
                        </Button>
                    </PermissionGuard>
                    <IconButton
                        sx={{}}
                        onClick={closeEvent}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Box>
            <Grid container spacing={2} mt={1}>
                {isLoading && <Loading/>}
                <Grid item xs={12} >
                    <Grid container spacing={2}>
                        <Grid item xs={isMobile?12:6}>
                            <Grid item xs={12} alignItems={'center'}>
                                <Grid item xs={6}>
                                    {
                                        serviceData.image && <img
                                            src={`${Helpers.ImagePathUrl()}/service/md/${serviceData.image}`}
                                        />
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={isMobile?12:6}>
                            <Grid item xs={12}>
                            <TextField
                                    select
                                    label={'Category'}
                                    value={serviceInitialState.categoryId}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    >
                                    {categoryList.map((option, index) => (
                                        <MenuItem key={index} value={option.id}>
                                        {option.title}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} my={'3%'}>
                                <TextField
                                    select
                                    label={'Sub Category'}
                                    value={serviceInitialState.serviceCategoryId}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    >
                                    {subCategoryList.map((option, index) => (
                                        <MenuItem key={index} value={option.id}>
                                        {option.title}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl component="fieldset">
                                <FormLabel component="legend">Service Type</FormLabel>
                                    <RadioGroup value={serviceInitialState.serviceType} row>
                                        <FormControlLabel value="parent" control={<Radio />} label="Parent Service" />
                                        <FormControlLabel value="child" control={<Radio />} label="Child Service" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            {serviceType === 'child' && (
                                <Grid item xs={12} my={'3%'}>
                                    <TextField
                                        select
                                        label={'Parent Service'}
                                        value={serviceInitialState.parentServiceId}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                        >
                                        {parentServices.map((option, index) => (
                                            <MenuItem key={index} value={option.masterServiceId}>
                                            {option.title}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            )}
                            <Grid item xs={12} my={'3%'}>
                                <TextField
                                    value={serviceInitialState.title}
                                    label="Service Name"
                                    variant="outlined"
                                    size="small"
                                    fullWidth 
                                />
                            </Grid>
                            <Grid item xs={12} my={'3%'}>
                                <TextField
                                    value={serviceInitialState.shortTitle}
                                    label="Service Short Title*"
                                    variant="outlined"
                                    size="small"
                                    fullWidth 
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                value={serviceInitialState.description}
                                label="Service Description"
                                variant="outlined"
                                size="small"
                                fullWidth 
                            />
                        </Grid>
                    </Grid>
                        
                </Grid>
            </Grid>
        </>
    )
}
