import React, { useContext, useState, useEffect } from 'react';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import { Box, Typography, Checkbox, FormGroup, FormControlLabel, TextField, Button, Grid, IconButton, Card } from '@mui/material';
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material';
import { UserProfileContext } from '../UserProfile';
import { ProviderServices } from '../CrudServices';
import Swal from 'sweetalert2';
import Loading from '../../../components/Loader/Loading';
import { useParams } from 'react-router-dom';

const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const WorkingHours = ({ fetchedData }) => {
  const {id} = useParams();
  const { availabilityDetailData, setAvailabilityDetailData } = useContext(UserProfileContext);
  const [isLoading, setIsLoading] = useState(false);
  const [hasDefaultSlots, setHasDefaultSlots] = useState(false);
 
  const prepopulateData = (fetchedData) => {
    return days.map(day => {
      const dayData = fetchedData.dayTimeSlots.find(slot => slot.day === day);
      return {
        day,
        isSelected: !!dayData?.slots.length,
        slots: dayData ? dayData.slots : [],
        defaultSlots: [{ startTime: '09:00', endTime: '18:30' }]
      };
    });
  };

  const { control, handleSubmit, setValue, formState: { errors, isDirty }, watch, reset } = useForm({
    mode: 'onChange',
    defaultValues: {
      userId: availabilityDetailData?.userId || id>0 && id || '',
      dayTimeSlots: prepopulateData(availabilityDetailData)
    }
  });
  useEffect(() => {
    console.log("availabilityDetailData",availabilityDetailData);
    if (availabilityDetailData.userId > 0 || id>0) {
      const fetchProviderTimeSlots = async () => {
        setIsLoading(true);
        const timeSlotList = await ProviderServices.fetchProviderTimeSlots(availabilityDetailData.userId || id);
        setAvailabilityDetailData(timeSlotList.data);
        setIsLoading(false);
      }
      fetchProviderTimeSlots();
    }
  }, []);

  useEffect(() => {
    if (availabilityDetailData) {
      reset({
        userId: availabilityDetailData.userId || '',
        dayTimeSlots: prepopulateData(availabilityDetailData)
      });
    }
  }, [availabilityDetailData, reset]);

  const { fields } = useFieldArray({
    control,
    name: "dayTimeSlots"
  });

  // if any one of day have not default time slot then sethasDefaultSlots will be false
  // Effect to check default slots status
  useEffect(() => {
    const currentFields = watch('dayTimeSlots');
    
    // Check if ALL days have default slot
    const allHaveDefault = currentFields.every(field => 
      field.slots.some(slot => 
        slot.startTime === '09:00:00' && slot.endTime === '18:30:00'
      )
    );
  
    // Update both local state and form state
    setHasDefaultSlots(allHaveDefault);
  }, [watch('dayTimeSlots')]);
  
  // Modified default time slots handler
  // const handleDefaultTimeSlots = (checkboxState) => {
  //   const currentFields = watch('dayTimeSlots');
  
  //   // if (checkboxState) {
  //     // Adding default slots to ALL days
  //     const updatedFields = currentFields.map(field => {
  //       // Filter out existing slots to avoid duplicates
  //       const existingNonDefaultSlots = field.slots.filter(slot => 
  //         slot.startTime !== '09:00:00' || slot.endTime !== '18:30:00'
  //       );
  
  //       // Prepare default slot addition
  //       const defaultSlotToAdd = 
  //         // Check if default slot already exists
  //         field.slots.some(slot => 
  //           slot.startTime === '09:00:00' && slot.endTime === '18:30:00'
  //         ) 
  //         ? [] 
  //         // Add default slot only if total slots will be less than 2
  //         : (existingNonDefaultSlots.length < 2 
  //           ? [{ startTime: '09:00:00', endTime: '18:30:00' }] 
  //           : []);
  
  //       return {
  //         ...field,
  //         isSelected: true,
  //         slots: [
  //           ...existingNonDefaultSlots,
  //           ...defaultSlotToAdd
  //         ]
  //       };
  //     });
  
  //     setValue('dayTimeSlots', updatedFields);
  //   // } else {
  //   //   // Remove default slots from ALL days
  //   //   const updatedFields = currentFields.map(field => ({
  //   //     ...field,
  //   //     slots: field.slots.filter(slot => 
  //   //       slot.startTime !== '09:00' || slot.endTime !== '18:30'
  //   //     )
  //   //   }));
  
  //   //   setValue('dayTimeSlots', updatedFields);
  //   // }
  // };
  const handleDefaultTimeSlots = (checkboxState) => {
    const currentFields = watch('dayTimeSlots');
  
    const updatedFields = currentFields.map(field => {
      // Check if an equivalent default slot already exists
      const hasEquivalentSlot = field.slots.some(slot => 
        slot.startTime === '09:00:00' && slot.endTime === '18:30:00'
      );
  
      // Determine whether to add a default slot
      const defaultSlotToAdd = 
        (!hasEquivalentSlot && field.slots.length < 2)
          ? [{ startTime: '09:00:00', endTime: '18:30:00' }]
          : [];
  
      return {
        ...field,
        isSelected: true,
        slots: [
          ...field.slots,
          ...defaultSlotToAdd
        ]
      };
    });
  
    setValue('dayTimeSlots', updatedFields,{shouldValidate:true,shouldDirty:true});
  };

    const onSubmit = async (data) => {
    const selectedDays = data.dayTimeSlots.filter(day => day.isSelected && day.slots.some(slot => slot.startTime && slot.endTime));
    
    if (selectedDays.length === 0) {
      Swal.fire("Error", "Please select at least one day and add time slots.", "error");
      return;
    }

    setIsLoading(true);
    const formattedData = {
      userId: data.userId,
      dayTimeSlots: selectedDays.map(({ day, slots }) => ({
        day,
        slots: slots.filter(slot => slot.startTime && slot.endTime)
      }))
    };

    // console.log("formattedData",formattedData);

    try {
      const timeslotCreateResponse = await ProviderServices.updateTimeSlot(formattedData);
      if (timeslotCreateResponse.success == '1') {
        Swal.fire("Success", "Time slots updated successfully", "success");
        setAvailabilityDetailData(timeslotCreateResponse.data);
      } else {
        Swal.fire("Error", "Something went wrong", "error");
      }
    } catch (error) {
      Swal.fire("Error", "An error occurred while updating time slots", "error");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box component={'form'} onSubmit={handleSubmit(onSubmit)}>
      {isLoading && <Loading />}
      <Grid container spacing={2}>
        <Grid item xs={12} lg={7}>
          <Card sx={{my:0.5}}>
            <Box sx={{p:'.5% 2%',border:'2px solid lightGray',bgcolor:'lightGray'}}>
              <Typography sx={{fontWeight:'600'}}>Working Hours</Typography>
            </Box>
            <Box sx={{p:'2%'}}>
              {/* <FormControlLabel
                control={
                  <Checkbox
                    checked={hasDefaultSlots}
                    onChange={handleDefaultTimeSlots}
                  />
                }
                label="Add default time slots (9 AM - 6:30 PM) to all days"
              /> */}
              <Button variant='outlined' size='small' onClick={handleDefaultTimeSlots}>
                Add default time slots (9 AM - 6:30 PM) to all days
              </Button>
              <FormGroup>
                {fields.map((field, index) => {
                  const isSelected = watch(`dayTimeSlots.${index}.isSelected`);
                  return (
                    <Box key={field.id} sx={{ mb: 2 }}>
                      <Controller
                        name={`dayTimeSlots.${index}.isSelected`}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <FormControlLabel
                            control={
                              <Checkbox 
                                checked={value} 
                                onChange={(e) => {
                                  // When unchecking, clear but keep custom slots
                                  if (!e.target.checked) {
                                    const currentSlots = watch(`dayTimeSlots.${index}.slots`);
                                    const customSlots = currentSlots.filter(slot => 
                                      !field.defaultSlots.some(
                                        defaultSlot => 
                                          defaultSlot.startTime === slot.startTime && 
                                          defaultSlot.endTime === slot.endTime
                                      )
                                    );
                                    
                                    // Update slots to keep custom slots
                                    setValue(`dayTimeSlots.${index}.slots`, customSlots);
                                  }
                                  
                                  // Change the selection
                                  onChange(e.target.checked);
                                }} 
                              />
                            }
                            label={field.day}
                          />
                        )}
                      />
                      {isSelected && (
                        <Controller
                          name={`dayTimeSlots.${index}.slots`}
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <>
                              {value.map((slot, slotIndex) => (
                                <Grid container spacing={2} key={slotIndex} sx={{ mb: 1, ml: 4 }}>
                                  <Grid item xs={5}>
                                    <TextField
                                      label="Start Time"
                                      type="time"
                                      size="small"
                                      value={slot.startTime}
                                      onChange={(e) => {
                                        const newSlots = [...value];
                                        newSlots[slotIndex].startTime = e.target.value;
                                        onChange(newSlots);
                                      }}
                                      InputLabelProps={{ shrink: true }}
                                      inputProps={{ step: 1 }}
                                      fullWidth
                                    />
                                  </Grid>
                                  <Grid item xs={5}>
                                    <TextField
                                      label="End Time"
                                      type="time"
                                      size="small"
                                      value={slot.endTime}
                                      onChange={(e) => {
                                        const newSlots = [...value];
                                        newSlots[slotIndex].endTime = e.target.value;
                                        onChange(newSlots);
                                      }}
                                      InputLabelProps={{ shrink: true }}
                                      inputProps={{ step: 1 }}
                                      fullWidth
                                    />
                                  </Grid>
                                  <Grid item xs={2}>
                                    <IconButton 
                                      onClick={() => {
                                        const newSlots = value.filter((_, i) => i !== slotIndex);
                                        onChange(newSlots);
                                      }} 
                                      color="error"
                                    >
                                      <RemoveIcon />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              ))}
                              {/* Limit to 2 slots */}
                              {value.length < 2 && (
                                <Box textAlign={'right'}>
                                  <Button 
                                  startIcon={<AddIcon />} 
                                  onClick={() => onChange([...value, { startTime: '', endTime: '' }])}
                                  sx={{ mt: 1, ml: 4 }}
                                >
                                  Add Slot
                                </Button>
                                </Box>
                              )}
                            </>
                          )}
                        />
                      )}
                    </Box>
                  );
                })}
              </FormGroup>
            </Box>
            <Box sx={{textAlign:'center',my:'5%'}}>
              <Button disabled={!isDirty} type="submit" variant="contained" color="primary">
                Save Working Hours
              </Button>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WorkingHours;

/*
import React, { useContext, useState, useEffect } from 'react';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import { Box, Typography, Checkbox, FormGroup, FormControlLabel, TextField, Button, Grid, IconButton, Card } from '@mui/material';
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material';
import { UserProfileContext } from '../UserProfile';
import { ProviderServices } from '../CrudServices';
import Swal from 'sweetalert2';
import Loading from '../../../components/Loader/Loading';

const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const WorkingHours = ({ fetchedData }) => {
  const { availabilityDetailData, setAvailabilityDetailData } = useContext(UserProfileContext);
  const [isLoading, setIsLoading] = useState(false);
  const [hasDefaultSlots, setHasDefaultSlots] = useState(false);
 
  const prepopulateData = (fetchedData) => {
    return days.map(day => {
      const dayData = fetchedData.dayTimeSlots.find(slot => slot.day === day);
      return {
        day,
        isSelected: !!dayData?.slots.length,
        slots: dayData ? dayData.slots : [],
        defaultSlots: [{ startTime: '09:00', endTime: '18:30' }]
      };
    });
  };

  const { control, handleSubmit, setValue, formState: { errors, isDirty }, watch, reset } = useForm({
    mode: 'onChange',
    defaultValues: {
      userId: availabilityDetailData.userId || '',
      dayTimeSlots: prepopulateData(availabilityDetailData)
    }
  });
  useEffect(() => {
    if (availabilityDetailData.userId > 0) {
      const fetchProviderTimeSlots = async () => {
        setIsLoading(true);
        const timeSlotList = await ProviderServices.fetchProviderTimeSlots(availabilityDetailData.userId);
        setAvailabilityDetailData(timeSlotList.data);
        setIsLoading(false);
      }
      fetchProviderTimeSlots();
    }
  }, []);

  useEffect(() => {
    if (availabilityDetailData) {
      reset({
        userId: availabilityDetailData.userId || '',
        dayTimeSlots: prepopulateData(availabilityDetailData)
      });
    }
  }, [availabilityDetailData, reset]);

  const { fields } = useFieldArray({
    control,
    name: "dayTimeSlots"
  });

  // if any one of day have not default time slot then sethasDefaultSlots will be false
  // Effect to check default slots status
  useEffect(() => {
    const currentFields = watch('dayTimeSlots');
    
    // Check if ALL days have default slot
    const allHaveDefault = currentFields.every(field => 
      field.slots.some(slot => 
        slot.startTime === '09:00' && slot.endTime === '18:30'
      )
    );
  
    // Update both local state and form state
    setHasDefaultSlots(allHaveDefault);
  }, [watch('dayTimeSlots')]);
  
  // Modified default time slots handler
  const handleDefaultTimeSlots = (checkboxState) => {
    const currentFields = watch('dayTimeSlots');
  
    if (checkboxState) {
      // Adding default slots to ALL days
      const updatedFields = currentFields.map(field => {
        // Filter out existing slots to avoid duplicates
        const existingNonDefaultSlots = field.slots.filter(slot => 
          slot.startTime !== '09:00' || slot.endTime !== '18:30'
        );
  
        // Prepare default slot addition
        const defaultSlotToAdd = 
          // Check if default slot already exists
          field.slots.some(slot => 
            slot.startTime === '09:00' && slot.endTime === '18:30'
          ) 
          ? [] 
          // Add default slot only if total slots will be less than 2
          : (existingNonDefaultSlots.length < 2 
            ? [{ startTime: '09:00', endTime: '18:30' }] 
            : []);
  
        return {
          ...field,
          isSelected: true,
          slots: [
            ...existingNonDefaultSlots,
            ...defaultSlotToAdd
          ]
        };
      });
  
      setValue('dayTimeSlots', updatedFields);
    } else {
      // Remove default slots from ALL days
      const updatedFields = currentFields.map(field => ({
        ...field,
        slots: field.slots.filter(slot => 
          slot.startTime !== '09:00' || slot.endTime !== '18:30'
        )
      }));
  
      setValue('dayTimeSlots', updatedFields);
    }
  };

    const onSubmit = async (data) => {
    const selectedDays = data.dayTimeSlots.filter(day => day.isSelected && day.slots.some(slot => slot.startTime && slot.endTime));
    
    if (selectedDays.length === 0) {
      Swal.fire("Error", "Please select at least one day and add time slots.", "error");
      return;
    }

    setIsLoading(true);
    const formattedData = {
      userId: data.userId,
      dayTimeSlots: selectedDays.map(({ day, slots }) => ({
        day,
        slots: slots.filter(slot => slot.startTime && slot.endTime)
      }))
    };

    // console.log("formattedData",formattedData);

    try {
      const timeslotCreateResponse = await ProviderServices.updateTimeSlot(formattedData);
      if (timeslotCreateResponse.success == '1') {
        Swal.fire("Success", "Time slots updated successfully", "success");
        setAvailabilityDetailData(timeslotCreateResponse.data);
      } else {
        Swal.fire("Error", "Something went wrong", "error");
      }
    } catch (error) {
      Swal.fire("Error", "An error occurred while updating time slots", "error");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box component={'form'} onSubmit={handleSubmit(onSubmit)}>
      {isLoading && <Loading />}
      <Grid container spacing={2}>
        <Grid item xs={12} lg={7}>
          <Card sx={{my:0.5}}>
            <Box sx={{p:'.5% 2%',border:'2px solid lightGray',bgcolor:'lightGray'}}>
              <Typography sx={{fontWeight:'600'}}>Working Hours</Typography>
            </Box>
            <Box sx={{p:'2%'}}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={hasDefaultSlots}
                    onChange={handleDefaultTimeSlots}
                  />
                }
                label="Add default time slots (9 AM - 6:30 PM) to all days"
              />
              <FormGroup>
                {fields.map((field, index) => {
                  const isSelected = watch(`dayTimeSlots.${index}.isSelected`);
                  return (
                    <Box key={field.id} sx={{ mb: 2 }}>
                      <Controller
                        name={`dayTimeSlots.${index}.isSelected`}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <FormControlLabel
                            control={
                              <Checkbox 
                                checked={value} 
                                onChange={(e) => {
                                  // When unchecking, clear but keep custom slots
                                  if (!e.target.checked) {
                                    const currentSlots = watch(`dayTimeSlots.${index}.slots`);
                                    const customSlots = currentSlots.filter(slot => 
                                      !field.defaultSlots.some(
                                        defaultSlot => 
                                          defaultSlot.startTime === slot.startTime && 
                                          defaultSlot.endTime === slot.endTime
                                      )
                                    );
                                    
                                    // Update slots to keep custom slots
                                    setValue(`dayTimeSlots.${index}.slots`, customSlots);
                                  }
                                  
                                  // Change the selection
                                  onChange(e.target.checked);
                                }} 
                              />
                            }
                            label={field.day}
                          />
                        )}
                      />
                      {isSelected && (
                        <Controller
                          name={`dayTimeSlots.${index}.slots`}
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <>
                              {value.map((slot, slotIndex) => (
                                <Grid container spacing={2} key={slotIndex} sx={{ mb: 1, ml: 4 }}>
                                  <Grid item xs={5}>
                                    <TextField
                                      label="Start Time"
                                      type="time"
                                      size="small"
                                      value={slot.startTime}
                                      onChange={(e) => {
                                        const newSlots = [...value];
                                        newSlots[slotIndex].startTime = e.target.value;
                                        onChange(newSlots);
                                      }}
                                      InputLabelProps={{ shrink: true }}
                                      inputProps={{ step: 1 }}
                                      fullWidth
                                    />
                                  </Grid>
                                  <Grid item xs={5}>
                                    <TextField
                                      label="End Time"
                                      type="time"
                                      size="small"
                                      value={slot.endTime}
                                      onChange={(e) => {
                                        const newSlots = [...value];
                                        newSlots[slotIndex].endTime = e.target.value;
                                        onChange(newSlots);
                                      }}
                                      InputLabelProps={{ shrink: true }}
                                      inputProps={{ step: 1 }}
                                      fullWidth
                                    />
                                  </Grid>
                                  <Grid item xs={2}>
                                    <IconButton 
                                      onClick={() => {
                                        const newSlots = value.filter((_, i) => i !== slotIndex);
                                        onChange(newSlots);
                                      }} 
                                      color="error"
                                    >
                                      <RemoveIcon />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              ))}*/
                              // {/* Limit to 2 slots */}
//                               {value.length < 2 && (
//                                 <Box textAlign={'right'}>
//                                   <Button 
//                                   startIcon={<AddIcon />} 
//                                   onClick={() => onChange([...value, { startTime: '', endTime: '' }])}
//                                   sx={{ mt: 1, ml: 4 }}
//                                 >
//                                   Add Slot
//                                 </Button>
//                                 </Box>
//                               )}
//                             </>
//                           )}
//                         />
//                       )}
//                     </Box>
//                   );
//                 })}
//               </FormGroup>
//             </Box>
//             <Box sx={{textAlign:'center',my:'5%'}}>
//               <Button disabled={!isDirty} type="submit" variant="contained" color="primary">
//                 Save Working Hours
//               </Button>
//             </Box>
//           </Card>
//         </Grid>
//       </Grid>
//     </Box>
//   );
// };

// export default WorkingHours;
