import React, { useContext, useEffect, useState } from 'react';
import { FormProvider,useForm, Controller } from 'react-hook-form';
import {  Button,  Grid,  Box,  FormControl,  Select,  MenuItem,  Typography,  Card, IconButton,} from '@mui/material';
import { BookingService } from '../../Booking/Service';
import { ProviderServices } from '../CrudServices';
import ServiceSelectionComponent from './ServiceSelectionComponent';
import SelectedServices from './SelectedServices';
import ServiceCharges from './ServiceCharges';
import { UserProfileContext } from '../UserProfile';
import Swal from 'sweetalert2';
import Loading from '../../../components/Loader/Loading';
import { useParams } from 'react-router-dom';
import ServiceTable from './ServiceTable';
import { Add, Edit, List } from '@mui/icons-material';
import ConfirmationDialog from './ConfirmationDialog';



const ServiceDetails = () => {
  const { id } = useParams();
  const {serviceDetailsData,setServiceDetailsData} = useContext(UserProfileContext);
  // use for checking both form submission and fetchinglist
  const [isSubmitted,setIsSubmitted] = useState(false);

  const [showList,setShowList] = useState(true);
  const [openConfirmationDialog,setOpenConfirmationDialog] = useState(false);
  const serviceFormMethod = useForm(
    { 
      mode: "onChange",
      defaultValues:serviceDetailsData 
    }
  );
  const {control, handleSubmit, setValue, formState: { errors ,isDirty},watch,reset,trigger} = serviceFormMethod

  const [categoryList,setCategoryList] = useState([]);
  const [subCategoryList,setSubCategoryList] = useState([]);
  const [serviceList,setServiceList] = useState([]);
  const [providerServiceList,setProviderServiceList] = useState([]);
  const [providerServiceModal,setProviderServiceModal] = useState({});

  const categoryId= watch('serviceCategoryId');
  const subCategoryId=watch('subCategoryId');
  const service = watch('service');

  /*===========fetch servicelist of provider =========== */
  const fetchProviderServiceList = async(fetchParameters)=>{
    setIsSubmitted(true);
   
    const providerServiceListResponse = await ProviderServices.fetchProviderServices(fetchParameters);
    // console.log("provider service list Response:",providerServiceListResponse)
    // setProviderServiceList(providerServiceListResponse?.data?.list);
    const fetchedServiceList = providerServiceListResponse?.data?.list;
    const newServiceList = fetchedServiceList.map(item => {
      return {
        id: item.id,
        servicesMasterId: item.servicesMasterId,
        servicesChildId: item.servicesChildId,
        subCategoryId: item.childService?.ServiceCategoryRels[0]?.serviceCategoryId,
        subCategoryTitle: item.childService?.ServiceCategoryRels[0]?.ServiceCategory?.title,
        masterServiceTitle: item?.ServicesMaster?.title,
        childServiceTitle: item.childService?.title,
        serviceTime: item.serviceTime,
        price: item.price
      };
    });
    setProviderServiceList(newServiceList);
    setProviderServiceModal({
      currentPage: providerServiceListResponse.data?.currentPage,
      totalItems:providerServiceListResponse.data?.totalItems,
      totalPages:providerServiceListResponse.data?.totalPages
    })
    setIsSubmitted(false);
  }

  /*=============on compopnent load ============ */
  useEffect(()=>{
    const fetchCategoryList= async()=>{
        try {
          const categoryListResponse = await BookingService.categoryList();
          // console.log("category list Response:",categoryListResponse);
          if(categoryListResponse.success===1){
            setCategoryList(categoryListResponse.data)
          }
        } catch (error) {
          
        }
      }
      fetchCategoryList();
      if (serviceDetailsData.providerId>0 || id>0) {
        let fetchParameters = {
          providerId:serviceDetailsData.providerId || id>0&&id,
          page:1,
          pageSize:5,
        }
        fetchProviderServiceList(fetchParameters);
    }
  },[]);

  /*=============on category selection change ============ */
  useEffect(()=>{
    if (watch('services').length>0 && !serviceDetailsData.serviceCategoryId) {
      console.log("setting confirmation dialog true in category cahnge:")
      setOpenConfirmationDialog(true);
    }else{
      // if(!serviceDetailsData.serviceCategoryId){
      //   setValue('subCategoryId','')
      //   setSubCategoryList([]);
      //   setValue('service','');
      //   setServiceList([]);
      // }
      if(categoryId >'0'){  
        setIsSubmitted(true);
        const fetchSubCategoryList= async()=>{
          try {
            const subCategoryListResponse = await ProviderServices.subCategoryList({categoryId:categoryId});
            // console.log("sub category list Response:",subCategoryListResponse);
            if(subCategoryListResponse.success===1){
              setSubCategoryList(subCategoryListResponse.data)
            }
          } catch (error) {
            
          }finally{
            setIsSubmitted(false);
          }
        }
        fetchSubCategoryList();
      }
    }
  },[categoryId])

  /*=============on subcategory selection change ============ */
  useEffect(()=>{
    if (watch('services').length>0 && !serviceDetailsData.subCategoryId) {
      setOpenConfirmationDialog(true);
    }else{
      // if(!serviceDetailsData.subCategoryId){
      //   setServiceList([]);
      //   setValue('service','');
      // }
      if(subCategoryId){
        setIsSubmitted(true);
        const fetchServiceList= async()=>{
          try {
            const serviceListResponse = await ProviderServices.serviceList({subCategoryId:subCategoryId});
            // console.log("service list Response:",serviceListResponse);
            if(serviceListResponse.success===1){
              setServiceList(serviceListResponse.data)
            }
          } catch (error) {
            
          }finally{
            setIsSubmitted(false);
          }
        }
        fetchServiceList();
      }
    }
  },[subCategoryId])
  
  /*=============on submit srvice form ============ */
  const onSubmitServiceDetails = async (data) => {
    // console.log("submitted form data:", data);
    setIsSubmitted(true);
    //grouping services for particular service master id
    const groupedServices = data.services.reduce((acc, service) => {
      const { servicesMasterId, ...serviceDetails } = service;
      
      if (!acc[servicesMasterId]) {
        acc[servicesMasterId] = {
          servicesMasterId: servicesMasterId.toString(),
          childServices: []
        };
      }
      
      acc[servicesMasterId].childServices.push({
        servicesChildId: serviceDetails.servicesChildId,
        serviceTime: serviceDetails.serviceTime,
        price: serviceDetails.price});
      
      return acc;
    }, {});
    
    //modifying received data ....services
    const modifiedServiceData = {
      ...data,
      serviceCategoryId:0,
      services: Object.values(groupedServices)
    };
  
    // console.log("modified service detail form data:", modifiedServiceData);

    // excluding service and subcategoryid as required to post
    const prepareDataForAxios = (data) => {
      const { service, subCategoryId, ...restData } = data;
      return {...restData,
        refundableAmount: restData.isRefundable ? restData.refundableAmount : 0,
        refundableDuration: restData.isRefundable ? restData.refundableDuration : 0
      };
    };

    const finalModifiedData = prepareDataForAxios(modifiedServiceData);
    // console.log(" final modified data:",finalModifiedData);
    try {
      const serviceCreationResponse = await ProviderServices.updateUserServiceDetail(finalModifiedData);
      // console.log("user Service creation response:",serviceCreationResponse);
      if (serviceCreationResponse.success=='1') {
        const servicesData = serviceCreationResponse.data;
        handleDiscard();// in this function all fields related to service reset to initial state
        Swal.fire('Service Detail Created Successfully','','success');
        setIsSubmitted(false);
        setShowList(true);
        fetchProviderServiceList({
            providerId:serviceDetailsData.providerId,
            page:1,
            pageSize:5,
        });
      }else{
          Swal.fire('Something Went Wrong','','error');
          setIsSubmitted(false);
      }
    } catch (error) {
      setIsSubmitted(false)
    }
  };

  useEffect(()=>{
    reset(serviceDetailsData);
  },[serviceDetailsData,reset])

  /*==========actions related to confirmation dialog======== */
  const handleDiscard=()=>{
    setValue('services', [])
    setValue('service','');
    setValue('subCategoryId','');
    setServiceList([]);
    setSubCategoryList([]);
    setServiceDetailsData(pre=>{
      return {...pre,
        serviceCategoryId: "",
        vistingCharge: "",
        additionalCharge: "",
        cancellationChargeInPercent: "",
        timeLimitForCancellation: "",
        isRefundable: false,
        refundableAmount: "",
        refundableDuration: "",
        services: []
      }
    });
    setOpenConfirmationDialog(false);
  }
  const handleConfirmationSave = async()=>{
    // console.log("clicked");
    // handleSubmit(onSubmitServiceDetails);
    // Trigger validation for all fields
    const result = await trigger();
    
    if (!result) {
      // Get all error messages
      const errorMessages = [];
      Object.keys(errors).forEach(key => {
        if (errors[key]?.message) {
          errorMessages.push(errors[key].message);
        }
      });
      
      console.log('Validation errors:', errorMessages);
      // You can show errors using Swal or other UI feedback
      setOpenConfirmationDialog(false);
    }else{
      handleSubmit(onSubmitServiceDetails)();
      setOpenConfirmationDialog(false)
    }
  }
  // for editing
  const [isEditing,setIsEditing] = useState(false);

  const fetchServicedetails = async(serviceId)=>{    
    // setIsSubmitted(true);
    const serviceDetailsResponse = await ProviderServices.fetchProviderServiceDetailsById({serviceId:serviceId});
    console.log("service details Response:",serviceDetailsResponse);
    if(serviceDetailsResponse.success===1){
      const serviceDetails = serviceDetailsResponse.data;
      console.log("service details:",serviceDetails);
      setServiceDetailsData(pre=>{
        return {
          ...pre,
          "serviceCategoryId": 199,
          "vistingCharge": "100",
          "additionalCharge": "10",
          "cancellationChargeInPercent": "10",
          "timeLimitForCancellation": "10",
          "isRefundable": false,
          "refundableAmount": 0,
          "refundableDuration": 0,
          "services": [
            {
              "servicesMasterId": 931,
              "servicesChildId": 932,
              "categoryId": 199,
              "subCategoryId": 200,
              "categoryTitle": "Cleaning & Pest Control",
              "subCategoryTitle": "Best Seller Packages",
              "masterServiceTitle": "Sofa & Carpet Cleaning",
              "childServiceTitle": "Cleaning & Pest Control Sofa & Carpet Cleaning",
              "serviceTime": "10",
              "price": "99.95"
            }
          ],
          "subCategoryId": 201,
          "service": 931
        }
      }
      );
      // setServiceList(serviceDetails?.services);
      setIsEditing(true);
      setShowList(false);
    }
    // setIsSubmitted(false);
  }
  return (
    <FormProvider {...serviceFormMethod}>
      {isSubmitted && <Loading/>}
    <Grid container spacing={2} component={'form'} >
      
        {/* { id=='0' || id>0 && providerServiceList.length=='0'|| id>0 && !showList ?  */}
        { (id=='0' && providerServiceList.length=='0' ) || (id>0 && providerServiceList.length=='0' ) || !showList ? 

          <>
            {/* ======================modal dialogs=========== */}
              {openConfirmationDialog && <ConfirmationDialog onSave={handleConfirmationSave} onDiscard={handleDiscard} openConfirmationDialog={openConfirmationDialog} />}
            {/* ======================modal dialogs=========== */}
            {/* ==============services=========== */}
            <Grid item xs={12} sx={{paddingTop:'1%!important'}}>
              <Card sx={{my:'1%'}}>
              <Box sx={{p:'1%',display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                    <Box sx={{p:'.5% 2%'}}>
                      <Typography sx={{fontWeight:'600',fontSize:'large',}}>Add New Services</Typography>
                    </Box>
                    <Box sx={{ }}>
                      <Button variant='outlined' disabled={providerServiceList.length=='0'} startIcon={<List/>} onClick={()=>setShowList(true)}>Show Service List</Button>
                    </Box>
                  </Box>
              </Card>
              <Card sx={{}}>
                  <Box sx={{pr:'2%',border:'2px solid lightGray',display:'flex',bgcolor:'lightGray',justifyContent:'space-between',alignItems:'center'}}>
                    <Box sx={{p:'.5% 2%',border:'2px solid lightGray',}}>
                      <Typography sx={{fontWeight:'600'}}>Service Info</Typography>
                    </Box>
                    {/* <Box sx={{ }}>
                      <Button variant='outlined' disabled={providerServiceList.length=='0'} startIcon={<List/>} onClick={()=>setShowList(true)}>Show Service List</Button>
                    </Box> */}
                  </Box>
                  <Box sx={{p:'1% 2%'}}>
                    <Box sx={{display:'flex',gap:'2%'}}>
                        <Box sx={{width:'49%'}}>
                            <Typography sx={{width:'80%',fontSize:'small' ,fontWeight:'600', textTransform:'uppercase', display:'flex ',alignItems:'center'}}>Category </Typography>
                            <Controller
                                name='serviceCategoryId'
                                control={control}
                                // rules={{required:'select category'}}
                                render={({field,fieldState:{error}})=>(
                                    <FormControl size='small' sx={{ minWidth: "100%" }}>
                                    {/* <InputLabel id="">Select Gender</InputLabel> */}
                                        <Select
                                            {...field}
                                            // label="Select Gender"
                                            // sx={{height:'2rem'}}
                                            error={!!error}
                                            // helperText={error && error?.message}
                                        >
                                        { categoryList &&
                                            categoryList.map((category,index)=>(
                                            <MenuItem value={category.id} key={index}>{category.title}</MenuItem>
                                            ))

                                        }
                                        </Select>
                                    </FormControl>
                                )}  
                            />
                        </Box>
                        <Box sx={{width:'49%'}}>
                            <Typography sx={{width:'80%',fontSize:'small' ,fontWeight:'600', textTransform:'uppercase', display:'flex ',alignItems:'center'}}>Sub Category </Typography>
                            <Controller
                                name='subCategoryId'
                                control={control}
                                // rules={{required:'select sub-category'}}
                                render={({field,fieldState:{error}})=>(
                                    <FormControl size='small' sx={{ minWidth: "100%" }}>
                                    {/* <InputLabel id="">Select Gender</InputLabel> */}
                                        <Select
                                            {...field}
                                            disabled={ categoryId == '0' || !categoryId}
                                            // label="Select Gender"
                                            // sx={{height:'2rem'}}
                                            error={!!error}
                                            // helperText={error && error?.message}
                                        >
                                        {subCategoryList &&
                                            subCategoryList.map((subCategory,scIndex)=>(
                                            <MenuItem value={subCategory.id} key={scIndex}>{subCategory.title}</MenuItem>
                                            ))
                                        }
                                        </Select>
                                    </FormControl>
                                )}  
                            />
                        </Box>   
                    </Box>
                    <ServiceSelectionComponent serviceList={serviceList} categoryList={categoryList} subCategoryList={subCategoryList} providerServiceList={providerServiceList} />  
                  </Box>
              </Card>
            </Grid>
            
            <Grid item xs={12}  sx={{paddingTop:'1%!important'}}>
              <Card sx={{}}>
                <Box sx={{p:'.5% 2%',border:'2px solid lightGray',bgcolor:'lightGray'}}>
                  <Typography sx={{fontWeight:'bold'}}>Selected Service List</Typography>
                </Box>
                <SelectedServices providerServiceList={providerServiceList} />
              </Card>
            </Grid>
            
            {/* =======================Charges Info section================== */}
            <Grid item xs={12}>
              <Card sx={{my:0}}>
                <Box sx={{p:'.5% 2%',border:'2px solid lightGray',bgcolor:'lightGray'}}>
                  <Typography sx={{fontWeight:'bold'}}>Charges Info</Typography>
                </Box>
                <ServiceCharges/>
              </Card>
            </Grid>
            <Grid item xs={12}  sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
              <Box sx={{textAlign:'center',width:'100%'}}>
                <Button disabled={!isDirty || isSubmitted || watch('services').length=='0'} variant='contained' onClick={handleSubmit(onSubmitServiceDetails)} size='large'>Save Service</Button>
              </Box>
            </Grid>
          </>
        :
        <Grid item xs={12} sx={{paddingTop:'1%!important'}} >
          <Card sx={{my:'1%'}}>
              <Box sx={{p:'1%',display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                    <Box sx={{p:'.5% 2%'}}>
                      <Typography sx={{fontWeight:'600',fontSize:'large',}}>Service List</Typography>
                    </Box>
                    <Box sx={{ }}>
                    <Button variant='outlined' color='primary' startIcon={<Add/>} onClick={()=>setShowList(false)}>Add New Service</Button>                    </Box>
                  </Box>
              </Card>
          <Card sx={{}}>
            {/*  */}
            <ServiceTable serviceList={providerServiceList} fetchProviderServiceList={fetchProviderServiceList} providerServiceModal = {providerServiceModal} fetchServicedetails={fetchServicedetails} />
          </Card>
        </Grid>
        }
      </Grid>
      </FormProvider>
  );
}

export default ServiceDetails;
