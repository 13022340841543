import { commonRequest } from "../../services/ApiCall";
import { Helpers } from "../../services/Helpers";

export const CustomerService = {
    unsortedCustomerList : async (data) =>{
        return await commonRequest("get",  `${Helpers.URL()}/api/admin/customer/customerList`, {}, Helpers.token());
    },
    sortedCustomerList : async (data) =>{
        return await commonRequest("get",  `${Helpers.URL()}/api/admin/customer?page=${data.page+1}&limit=${data.limit}&searchKey=${data.filters.searchKey}&isActive&sortKey=${data.filters.sortKey}&sortDirection=${data.filters.sortDirection}`, {}, Helpers.token());
    },
    // customorDetailById : async (id) =>{
    //     return await commonRequest("get", `${Helpers.URL()}/customer/${id}`, {}, Helpers.token());
    // },

    addCustomer : async (data) =>{
        return await commonRequest("POST", `${Helpers.URL()}/api/admin/customer/createCustomer`,data, Helpers.token());
    },
    fetchCUstomerDetailById : async (customerId) =>{
        // return await commonRequest("GET", `${Helpers.URL()}/api/admin/customer/createCustomer`,data, Helpers.token());
    },
    updateCustomerDetails : async (data,customerId) =>{
        return await commonRequest("PUT", `${Helpers.URL()}/api/admin/customer/updateCustomer/${customerId}`,data, Helpers.token());
    },
    updateCustomerStatus : async (data,customerId) =>{
        return await commonRequest("PUT", `${Helpers.URL()}/api/admin/customer/updateCustomer/${customerId}`,data, Helpers.token());
    },
    verifyCustomerByMobileNo: async (data)=>{
        return await commonRequest("POST", `${Helpers.URL()}/api/admin/customer/verifyCustomerByMobileNo`,data, Helpers.token());
    },
    verifyMobileNoOtp: async (data)=>{
        return await commonRequest("POST", `${Helpers.URL()}/api/admin/customer/verifyOtp`,data, Helpers.token());
    },

    deleteCustomerById:async (customerId)=>{
        return await commonRequest('DELETE',`${Helpers.URL()}/api/admin/customer/deleteCustomer/${customerId}`,{},Helpers.token()) 
    },
    updateMobileNumber: async (data) =>{
        return await commonRequest('POST',`${Helpers.URL()}/api/admin/customer/changeCustomerMobileNo`,data,Helpers.token());
    }
    

    // updateCustomer : async (id, data) =>{
    //     return await commonRequest("PUT",  `${Helpers.URL()}/customer/${id}`, data, Helpers.token());
    // },

    // changeStatus: async (data) =>{
    //     return await commonRequest("POST", `${Helpers.URL()}/customer/statusChange`,data, Helpers.token());
    // },

    // addBankDetail : async (data) =>{
    //     return await commonRequest("POST", `${Helpers.URL()}/users/bankDetail/create`,data, Helpers.token());
    // },

    // updateBankDetail : async (id, data) =>{
    //     return await commonRequest("PUT",  `${Helpers.URL()}/users/bankDetail/${id}`, data, Helpers.token());
    // },

    // addAddressDetail : async (data) =>{
    //     return await commonRequest("POST", `${Helpers.URL()}/users/addressDetail/create`,data, Helpers.token());
    // },

    // updateAddressDetail : async (id, data) =>{
    //     return await commonRequest("PUT",  `${Helpers.URL()}/users/addressDetail/${id}`, data, Helpers.token());
    // },

    // deleteBrand : async (id) =>{
    //     return await commonRequest("DELETE",  `${Helpers.URL()}/brand/${id}`, {}, Helpers.token());
    // },
    
}