export const LocalStore = {
    isLogin: () =>{
        let data = localStorage.getItem("data");
        if(data != null) return true;
        else return false;
    },
    doLogin: (data, next) => {
        const {user,...restItem} = data;
        localStorage.setItem("data", JSON.stringify(restItem));
        localStorage.setItem('isUser', JSON.stringify({status: true, user: user}));
        return true;
    },
    doLogout: () => {
        localStorage.removeItem("data");
        localStorage.removeItem("isUser");
        window.location.href = "/login"; // Redirect to /login page
        return true;
    },
    getCurrentUserDetails: () => {
        if(LocalStore.isLogin){
            return JSON.parse(localStorage.getItem("data"));
        }else{
            return false;
        }
    },
    getLoggedUserDetails: () => {
        if(LocalStore.isLogin){
            return JSON.parse(localStorage.getItem("isUser")).user;
        }else{
            return false;
        }
    }

}; 