import React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import {Box,Card, Grid, IconButton, ImageListItemBar, Typography } from '@mui/material';
import { DeleteOutline, Menu } from '@mui/icons-material';
import { Helpers } from '../../../services/Helpers';
import DeleteIcon from '@mui/icons-material/Delete';

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

export default function StoreFrontGallery({items}) {
  return (
    <Grid item xs={12}>
          <Card>
        <Box sx={{p:'.5% 2%',border:'2px solid lightGray',bgcolor:'lightGray'}}>
            <Typography sx={{fontWeight:'600'}}>Storefront Photos</Typography>
        </Box>
        <Box sx={{px:'2%'}}>
            <ImageList 
                variant="quilted"
                cols={5} 
                gap={5}
                // className="w-full overflow-hidden"
                sx={{width:'100%'}}
            >
                {items && items.length>0 && items.map((item) => (
                <ImageListItem 
                    key={item}
                    className="aspect-square overflow-hidden"
                >
                    <img
                        src={`${Helpers.ImagePathUrl()}/storefront-gallery/md/${item}?w=400&h=400&fit=cover&auto=format`}
                        srcSet={`${Helpers.ImagePathUrl()}/storefront-gallery/md/${item}?w=400&h=400&fit=cover&auto=format&dpr=2 2x`}
                        alt={item}
                        // className="object-cover w-full h-full"
                        loading="lazy"
                    />
                    {/* <ImageListItemBar
                        // title={item.title}
                        // subtitle={item.author}
                        actionIcon={
                            <> */}
                        
                            {/* <IconButton
                                color='warning'
                                // sx={{ color: 'warning' }}
                                aria-label={`info about ${item.title}`}
                            >
                                <Edit />
                            </IconButton> */}
                            {/* <IconButton
                                sx={{ color: 'red' }}
                                aria-label={`info about ${item.title}`}
                            >
                                <DeleteIcon />
                            </IconButton>
                            <IconButton
                                color='primary'
                                // sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                aria-label={`info about ${item.title}`}
                            >
                                <Menu />
                            </IconButton> */}
                        {/* </>
                        }
                    /> */}
                </ImageListItem>
                
                ))}
            </ImageList>

        </Box>
    </Card>
    </Grid>
  );
}

