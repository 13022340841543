import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { Button, Box, Typography, Paper } from "@mui/material";
import { MuiOtpInput } from 'mui-one-time-password-input';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import ResendTimer from "../../../components/ResendTimer";
import Loading from "../../../components/Loader/Loading";
import { LocalStore } from "../../../storage/authStore";
import { Auth } from "../../../services/AuthServices";
import { isMobile } from "react-device-detect";
import { UserContext } from './OtrDetails';
import getDeviceType from '../../../utils/deviceType';
import { UserProfileContext } from '../UserProfile';

export default function Otp() {
  const {providerPersonalDetails,setProviderPersonalDetails,setTabValue,handleNextTab}= useContext(UserProfileContext);
  const { sendOtp, setMobile, step, setStep,otrDetail } = useContext(UserContext);
  const deviceType = getDeviceType();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [disableVerifyButton, setDisableVerifyButton] = useState(true);

  const handleChange = (otpValue) => {
    setOtp(otpValue);

    // Disable the verify button if OTP is not complete
    if (otpValue.length === 6) {
      setDisableVerifyButton(false);
    } else {
      setDisableVerifyButton(true);
    }
  };

  const handleComplete = () => {
    // Once OTP is complete, enable the verify button
    setDisableVerifyButton(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const dataTobeSend = {
      mobileNumber: otrDetail?.mobileNumber.replace(/\D/g, ''),
      otp: Number(otp),
      "deviceToken": "",
      "deviceInfo": "",
      isDeviceType: deviceType,
      isUserType: otrDetail.userType=='4'&&'provider',
    };

    let response = await Auth.verifyOtp(dataTobeSend);
    // console.log("otp verification response:",response);
    if (response.success) {
      setTabValue('1');
      setIsLoading(false);
    } else {
      setIsLoading(false);
      toast.error(response.message);
    }
  };

  const [resendOtpTime, setResendOtpTime] = useState(120);

  const handleOtpResend = async (e) => {
    setIsLoading(true);
    setOtp('');
    setMobile(otrDetail?.mobileNumber.replace(/\D/g, ''));
    await sendOtp(e);
    setIsLoading(false);
    setResendOtpTime(120);
    toast.success('Otp Resent')

  };

  let otpBoxSize = isMobile ? "100%" : "350px";

  return (
    <>
      <Paper sx={{ my: 8, mx: 4, p: '2%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box sx={{ textAlign: 'left', width: '85%', my: '2%' }}>
          <Typography component="h1" variant="h5">OTP Verification</Typography>
          <Typography component="h1" variant="h6">Enter the OTP you received to</Typography>
          <Typography component="h1" variant="h6">+91-{otrDetail.mobileNumber.replace(/\D/g, '')}</Typography>
        </Box>
        <Box component="form" noValidate sx={{ mt: 1 }} width={'100%'} onSubmit={handleSubmit}>
          <MuiOtpInput
            sx={{ width: otpBoxSize, margin: 'auto' }}
            onComplete={handleComplete}
            gap={2}
            length={6}
            value={otp}
            onChange={handleChange}
          />
          <Box sx={{ my: '5%', textAlign: 'center' }}>
            <Button disabled={disableVerifyButton} variant="contained" type="submit">
              Verify Otp
            </Button>
          </Box>
          <Typography textAlign={'center'}>
            OTP will expire in:
            <Box component={'span'} sx={{ color: `${resendOtpTime < 60 && resendOtpTime > 0 ? 'red' : 'green'}`, fontSize: '.75rem', ml: '2%' }}>
              <ResendTimer counter={resendOtpTime} setCounter={setResendOtpTime} />
            </Box>
            <Box component={'span'} sx={{ fontSize: '.5rem' }}> seconds</Box>
          </Typography>
          <Box sx={{ my: '5%', display: 'flex', justifyContent: 'space-between', padding: '0 5%' }}>
            <Button onClick={handleOtpResend} disabled={resendOtpTime > 0}>Resend OTP</Button>
            <Typography paragraph variant="body1">
              <span>Click Here! </span>
              <Box component={'span'} onClick={() => setStep(0)} sx={{ textDecoration: 'none', cursor: 'pointer', color: '#1976d2' }}>
                Change mobile number
              </Box>
            </Typography>
          </Box>
        </Box>
      </Paper>
      <ToastContainer />
      {isLoading && <Loading />}
    </>
  );
}
