import { Box, Button, IconButton, Paper, Switch, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import CircleIcon from '@mui/icons-material/Circle';
import { useNavigate } from 'react-router-dom';
import { RoleDataContext } from '.';
import { PermissionGuard } from '../../../components/PermissionGuard';
import Swal from 'sweetalert2';
import { RoleAndPermissionServices } from '../RoleAndPermissionServices';
import { Delete } from '@mui/icons-material';

const RoleCard = ({roleData,performEdit,fetchRoleAndPermissionList}) => {
  const {actionTab,setActionTab,selectedRole,setSelectedRole} = useContext(RoleDataContext);
  const navigate = useNavigate();

  const handleViewRole = ()=>{
    setSelectedRole(roleData);
    setActionTab(2);
  }

  const handleEdit =()=>{
    setSelectedRole(roleData);
    performEdit(selectedRole,'edit')
  }

  const getEnabledPermissions = (permission) => {
    const enabled = [];
    if (permission.canView) enabled.push('View');
    if (permission.canCreate) enabled.push('Create');
    if (permission.canEdit) enabled.push('Edit');
    if (permission.canDelete) enabled.push('Delete');
    
    if (enabled.length === 0) return 'No permissions for';
    
    // Join with commas and 'and' for the last item
    if (enabled.length === 1) return `${enabled[0]} is enabled for`;
    
    const lastPermission = enabled.pop();
    return `${enabled.join(', ')} and ${lastPermission}`;
  };
  const handleStatusChange = async (roleId) => {
    const result = await Swal.fire({
      text: "Do you want to Change Status now?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Change now!',
      cancelButtonText: 'No, Cancel',
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCloseButton: false,
    });
  
    if (result.isConfirmed) {
      try {
        // Show loading state
        Swal.fire({
          title: 'Processing...',
          text: 'Please wait while we update the status',
          allowOutsideClick: false,
          allowEscapeKey: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });
  
        // Make the API call
        const statusChangeResponse = await RoleAndPermissionServices.changeRoleStatus(roleData.roleId);
        // console.log("role status change response:", statusChangeResponse);
  
        // Close loading dialog and show success/error message
        if (statusChangeResponse.success === 1) {
          await fetchRoleAndPermissionList();
          await Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: statusChangeResponse.message || 'Status updated successfully',
            timer: 1500
          });
          
          return true;
        } else {
          await Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: statusChangeResponse.message || 'Failed to update status'
          });
          return false;
        }
  
      } catch (error) {
        // console.error("Error changing role status:", error);
        await Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: 'An error occurred while updating the status'
        });
        return false;
      }
    } else {
      return false;
    }
  };
  const  handleRoleDelete= async (roleId) => {
    const result = await Swal.fire({
      text: "Do you want to Delete Role now?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete now!',
      cancelButtonText: 'No, Cancel',
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCloseButton: false,
    });
  
    if (result.isConfirmed) {
      try {
        // Show loading state
        Swal.fire({
          title: 'Processing...',
          text: 'Please wait while we Deleting role',
          allowOutsideClick: false,
          allowEscapeKey: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });
  
        // Make the API call
        const roleDeleteResponse = await RoleAndPermissionServices.deleteRole(roleData.roleId);
        // console.log("role delete response:", roleDeleteResponse);
  
        // Close loading dialog and show success/error message
        if (roleDeleteResponse.success === 1) {
          await fetchRoleAndPermissionList();
          await Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: roleDeleteResponse.message || 'role deleted successfully',
            timer: 1500
          });
          
          return true;
        } else {
          await Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: roleDeleteResponse.message || 'Failed to delete role'
          });
          return false;
        }
  
      } catch (error) {
        // console.error("Error deleting role:", error);
        await Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: 'An error occurred while deleting the role'
        });
        return false;
      }
    } else {
      return false;
    }
  };
  
  return (
    <Paper sx={{p:'5%'}}>
      <Box sx={{display:'flex',justifyContent:'space-between'}}>
        <Typography variant='body1'sx={{fontWeight:600,fontSize:'x-large',textTransform:'uppercase'}}>{roleData?.roleName}</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Switch
            checked={roleData?.isActive}
            onChange={handleStatusChange}
            sx={{
              '& .MuiSwitch-switchBase.Mui-checked': {
                color: 'success.main',
              },
              '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                backgroundColor: 'success.main'
              },
              '& .MuiSwitch-switchBase': {
                color: 'error.main',
              },
              '& .MuiSwitch-track': {
                backgroundColor: 'error.main'
              }
            }}
            inputProps={{ 'aria-label': 'status toggle' }}
          />
          <Typography
            variant="body2"
            sx={{
              color: roleData?.isActive ? 'success.main' : 'error.main',
              fontWeight: 'medium'
            }}
          >
            {roleData?.isActive ? 'Active' : 'Inactive'}
          </Typography>
          <IconButton color='error' onClick={handleRoleDelete}>
            <Delete/>
          </IconButton>
        </Box>
      </Box>
      {/* =============== Total user Asssociated with role ============== */}
      <Typography variant='caption' >Total users with this role:
        <Box component={'span'} sx={{
        filter: `blur(${'4'}px)`,
        opacity: '0.7',
        transition: 'filter 0.3s ease',
      }}>10</Box>
      </Typography>
      <Box sx={{my:'3%'}}>
        {
            roleData.permissions.map((permission,index)=>(
              <Box key={index}>
                {index<=5 &&
                  <Box sx={{display:'flex',alignItems:'flex-start'}} >
                    <Box sx={{fontSize:'x-small',mr:'3%',lineHeight:'2.5'}}><CircleIcon color='primary' fontSize='inherit'/></Box>
                    <Typography variant='body2'>
                        {getEnabledPermissions(permission)} <Box component={'span'} sx={{fontWeight:'600'}}>{permission.featureName}</Box>
                    </Typography>
                  </Box>
                }
              </Box>
            ))
        }
        <Box sx={{my:'2%'}}>
          <Typography variant='body2'><Box component={'span'} sx={{fontSize:'x-small',mr:'3%'}}><CircleIcon color='primary' fontSize='inherit'/></Box> and {roleData.permissions.length-6} more...</Typography>
        </Box>
      </Box>
      <Box sx={{display:'flex',gap:'5%',justifyContent:'center'}}>
        <Button variant='outlined' color='warning' size='medium' onClick={handleViewRole}>View Role</Button>
        <PermissionGuard feature={'roles-and-permissions'} permission='canEdit'>
          <Button variant='outlined' color='info' size='medium' onClick={handleEdit}>Edit Role</Button>
        </PermissionGuard>
      </Box>
    </Paper>
  );
}

export default RoleCard;
