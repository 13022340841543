import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  Button,
  Typography,
  Grid,
  Box,
  InputLabel,
  Stack,
} from '@mui/material';
import Swal from 'sweetalert2';
import { Helpers } from '../../services/Helpers';
import { CustomerService } from './Service';
import { useNavigate } from 'react-router-dom';
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Loading from '../../components/Loader/Loading';

export default function PersonalDetails({ personalDetails, onSubmitPersonal }) {
  const { control, handleSubmit, setValue, formState: { errors,isDirty },reset } = useForm({
    mode: "onChange",
    defaultValues:{
      firstName: '',
      middleName: '',
      lastName: '',
      mobileNumber: '',
      gender: '',
      email: '',
      dob: ''
    }
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (personalDetails) {
      reset({
        firstName: personalDetails.firstName,
        middleName: personalDetails.middleName,
        lastName: personalDetails.lastName,
        mobileNumber: personalDetails.mobileNumber,
        gender: personalDetails.gender,
        email: personalDetails.email,
        dob: personalDetails.dob
      })
    }
  }, [personalDetails]);

  const [isSubmitting,setIsSubmitting] = useState(false);
  const onSubmit = (data) => {
    setIsSubmitting(true)
    // console.log("submitted customer data:",data)
    const personalData = {
      firstName: data.firstName,
      middleName: data.middleName,
      lastName: data.lastName,
      mobileNumber: data.mobileNumber,
      gender: data.gender,
      email: data.email,
      dob: data.dob
    }

    const createRow = async () => {
      let createRowRes = "";
      let msg = "";
        if (personalDetails && personalDetails?.id > 0) {
          createRowRes = await CustomerService.updateCustomerDetails(personalData,personalDetails.id );
          personalData.id = personalDetails.id;
          msg = "Update Successfully";
        } else {
          // personalData.password = data.password;
          createRowRes = await CustomerService.addCustomer(data);
          if (createRowRes.success) {
            personalData.id = createRowRes.data.id;
            msg = "User Registerd Successfully";
          } else {
            Swal.fire(createRowRes.message, '', 'error')
          }
        }
      if (createRowRes.success) {
        onSubmitPersonal()
        // navigate(`/admin/customers`);

        Swal.fire(msg, '', 'success')
        setIsSubmitting(true)
      } else {
        Swal.fire(createRowRes.message, '', 'error');
        setIsSubmitting(false)
      }
    }
    createRow();
  };


  return (
    <Box sx={{ overflow: "auto", backgroundColor: "#fff" }}>
      {isSubmitting && <Loading/>}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} sx={{ paddingTop: '15px' }}>
          <Grid item lg={9} xs={12} sx={{margin:'auto'}}>
            <Grid container spacing={2} sx={{ paddingTop: '15px' }}>
            <Grid item lg={4} xs={12}>
              <Typography sx={{fontWeight:'600', texttTransform:'uppercase', display:'flex ',alignItems:'center'}}>First Name<span style={{color:'red'}}>*</span></Typography>
              <Controller
                name="firstName"
                control={control}
                defaultValue=""
                rules={{
                  required: "required",
                  pattern:{
                      value:/^[a-zA-Z]*$/,
                      message:"only alphabets allowed"
                  },
                  minLength:{
                      value:3,
                      message:"minimum 3 digit"
                  },
                  maxLength:{
                      value:30,
                      message:"maximum 30 digit"
                  }
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder="Enter First Name"
                    // label="Enter First Name"
                    size="small"
                    fullWidth
                    error={!!errors.firstName}
                    // helperText={errors.lastName && errors.lastName.message}
                  />
                )}
              />
            </Grid>
            <Grid item lg={4} xs={12}>
              <Typography sx={{fontWeight:'600', texttTransform:'uppercase', display:'flex ',alignItems:'center'}}>Middle Name</Typography>
              <Controller
                name="middleName"
                control={control}
                defaultValue=""
                rules={{
                  // required: "required",
                  pattern:{
                      value:/^[a-zA-Z]*$/,
                      message:"only alphabets allowed"
                  },
                  minLength:{
                      value:3,
                      message:"minimum 3 digit"
                  },
                  maxLength:{
                      value:30,
                      message:"maximum 30 digit"
                  }
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder="Enter Middle Name"
                    size="small"
                    fullWidth
                    error={!!errors.middleName}
                  />
                )}
              />
            </Grid>
            <Grid item lg={4} xs={12}>
              <Typography sx={{fontWeight:'600', texttTransform:'uppercase', display:'flex ',alignItems:'center'}}>Last Name<span style={{color:'red'}}>*</span></Typography>
              <Controller
                name="lastName"
                control={control}
                defaultValue=""
                rules={{
                  required: "required",
                  pattern:{
                      value:/^[a-zA-Z]*$/,
                      message:"only alphabets allowed"
                  },
                  minLength:{
                      value:3,
                      message:"minimum 3 digit"
                  },
                  maxLength:{
                      value:30,
                      message:"maximum 30 digit"
                  }
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder="Enter Last Name"
                    size="small"
                    fullWidth
                    error={!!errors.lastName}
                  />
                )}
              />
            </Grid>
            <Grid item lg={8} xs={12}>
              <Typography sx={{fontWeight:'600', texttTransform:'uppercase', display:'flex ',alignItems:'center'}}>Email<span style={{color:'red'}}>*</span></Typography>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                rules={{
                  required:'enter Email',
                  pattern:{
                      value:/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
                      message:"Email is not Valid"
                  }
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder="Enter Email"
                    // label="Enter Email"
                    size="small"
                    fullWidth
                    error={!!errors.email}
                  />
                )}
              />
            </Grid>

            <Grid item lg={4} xs={12}>
              <Typography sx={{fontWeight:'600', texttTransform:'uppercase', display:'flex ',alignItems:'center'}}>Mobile Number<span style={{color:'red'}}>*</span></Typography>
              <Controller
                  name="mobileNumber"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: 'Mobile number is required',
                      pattern: {
                        value: /^[1-9][0-9]{9}$/,
                        message: "Enter a valid 10-digit number not starting with 0"
                    },
                    validate: (value) => {
                      if (value && value.length !== 10) {
                        return "Mobile number must be exactly 10 digits";
                      }
                      return true;
                    }
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="Enter Mobile Number"
                      // label="Enter Mobile Number"
                      size="small"
                      type='number'
                      fullWidth
                      disabled={personalDetails&& personalDetails?.mobileNumber}
                      error={!!errors.mobileNumber}
                      inputProps={{
                        maxLength: 10,
                      }}
                      onChange={(e) => {
                        const value = e.target.value.replace(/[^0-9]/g, '').slice(0, 10);
                        field.onChange(value);
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item lg={4} xs={12}>
                <Typography sx={{width:'60%' ,fontWeight:'600', texttTransform:'uppercase', display:'flex ',alignItems:'center'}}>Gender<span style={{color:'red'}}>*</span></Typography>
                <Controller
                  name='gender'
                  control={control}
                  rules={{required:'select gender'}}
                  render={({field,fieldState:{error}})=>(
                      <FormControl size='small' sx={{ minWidth: "100%" }}>
                        {/* <InputLabel id="">Select Gender</InputLabel> */}
                          <Select
                              {...field}
                              placeholder='Select Gender'
                              error={!!error}
                          >
                            <MenuItem value="Male">Male</MenuItem>
                            <MenuItem value="Female">Female</MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                          </Select>
                      </FormControl>
                  )}  
                />
              </Grid>
              <Grid item lg={4} xs={12}>
                <Typography sx={{fontWeight:'600', texttTransform:'uppercase', display:'flex ',alignItems:'center'}}>Date Of Birth<span style={{color:'red'}}>*</span></Typography>
                <Controller
                  name="dob"
                  control={control}
                  rules={{
                    required: "Date of Birth is required",
                    validate: (value) => {
                      if (!value) return "This field is required";
                      const date = dayjs(value);
                      const today = dayjs().startOf('day');
                      const eighteenYearsAgo = today.subtract(18, 'year');
                      
                      if (!date.isValid()) return "Invalid date";
                      if (date.isAfter(today)) return "Date of Birth must be in the past";
                      if (date.isAfter(eighteenYearsAgo)) return "must be at least 18 years old";
                      
                      return true;
                    }
                  }}
                  render={({ field: { onChange, value, ...restField }, fieldState: { error } }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        {...restField}
                        value={value ? dayjs(value) : null}
                        onChange={(newValue) => {
                          // Store the date in YYYY-MM-DD format for database compatibility
                          onChange(newValue ? newValue.format("YYYY-MM-DD") : null);
                        }}
                        format="DD-MM-YYYY" // Display format for user
                        maxDate={dayjs()}
                        slotProps={{
                          textField: {
                            error: !!error,
                            helperText: error?.message,
                            fullWidth: true,
                            // required: true,
                            size: 'small',
                          }
                        }}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Grid>
              <Grid item lg={12} xs={12}>
                <Box textAlign={'center'}>
                  <Button disabled={isSubmitting||!isDirty} type="submit" variant="contained" color="primary">
                    {personalDetails ? 'update':'Register'}
                  </Button>
                  <Box sx={{ flexGrow: 1 }}></Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={7} xs={12}>

          </Grid>
        </Grid>
      </form>
    </Box >
  );
}
