import React, { useEffect, useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import {
  TextField,
  Grid,
  Box,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Card,
  FormControlLabel,
  Checkbox,
  CardContent
} from '@mui/material';
import statelist from '../../../utils/StateDistList.json';
import CoordinateDetails from './CoordinateDetails';

export default function AddressDetails() {
  const { control, setValue, watch } = useFormContext();
  const [bothAddressSame,setBothAddressSame]=useState(false);

  const selfAddress = watch('selfAddress');
  const serviceAddress = watch('serviceAddress');

  // const same = watch('same');
  const selfAddressState = watch('selfAddress.state');
  const selfAddressAddress = watch('selfAddress.address');
  const selfAddressCity = watch('selfAddress.city');
  const selfAddressPincode = watch('selfAddress.pincode');
  const selfAddressLandMark = watch('selfAddress.landmark');
  const selfAddressMobile = watch('selfAddress.mobileNumber');
  const selfAddressLatitude = watch('selfAddress.latitude');
  const selfAddressLongitude = watch('selfAddress.longitude');

  useEffect(() => {
    
    if (bothAddressSame) {
      setValue('serviceAddress.address',selfAddressAddress,{shouldValidate:true,shouldDirty:true});
      setValue('serviceAddress.state',selfAddressState,{shouldValidate:true,shouldDirty:true});
      setValue('serviceAddress.city',selfAddressCity,{shouldValidate:true,shouldDirty:true});
      setValue('serviceAddress.pincode',selfAddressPincode,{shouldValidate:true,shouldDirty:true});
      setValue('serviceAddress.landmark',selfAddressLandMark,{shouldValidate:true,shouldDirty:true});
      setValue('serviceAddress.mobileNumber',selfAddressMobile,{shouldValidate:true,shouldDirty:true});
      setValue('serviceAddress.latitude',selfAddressLatitude,{shouldValidate:true,shouldDirty:true});
      setValue('serviceAddress.longitude',selfAddressLongitude,{shouldValidate:true,shouldDirty:true});
    }
  }, [bothAddressSame,selfAddressState,selfAddressCity,selfAddressAddress,selfAddressPincode,selfAddressLandMark,selfAddressMobile,selfAddressLatitude,selfAddressLongitude],{shouldValidate:true,shouldDirty:true});
  
  const handleAddressChange = (e) => {
    const { checked } = e.target;
    setBothAddressSame(checked);
    if (!checked) {
      setValue('serviceAddress.address','',{shouldValidate:true,shouldDirty:true});
      setValue('serviceAddress.state','',{shouldValidate:true,shouldDirty:true});
      setValue('serviceAddress.city','',{shouldValidate:true,shouldDirty:true});
      setValue('serviceAddress.pincode','',{shouldValidate:true,shouldDirty:true});
      setValue('serviceAddress.landmark','',{shouldValidate:true,shouldDirty:true});
      setValue('serviceAddress.mobileNumber','',{shouldValidate:true,shouldDirty:true});
      setValue('serviceAddress.latitude','',{shouldValidate:true,shouldDirty:true});      
      setValue('serviceAddress.longitude','',{shouldValidate:true,shouldDirty:true});
      setValue('serviceAddress.serviceArea', '1',{shouldValidate:true,shouldDirty:true});
      setValue('serviceAddress.addressType','1',{shouldValidate:true,shouldDirty:true});
    }
  };

  useEffect(() => {
    // Check if addresses are the same when component mounts
    const addressesAreSame = Object.keys(selfAddress).every(
      key => selfAddress[key] === serviceAddress[key]
    );
    // console.log("address is same:",addressesAreSame)
    setBothAddressSame(addressesAreSame);
  }, []);

  const renderAddressFields = (prefix, disabled = false) => (
    <>
      {/* <Box>
        <Typography sx={{ fontWeight: 'bold' }}>Address<span style={{color:'red'}}>*</span></Typography>
        <Controller
          name={`${prefix}.address`}
          control={control}
          rules={{ required: "Required" }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              fullWidth
              size='small'
              multiline
              disabled={disabled}
              // InputProps={{ inputProps: { style: { textTransform: "uppercase" } } }}
              error={!!error}
            />
          )}
        />
      </Box> */}
      {/* <Box>
          <Typography sx={{ fontWeight: 'bold' }}>Landmark<span style={{color:'red'}}>*</span></Typography>
          <Controller
            name={`${prefix}.landmark`}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                size='small'
                disabled={disabled}
                // InputProps={{ inputProps: { style: { textTransform: "uppercase" } } }}
                error={!!error}
              />
            )}
          />
        </Box> */}
      
      {/* <Box sx={{ display: 'flex', gap: '2%', my: '1%' }}>
        <Box sx={{ width: '50%' }}>
          <Typography sx={{ fontWeight: 'bold' }}>State<span style={{color:'red'}}>*</span></Typography>
          <Controller
            name={`${prefix}.state`}
            control={control}
            rules={{ required: 'Select state' }}
            render={({ field, fieldState: { error } }) => (
              bothAddressSame && prefix!='selfAddress' ?
              <TextField
                {...field}
                error={!!error}
                fullWidth
                disabled
                size='small'
              />
              :<FormControl fullWidth>
                <Select {...field} size='small' error={!!error} disabled={disabled}>
                  {statelist.map((item, index) => (
                    <MenuItem value={item.state} key={index}>{item.state}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </Box>
        <Box sx={{ width: '50%' }}>
          <Typography sx={{ fontWeight: 'bold' }}>City<span style={{color:'red'}}>*</span></Typography>
          <Controller
            name={`${prefix}.city`}
            control={control}
            rules={{
              required: 'Required',
              pattern: { value: /^[a-zA-Z ]*$/, message: "Only alphabets allowed" },
              minLength: { value: 3, message: "Minimum 3 characters" },
              maxLength: { value: 30, message: "Maximum 30 characters" }
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                size='small'
                disabled={disabled}
                // InputProps={{ inputProps: { style: { textTransform: 'uppercase' } } }}
                error={!!error}
              />
            )}
          />
        </Box>
      </Box> */}
      
      <Box sx={{ display: 'flex', gap: '2%', my: '1%' }}>
        {/* <Box sx={{ width: '49%' }}>
          <Typography sx={{ fontWeight: 'bold' }}>Pincode<span style={{color:'red'}}>*</span></Typography>
          <Controller
            name={`${prefix}.pincode`}
            control={control}
            rules={{
              required: "Required",
              pattern: { value: /[1-9]{1}[0-9]{5}/, message: "Only numeric: not starting with 0" },
              minLength: { value: 6, message: "Should be 6 digits" },
              maxLength: { value: 6, message: "Should be 6 digits" }
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                type='number'
                size='small'
                disabled={disabled}
                // InputProps={{ inputProps: { style: { textTransform: "uppercase" } } }}
                error={!!error}
              />
            )}
          />
        </Box> */}
        <Box sx={{width:'68%'}}>
          <Typography sx={{ fontWeight: 'bold' }}>Landmark</Typography>
          <Controller
            name={`${prefix}.landmark`}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                size='small'
                disabled={disabled}
                // InputProps={{ inputProps: { style: { textTransform: "uppercase" } } }}
                error={!!error}
              />
            )}
          />
        </Box>
        <Box sx={{width:'30%'}}>
          <Typography sx={{fontWeight:'600', texttTransform:'uppercase', display:'flex ',alignItems:'center'}}>Contact Number </Typography>
          <Controller
            name={`${prefix}.mobileNumber`}
            control={control}
            rules={{
              // required: 'Mobile number is required',
            //   pattern: {
            //     value: /^[6-9][0-9]{9}$/,
            //     message: "Mobile number must start with 6, 7, 8, or 9"
            // },
            // validate: {
            //     numbersOnly: (value) => 
            //         !value || /^\d+$/.test(value) || "Only numbers are allowed",
                
            //     validLength: (value) => {
            //         if (!value) return true;
            //         if (value.length < 10) return "Mobile number must be 10 digits long";
            //         if (value.length > 10) return "Mobile number cannot exceed 10 digits";
            //         return true;
            //     },
                
            //     // noRepeatedDigits: (value) => 
            //     //     !value || !/^(\d)\1{9}$/.test(value) || 
            //     //     "Invalid mobile number: Cannot have all same digits",
            // }
            validate: (value) => {
              if (!value) return true;
              if (value[0] === '0') {
                return "Mobile number cannot start with 0";
            }
              if (value.length !== 10) {
                  return "Mobile number must be exactly 10 digits";
              }
              
              
              
              if (!/^\d+$/.test(value)) {
                  return "Only numbers are allowed";
              }
              
              return true;
          }
          }}
          render={({ field, fieldState: { error } }) => (
              <TextField
                  {...field}
                  size='small'
                  error={!!error}
                  helperText={error?.message}
                  inputProps={{
                      maxLength: 10,
                  }}
                  onChange={(e) => {
                      const value = e.target.value.replace(/[^0-9]/g, '').slice(0, 10);
                      field.onChange(value);
                  }}
              />
            )}                       
          />
        </Box>
        
      </Box>
      {/* <CoordinateDetails prefix={prefix}/> */}
    </>
  );

  return (
    <Grid item xs={12}>
      <Card sx={{ my: 0.5 }}>
        <Box sx={{ p: '.5% 2%', border: '2px solid lightGray', bgcolor: 'lightGray' }}>
          <Typography sx={{ fontWeight: 'bold' }}>Address Info</Typography>
        </Box>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <Card sx={{ my: 0.5, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
                <Box sx={{ borderBottom: '2px solid lightGray' }}>
                  <Typography sx={{ p: '.5% 2%', fontWeight: 'bold', bgcolor: 'lightGray', width: 'fit-content' }}>Service Expert Address</Typography>
                </Box>
                <Box sx={{ p: '2%', pt: 5.5 }}>
                  
                  <CoordinateDetails prefix="selfAddress" />
                  {renderAddressFields('selfAddress')}
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Card sx={{ my: 0.5, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
                <Box sx={{ borderBottom: '2px solid lightGray' }}>
                  <Typography sx={{ p: '.5% 2%', fontWeight: 'bold', bgcolor: 'lightGray', width: 'fit-content' }}>Service Address Self/House</Typography>
                </Box>
                <Box sx={{ p: '2%', pt: 0 }}>
                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox 
                          checked={bothAddressSame}
                          onChange={handleAddressChange}
                        />
                      }
                      label="Same as Home Address"
                    />
                  </Box>
                  
                  <CoordinateDetails prefix="serviceAddress" />
                  {renderAddressFields('serviceAddress', bothAddressSame)}
                </Box>
              </Card>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}