import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import CustomerList from './CustomerList'

export default function Customer(){
    const menuDispatch = useDispatch();
    useEffect(() => {
        menuDispatch({
            type: 'menuSelect',
            payload: 'customer'
        });
    }, []);
    return (
        <>
            <CustomerList />            
        </>
    )
}
