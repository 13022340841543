import React, { useContext, useEffect, useState } from 'react';
import {Button,  Typography,MenuItem,  Grid,  Box,Card, TextField, FormControl, Select, Chip} from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

export default function PersonalDetails({ personalDetails}) {
  
  const [providerPersonalDetails, setProviderPersonalDetails] = useState(
    {
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      dob: '',
      mobileNumber:'',
      serviceCategories: [],
      waiveVisitCharge: 0,
      gender: '',
      image: '',
    }
  );
  useEffect(()=>{
    if (personalDetails) {
      // console.log("personal details:",personalDetails)
      setProviderPersonalDetails(pre=>({...pre,...personalDetails}))
    }
  },[personalDetails])
  return (
      <Grid container >
        {/* =======================Basic Info section================== */}
        <Grid item xs={12}>
          <Card>
            <Box sx={{p:'.5% 2%',border:'2px solid lightGray',bgcolor:'lightGray'}}>
                <Typography sx={{fontWeight:'600'}}>Personal Info</Typography>
            </Box>
            <Box sx={{p:'2%'}}>
              <Box sx={{display:'flex',gap:'2%'}}>
                <Box sx={{my:'1%'}}>
                  <Typography sx={{width:'60%' ,fontWeight:'600', texttTransform:'uppercase', display:'flex ',alignItems:'center'}}>Full Name</Typography>
                  <TextField
                    sx={{}}
                    size='small'
                    value={`${providerPersonalDetails.firstName?providerPersonalDetails.firstName:''} ${providerPersonalDetails.middleName ? providerPersonalDetails.middleName :''} ${providerPersonalDetails.lastName? providerPersonalDetails.lastName:''}`}
                    slotProps={{
                      input: {
                        readOnly: true,
                      },
                    }}
                  />                    
                </Box>  
                <Box sx={{flex:2,my:'1%'}}>
                  <Typography sx={{width:'60%' ,fontWeight:'600', texttTransform:'uppercase', display:'flex ',alignItems:'center'}}>Email</Typography>
                  <TextField
                    fullWidth
                    // sx={{width:'80%'}}
                    size='small'
                    value={providerPersonalDetails.email} 
                    slotProps={{
                      input: {
                        readOnly: true,
                      },
                    }}
                  />               
                </Box>
                {/* <Box sx={{my:'1%'}}>
                  <Typography sx={{width:'65%' ,fontWeight:'600', texttTransform:'uppercase', display:'flex ',alignItems:'center'}}>Contact Number </Typography>
                    <TextField
                      // sx={{width:'58%'}}
                      size='small' 
                      value={providerPersonalDetails.mobileNumber}
                    />
                </Box> */}
              </Box>           
              {/* <Box sx={{display:'flex' , gap:'2%',my:'1%'}}>
                <Box sx={{flex:1}}>
                  <Typography sx={{width:'60%' ,fontWeight:'600', texttTransform:'uppercase', display:'flex ',alignItems:'center'}}>Date Of Birth</Typography>     
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker 
                      disabled
                      value={providerPersonalDetails.dob ? dayjs(providerPersonalDetails.dob) : null}
                      format="DD-MM-YYYY" // Display format for user
                      maxDate={dayjs()}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          required: true,
                          size: 'small',
                        }
                      }}
                    />
                  </LocalizationProvider>  
                </Box>
                <Box sx={{flex:1}}>
                  <Typography sx={{width:'60%' ,fontWeight:'600', texttTransform:'uppercase', display:'flex ',alignItems:'center'}}>Gender </Typography>
                    <FormControl fullWidth>
                        <Select
                            value={providerPersonalDetails.gender}
                            size='small'
                            // sx={{height:'2rem'}}
                            
                            
                        >
                            <MenuItem sx={{textTransform:'capitalize'}} value='Male'>male</MenuItem>
                            <MenuItem sx={{textTransform:'capitalize'}}value='Female'>female</MenuItem>
                            <MenuItem sx={{textTransform:'capitalize'}} value='Other'>other</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{flex:1}}>
                  <Typography sx={{fontWeight:'600', texttTransform:'uppercase', display:'flex ',alignItems:'center'}}>waive Visit Charge </Typography>
                    <TextField
                      value={providerPersonalDetails.waiveVisitCharge}
                      size='small'
                    />      
                </Box>
              </Box> */}
              {/* <Box sx={{display:'flex' , gap:'2%'}}>
                <Box sx={{flex:2}}>
                  <Typography sx={{fontWeight:'600', texttTransform:'uppercase', display:'flex ',alignItems:'center'}}>Service Categories </Typography>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 ,border:'1px solid lightGray',p:'1%',borderRadius:'.25rem',minHeight:'2.5rem'}}>
                    {providerPersonalDetails.serviceCategories.map(id => {
                      const category = categoryList.find(opt => opt.id == id);
                      if (category) {
                        return (
                          <Chip
                            key={id}
                            label={category.title}
                            color="primary"
                            variant="outlined"
                            size="small"
                          />
                        );
                      }
                      return null;
                    })}
                  </Box> 
                </Box> 
              </Box>  */}
            </Box>
          </Card>
        </Grid>
        {/* =======================address section================== */}
        {/* <AddressDetails/> */}
      </Grid>
  );
}
