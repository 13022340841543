import React, { useContext } from 'react';
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";
import { ToastContainer, toast } from 'react-toastify';
import Loading from '../../../components/Loader/Loading';
import { useNavigate } from 'react-router-dom';
import { UserContext } from './OtrDetails';
import {FormProvider, useForm, Controller } from 'react-hook-form';

const MobileNumberRegistration = () => {
    const navigate = useNavigate();
    const {isLoading,setIsLoading,sendOtp,setMobile} = useContext(UserContext);
    const {control,watch,handleSubmit} = useForm({mode:'onChange'});
    const handleMobileSubmit = async(data)=>{
        sendOtp(data.mobile_number);
        // sendOtp();
    }
  return (
    <>               
        <Paper
            sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                p:'3%',
                borderRadius:'.75rem'
            }}
        >
            <Box sx={{textAlign:'left',width:'85%'}}>
                <Typography component="h1" variant="h5">
                    Enter Mobile Number
                </Typography>
            </Box>
            <Box
                component="form"
                noValidate
                sx={{ mt: 1 ,width:'85%'}}
                textAlign={'center'}
                onSubmit={handleSubmit(handleMobileSubmit)}
            >
                <Controller
                    name='mobile_number'
                    control={control}
                    rules={{
                        required: 'Mobile number is required',
                        pattern: {
                            value: /^[1-9][0-9]{9}$/,
                            message: "Enter a valid 10-digit number not starting with 0"
                        },
                        validate: (value) => {
                        if (value && value.length !== 10) {
                            return "Mobile number must be exactly 10 digits";
                        }
                        return true;
                        }
                    }}
                    render={({field,fieldState:{error}})=>(
                        <TextField
                            {...field}
                            fullWidth
                            type='number'
                            size='small'
                            label="Mobile Number"
                            autoFocus
                            error={!!error}
                            helperText={error && error?.message}
                            inputProps={{
                                maxLength: 10,
                            }}
                            onChange={(e) => {
                            const value = e.target.value.replace(/[^0-9]/g, '').slice(0, 10);
                            field.onChange(value);
                            }}
                        />
                    )}
                />

                <Box sx={{textAlign:'center'}}>
                    <Button
                        type="submit"
                        // size='small'
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 ,width:'fit-content'}}
                        // onClick={sendOtp}
                    >
                        Get Otp
                    </Button>
                </Box>
            </Box>
        </Paper>
                    
        <ToastContainer />
        {isLoading && <Loading/>}
    </>
  );
}

export default MobileNumberRegistration;
