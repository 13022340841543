import React, { useState } from 'react';
import { 
  Box,
  Avatar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Card,
  CardHeader,
  CardContent
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { ExpandLess, MoreVert as MoreVertIcon } from '@mui/icons-material';

const UsersList = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading,setLoading] = useState(false)

  const handleMenuClick = (event, params) => {
    setAnchorEl(event.currentTarget);
    setSelectedUser(params.row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedUser(null);
  };

  const users = [
    {
        id:'1',
        name:'ram pratap singh',
        email:'ramprtapsingh@gmail.com',
        isAdmin:false,
        requestAccepted:false,
        role:'guest',
        status:false,
        image:'https://mui.com/static/images/avatar/1.jpg',
        joinedDate: '25 Jul 2024, 5:20 pm'
    },
    {
        id:'2',
        name:'ram pratap singh2',
        email:'ramprtapsingh2@gmail.com',
        isAdmin:true,
        requestAccepted:true,
        role:'manager',
        status:true,
        image:'https://mui.com/static/images/avatar/2.jpg',
        joinedDate: '25 Jul 2024, 5:20 pm'
    },
    {
        id:'3',
        name:'ram pratap singh3',
        email:'ramprtapsingh3@gmail.com',
        isAdmin:true,
        requestAccepted:false,
        role:'admin',
        status:false,
        image:'',
        joinedDate: '20 Jul 2023, 5:20 pm'
    },
    {
        id:'4',
        name:'ram pratap singh4',
        email:'ramprtapsingh4@gmail.com',
        isAdmin:true,
        requestAccepted:false,
        role:'guest',
        status:false,
        image:'https://mui.com/static/images/avatar/5.jpg',
        joinedDate: '25 Jul 2024, 5:20 pm'
    }
];

const columns = [
    { field: 'id', headerName: 'ID',headerAlign:'center', width: 130 },
    {
      field: 'member',
      headerName: 'Members',
      headerAlign:'center',
      flex: 2,
      filterable: false,
      renderCell: (params) => (
        <Box sx={{ p: 1,display:'flex',gap:'5%' }}>
            
            <Avatar alt={params.row.id+'_image'} src={params.row.image} />
          
          <Box>
            <Typography variant='body2' sx={{textTransform:'capitalize',fontWeight:'600'}}>{params.row.name}</Typography>     
            <Typography variant='body2'>{params.row.email}</Typography>
          </Box>
        </Box>
      ),
    },
    {
        field: 'joinedDate',
        headerName: 'JOINED DATE',
        // width: 200,
        headerAlign: 'center',
        flex: 1,
    },
    {
    field: 'actions',
    headerName: 'Actions',
    headerAlign: 'center',
    flex: 1,
    filterable: false,
    renderCell: (params) => (
        <Box sx={{ p: 1, display: 'flex', justifyContent: 'space-between' }}>
            <Button variant='outlined' size="small" onClick={(e) => handleMenuClick(e, params)} endIcon={<ExpandLess/>}>Action </Button>
        </Box>
    ),
    },
  ];

  return (
    <Card >
      <CardHeader 
        title="Users Assigned" 
        sx={{ 
          borderBottom: 1, 
          borderColor: 'divider',
          '& .MuiCardHeader-title': {
            fontSize: '1.25rem',
            fontWeight: 600
          }
        }}
      />
      <CardContent>
        <Box sx={{ overflow: "auto" }}>
            <Box sx={{ width: "100%", display: "table", tableLayout: "fixed", backgroundColor: "#fff", borderRadius: '.5rem' }}>
                <DataGrid
                rows={users}
                columns={columns}
                getRowId={(row) => row.id}
                //checkboxSelection={true}
                initialState={{
                    pagination: {
                    paginationModel: {
                        pageSize: 5,
                    },
                    },
                }}
                pageSizeOptions={[5, 10, 20, 50, 100]}
                loading={loading}
                disableColumnMenu
                disableRowSelectionOnClick
                slots={{
                    // toolbar: GridToolbar,
                }}
                autoHeight
                getRowHeight={() => 'auto'}
                sx={{
                    '& .MuiDataGrid-cell': {
                    border: 1,
                    borderRight: 0,
                    borderTop: 0,
                    borderColor: 'lightgrey',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    },
                    '& .MuiDataGrid-columnHeaders': {
                    borderBottom: '2px solid rgba(224, 224, 224, 1)',
                    borderTop: '2px solid rgba(224, 224, 224, 1)',
                    },
                    '& .MuiDataGrid-columnHeader': {
                    borderLeft: '1px solid rgba(224, 224, 224, 1)',
                    '&:last-child': {
                        borderRight: 'none',
                    },
                    },
                    '& .MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 'bold',
                    color: '#000000'
                    },
                    '& .MuiDataGrid-row': {
                    '&:last-child .MuiDataGrid-cell': {
                        borderBottom: '1px solid rgba(224, 224, 224, 1)',
                    },
                    '& .MuiDataGrid-cell:last-child': {
                        borderRight: 'none',
                    },
                    },
                    '& .MuiDataGrid-columnSeparator': {
                    display: 'none',
                    },
                }}
                />
            </Box>
        </Box>
      </CardContent>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleMenuClose}>View</MenuItem>
        <MenuItem onClick={handleMenuClose}>Delete</MenuItem>
      </Menu>
    </Card>
  );
};

export default UsersList;