import Layout from '../../components/Layout/Layout';
import { Box, Button,Checkbox,OutlinedInput,ListItemText,Chip, Avatar, Card, Grid,Popover,RadioGroup,FormControlLabel,Radio, IconButton, LinearProgress, Stack, Switch, Typography,Tooltip,MenuItem, TextField, FormControl, InputLabel, Select, Paper, InputAdornment } from '@mui/material'
import { DataGrid, getGridStringOperators, GridToolbar } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import axios from 'axios';
import TableLoadingSkeleton from '../../components/Layout/TableLoadingSkeleton';
import NoRowsLayout from '../../components/Layout/NoRowsLayout';
import { useForm, Controller } from 'react-hook-form';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SearchIcon from '@mui/icons-material/Search';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useNavigate } from 'react-router-dom';
import { BookingService } from './Service';
import Swal from 'sweetalert2';
import { Edit, KeyboardArrowDown, KeyboardArrowUp, Search } from '@mui/icons-material';
import { render } from '@testing-library/react';
import { formatDateTime } from '../../utils/dateUtils';
import Loading from '../../components/Loader/Loading';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
import Viewdetails from './Viewdetails';

dayjs.extend(customParseFormat);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const statusOptions = [
  'requested',
  'accepted',
  'rejected',
  'completed',
  'ongoing',
  'cancelled'
];
const BookingList = () => {
  const navigate = useNavigate();

  /* ....................booking table related..................... */
  const[isLoading,setIsLoading] = useState(true);
  const [selectedBookingDetails,setSelectedBookingDetails] = useState(null);

  // Get only the 'contains' operator and make it non-removable
  const containsOnlyOperator = getGridStringOperators()
    .filter(operator => operator.value === 'contains')
    .map(operator => ({
    ...operator,
    requiresOperator: false,
    InputComponent: operator.InputComponent,
    // Hide the operator component completely
    HeaderComponent: null,
    // Remove the operator label
    headerLabel: null,
    // Hide the operator selector
    showOperatorSelector: false
  }));
  const columns = [
    { 
      field: 'id', 
      headerName: '#S.No.', 
      // headerAlign:'center',
      flex: .75,
      filterable:false,
      renderCell: (params) => (
        <Typography variant="body2" sx={{fontSize:'.65rem'}} fontWeight="600" color={'#3e3e3e'}>
          {params.value}
        </Typography>
      ),
    },
    { 
      field: 'bookingNumber', 
      headerName: 'Booking ID', 
      // headerAlign:'center',
      flex: 1.5,
      filterOperators: containsOnlyOperator,
      renderCell: (params) => (
        <Typography variant="body2" color="primary" sx={{fontSize:'.65rem'}}>
          {params.value}
        </Typography>
      ),
    },
      {
        field: 'serviceDate',
        headerName: 'Service Date',
        // headerAlign:'center',
        flex: 1,
        filterOperators: containsOnlyOperator,
        valueGetter: (params) => {
          return generateSearchableDateFormats(params.row?.serviceDate);
        },
        renderCell: (params) => (
          <Typography variant="body2" sx={{fontSize:'.65rem'}} >
            {formatDateTime(params.row?.serviceDate, 'date')}
          </Typography>
        ),
      },
    {
      field: 'timeSlot',
      headerName: 'Time Slot',
      // headerAlign:'center',
      flex: 1.8,
      filterable:false,
      renderCell: (params) => (
        <Typography variant="body2" sx={{fontSize:'.65rem'}}>
          {`${dayjs(params.row.bookingStartTime, 'HH:mm:ss').format('h:mm A')} - ${dayjs(params.row.bookingEndTime, 'HH:mm:ss').format('h:mm A')}`}
        </Typography>
      ),
    },
    {
      field: 'providerName',
      headerName: 'Expert',
      // headerAlign:'center',
      width: 150,
      filterOperators: containsOnlyOperator,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', alignItems: 'center',justifyContent:'left' }}>
          <Typography variant="body2" sx={{textTransform:'capitalize',fontSize:'.75rem'}}>{params.value}</Typography>
        </Box>
      ),
    },
    {
      field: 'bookingItems',
      headerName: 'Requested Services',
      flex: 3,
      filterable: false,
      renderCell: (params) => {
        // Get all services from the first booking item
        // const services = params.value[0]?.serviceName.split('+').map(s => s.trim());
         const services = params.value;
        if (!services?.length) return null;
    
        const primaryService = services[0];
        const additionalServices = services.slice(1);
        
        return (
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 1,
          }}>
            <Typography sx={{fontSize:'.65rem'}}>
              {primaryService?.serviceName}
            </Typography>
            {services.length > 1 && (
              <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      padding: '0',
                      bgcolor: 'transparent'  // if you also want to remove background
                    }
                  }
                }}
                title={
                  <Paper sx={{ p: 1 }}>
                    {services.map((service, index) => (
                      index!=0 && <Box key={index}>
                      <Typography variant="caption" sx={{whiteSpace: 'wrap' }}>
                        {service?.serviceName}
                      </Typography>
                      <br/>
                      </Box>
                    ))}
                  </Paper>
                }
                placement="bottom-start"  // You can change this to any valid placement
                PopperProps={{
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -10], // Horizontal and vertical offset
                      },
                    },
                  ],
                }}
                enterDelay={200}
                leaveDelay={200}
              >
                <Chip 
                  label={`+${services.length - 1}`}
                  size="small"
                  sx={{
                    height: 20,
                    backgroundColor: 'primary.ight',
                    color: 'error',
                    cursor:'pointer',
                    fontSize: '0.65rem',
                    '&:hover': {
                      backgroundColor: 'primary.dark',
                      color:'#fff'
                    }
                  }}
                />
              </Tooltip>
            )}
          </Box>
        );
      }
    },
    {
      field: 'bookingStatus',
      headerName: 'Status',
      // headerAlign:'center',
      flex: 1.2,
      filterOperators: containsOnlyOperator,
      renderCell: (params) => (
        <Chip
          label={params.value}
          size="small"
          sx={{
            fontWeight:'500',
            fontSize:'x-small',
            color:params.value === 'requested' ? '#E8832A' : params.value === "rejected"?'#F80000':params.value === 'completed'?'#009EB4':params.value === 'accepted'?'#40B962': params.value === 'cancelled'?'#D81A23':params.value === 'ongoing'?'#1A44B8':'default',
            bgcolor:params.value === 'requested' ? '#FFEDD5' : params.value === "rejected"?'#f800001f':params.value === 'completed'?'#009eb41a':params.value === 'accepted'?'#40b96236':params.value === 'cancelled'?'#d81a231f':params.value === 'ongoing'?'#1a44b838': 'default'
          }}
        />
      ),
    },    
    {
      field: 'createdAt',headerName: 'Created At',headerAlign: 'center',flex:1,filterable:false,
      renderCell: (params) => (
        <Typography variant="body2" sx={{fontSize:'0.6rem',color:'#000000de'}}>{formatDateTime(params.row.createdAt)}</Typography>
      ),
    },
    {
      field: 'action', headerName: 'Details', flex:.75, sortable: false, 
      filterable:false,
      renderCell: (params) => {
        const currentRow = params.row;
          return (
            <Box className='flex items-center justify-center h-full'>
              <IconButton size='small' color='info' onClick={()=>setSelectedBookingDetails(currentRow)}><RemoveRedEyeIcon sx={{fontSize:'0.85rem'}} /></IconButton>
            </Box>
          );
      },
    },
  ];
  
   const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 50,
    filters: {
      searchKey: "",
      sortKey:'',
      sortDirection:'desc',//asc,desc
      status: statusOptions
    }
  });

  const handlePaginationModel = (newPagination) => {
    setPaginationModel({
      ...paginationModel,
      page: newPagination.page,
      pageSize: newPagination.pageSize
    });
  }

  // State to store the total row count from the server
  const [rowCount, setRowCount] = useState(5); 
    
  // State to store the row count used by the DataGrid
  const [rowCountState, setRowCountState] = useState(rowCount);
    
  // Update rowCountState whenever rowCount changes
  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      rowCount !== undefined ? rowCount : prevRowCountState,
    );
  }, [rowCount, setRowCountState]);


  // State to store the rows fetched from the server
  const [rowData, setRowData] = useState([]);
  // Effect to fetch row count and rows when paginationModel changes
  useEffect(()=>{
    const getData = async () => {
      try {
        
        setIsLoading(true)
        const urlParams = paginationModel.filters.status.map(status => `bookingStatus=${status}`).join('&');
        const listResponse = await BookingService.bookingList({
          page: paginationModel.page,
          limit: paginationModel.pageSize,
          filters: {
            searchKey: paginationModel.filters.searchKey,   // Use the title filter from the paginationModel
            status: paginationModel.filters.status.length === statusOptions.length ? 'all' :urlParams,
            sortKey:paginationModel.filters.sortKey,
            sortDirection:paginationModel.filters.sortDirection
          }
        });
        // console.log("booking list Response:",listResponse);
        setValue('status', paginationModel.filters.status)
        if (listResponse.success =="1") {
          setRowData(listResponse.data.list);
          setRowCount(listResponse.data.totalItems);
        } 
        // else {
        //   Swal.fire('Request failed','','error')
        // }
      } catch (error) {
        // console.log(error)
        Swal.fire(error.message,'','error')
      }
      setIsLoading(false)
    };
    getData();
  },[paginationModel])
//   }, [paginationModel, sortModel, filterModel]);

  /*............search realted.................... */

  const {control,handleSubmit,reset, setValue, getValues, formState: { errors },watch} = useForm();
  function onSubmit(data) {
    // setIsLoading(true);
    const filtersData = getValues();
    setPaginationModel({
      ...paginationModel,
      filters: {
        ...paginationModel.filters,
        searchKey: filtersData.searchKey,
        status: filtersData.status
      }
    });
  }
  const clearFilters = () => {
    reset();
    setPaginationModel({
      ...paginationModel,
      filters: {
        ...paginationModel.filters,
        searchKey: "",
        status: statusOptions,
        sortKey:'',
        sortDirection:'desc'
      }
    });
  }

  const [checkedAll, setCheckedAll] = useState(false);

  const handleChange = (event) => {
    const value = event.target.checked;
    if (value) {
      setValue('status',statusOptions);
    }else{
      setValue('status',['requested']);
    }
    setCheckedAll(event.target.checked);
    // console.log(event.target.checked)
  };

  // useEffect(()=>{
  //   if(checkedAll){
  //     if (watch('status').length < statusOptions.length) {
  //       setCheckedAll(false);
  //     }
  //   }
  //   if(watch('status').length == '0'){
  //     setValue('status',['requested'])
  //     // setCheckedAll(true);
  //   }
  //   if(watch('status').length == statusOptions.length){
  //     // setValue('status',['requested'])
  //     setCheckedAll(true);
  //   }
  // },[watch('status')])

  /**************** dynamic filter on search key  ****************/

  // useEffect(()=>{
  //   if(watch('searchKey').length>2){
  //     setPaginationModel({
  //       ...paginationModel,
  //       filters: {
  //         ...paginationModel.filters,
  //         searchKey:watch('searchKey'),
  //       }
  //     });
      
  //   }
  // },[watch('searchKey')])
  /************* Sorting related *************/
  const [anchorEl, setAnchorEl] = useState(null);
  const [sortKey, setSortkey] = useState('');
  const [sortDirection, setSortDirection] = useState('desc');

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReset = () => {
    clearFilters();
    setSortkey('');
    setSortDirection('desc');
    handleClose();
  };

  const handleApply = () => {
    setPaginationModel({
      ...paginationModel,
      filters: {
        ...paginationModel.filters,
        sortKey:sortKey,
        sortDirection:sortDirection
      }
    });
    handleClose();
  };

  const open = Boolean(anchorEl);
  return (
    <>
      <Card sx={{ minWidth: "100%", marginBottom: ".5%" }}>
        <Grid container>
          <Grid item xs={10}>
              <Box component={'form'} onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={1} sx={{ p: '.5%' }}>
                  {/* ========= serach box =========== */}
                  <Grid item xs={12} lg={4}>
                    <Controller
                      name="searchKey"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                      <TextField
                          {...field}
                          placeholder="Search by expert name & booking ID"
                          // label="Search"
                          size="small"
                          fullWidth
                          sx={{
                            '& .MuiOutlinedInput-root': {                              
                              paddingLeft: '0px',                              
                            }
                          }}
                          InputProps={{  // Changed from slotProps to InputProps
                            inputProps:{
                              style:{
                                  height:'0.6rem',
                                  textTransform:'uppercase',   
                                  fontSize:'x-small'                       
                              },
                            },
                            startAdornment: (
                                <InputAdornment position="start">
                                  <IconButton>
                                    <Search sx={{fontSize:'x-small'}}/>
                                  </IconButton>
                                </InputAdornment>
                            ),
                          }}
                      />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} lg={1.15}sx={{}}>
                    <Button
                      variant="outlined"
                      onClick={handleClick}
                      endIcon={open?<KeyboardArrowUp/> :<KeyboardArrowDown />}
                      sx={{
                      textTransform: 'none',
                      // color: 'inherit',
                      // borderColor: 'grey.300',
                      // '&:hover': {
                      //   borderColor: 'grey.400',
                      //   backgroundColor: 'grey.50'
                      // }
                        fontSize:'x-small',
                        p:'3% 10%'
                      }}
                  >
                      Sort By
                  </Button>
                  <Popover
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                      }}
                      transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                      }}
                      PaperProps={{
                      sx: { 
                          width: 320,
                          p: 3,
                          mt: 1
                      }
                      }}
                  >
                      {/* <Typography
                      variant="h6"
                      sx={{
                          fontSize: '1.25rem',
                          fontWeight: 500,
                          mb: 3
                      }}
                      >
                      Sorting Options
                      </Typography> */}

                      <Typography
                      sx={{
                          fontSize: '1rem',
                          fontWeight: 500,
                          mb: 1
                      }}
                      >
                      Sorting Options:
                      </Typography>
                      <FormControl fullWidth sx={{ mb: 3 }} size='small'>
                      <Select
                          value={sortKey}
                          onChange={(e) => setSortkey(e.target.value)}
                          displayEmpty
                          sx={{
                          bgcolor: 'grey.50',
                          '& .MuiSelect-select': {
                              color: !sortKey ? 'grey.500' : 'inherit',
                              padding: '0 8px', // Adjust horizontal padding as needed
                              display: 'flex',
                              alignItems: 'center',
                          },
                          minHeight: '0px',
                          height: '1.6rem',
                          fontSize: '0.75rem',
                          fontWeight: '600',
                        }}
                      >
                          <MenuItem value="" selected>
                          Default View
                          </MenuItem>
                          <MenuItem value="bookingNumber">Booking Number</MenuItem>
                          <MenuItem value="serviceDate">Service date</MenuItem>
                          {/* <MenuItem value="expert">Expert</MenuItem>
                          <MenuItem value="status">Status</MenuItem> */}
                      </Select>
                      </FormControl>

                      <Typography
                      sx={{
                          fontSize: '1rem',
                          fontWeight: 500,
                          mb: 1
                      }}
                      >
                      Sort Type:
                      </Typography>
                      <RadioGroup
                      value={sortDirection}
                      onChange={(e) => setSortDirection(e.target.value)}
                      sx={{ mb: 3 }}
                      >
                      <FormControlLabel value="asc" control={<Radio />} label="Ascending" />
                      <FormControlLabel value="desc" control={<Radio />} label="Descending" />
                      </RadioGroup>

                      <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
                      <Button
                          onClick={handleReset}
                          sx={{
                          color: 'grey.700',
                          '&:hover': {
                              bgcolor: 'grey.100'
                          }
                          }}
                      >
                          Reset
                      </Button>
                      <Button
                          variant="contained"
                          onClick={handleApply}
                          sx={{
                          bgcolor: 'primary.main',
                          color: 'white',
                          '&:hover': {
                              bgcolor: 'primary.dark'
                          }
                          }}
                      >
                          Apply
                      </Button>
                      </Box>
                  </Popover>
                  
                  </Grid>
                  <Grid item xs={12} lg={5}>
                    <FormControl sx={{ width: "70%" }} size="small">
                      <InputLabel id="status-select-label" sx={{fontSize:'.75em',lineHeight:'2em'}}>Select Status</InputLabel>
                      <Controller
                      name="status"
                      control={control}
                      defaultValue={statusOptions}
                      render={({ field }) => (
                          <Select
                            {...field}
                            multiple
                            sx={{
                              minHeight: '0px',
                              height: '1.6rem',
                              fontSize: '0.75rem',
                              // fontWeight: '600',
                              '& .MuiSelect-select': {
                                  padding: '0 8px', // Adjust horizontal padding as needed
                                  display: 'flex',
                                  alignItems: 'center',
                              },
                            }}
                            labelId="status-select-label"
                            label="Select Status"
                            displayEmpty
                            MenuProps={MenuProps}
                            renderValue={(selected) => watch('status')&&watch('status').length === statusOptions.length? 'All':selected.join(', ')}
                          >
                          <Box sx={{display:'flex',alignItems:'center',justifyContent:'center',p:'0% 8%'}}>
                          <Checkbox
                              checked={checkedAll}
                              onChange={handleChange}
                              inputProps={{ 'aria-label': 'controlled' }}
                          />
                              <ListItemText primary={'All'} />
                          </Box>
                          {statusOptions.map((name) => (
                              <MenuItem key={name} value={name}>
                              <Checkbox checked={field.value.includes(name)} />
                              <ListItemText primary={name} />
                              </MenuItem>
                          ))}
                          </Select>
                      )}
                      />
                  </FormControl>
                  <Tooltip title="Search" sx={{ml:1,p:0}}>
                      <IconButton
                        size='small'
                        color="primary"
                        type="submit"
                        sx={{ border: "1px solid", borderRadius: "7px", padding: "1%" ,ml:1}}
                        >
                        <SearchIcon sx={{fontSize:'.85rem'}} />
                      </IconButton>
                  </Tooltip>
                  <Tooltip title="Clear All Filters">
                      <IconButton
                        size='small'
                        color="primary"
                        sx={{ marginLeft: "10px", border: "1px solid", color: "#f00", borderRadius: "7px", padding: "1%" }}
                        onClick={clearFilters}
                      >
                      <RestartAltIcon sx={{fontSize:'.85rem'}}/>
                      </IconButton>
                  </Tooltip>
                  </Grid>
              </Grid>
              </Box>
          </Grid>
          <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingRight: '1rem' }}>
            <Button 
              variant='contained' 
              size='small'
              onClick={() => navigate(`/admin/booking/0`)} 
              sx={{ height:'fit-content',fontSize:'x-small',p:'2% 1.25%'}} 
              endIcon={<AddCircleIcon />}
              >
              Add
            </Button>
          </Grid>
        </Grid>
      </Card>
      <Card>
        <Box sx={{ overflow: "auto" }}>
          <Box 
            sx={{ 
              width: "100%", display: "table",  tableLayout: "fixed", 
              backgroundColor: "#fff", borderRadius: '.5rem',
              '& .odd': {
                bgcolor: 'white',
              },
              '& .even': {
                bgcolor: '#ECECEC',
              },
            }}>
          {isLoading && <Loading/>}
            <DataGrid
              rows={rowData}
              columns={columns}
              getRowId={(row) => row.id}
              rowCount={rowCount}
              pageSizeOptions={[5, 10, 20, 50, 100]}
              paginationModel={paginationModel}
              paginationMode="server"
              onPaginationModelChange={handlePaginationModel}
              loading={isLoading}
              getRowClassName={(params) => 
                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
              }
              
              disableColumnMenu
              disableRowSelectionOnClick
              // disableColumnFilter
              // disableColumnSelector
              disableDensitySelector
              slots={{
                toolbar: GridToolbar,
                loadingOverlay:Loading
              }}
              autoHeight
              getRowHeight={() => 'auto'}
              sx={{   
                '& .MuiDataGrid-row': {
                  '& .MuiDataGrid-cell': {
                    py:1
                  }
                },           
                '& .MuiDataGrid-columnHeaders': {
                  borderBottom: '2px solid rgba(224, 224, 224, 1)',
                  borderTop: '2px solid rgba(224, 224, 224, 1)',
                  fontWeight: 600,
                  fontSize: '0.75rem',
                  p:0,
                  height: 'fit-content',
                  minHeight:'0 !important',
                  height:'2rem'
                },
                // '& .MuiDataGrid-columnHeader': {
                //   borderLeft: '1px solid rgba(224, 224, 224, 1)',
                //   '&:last-child': {
                //     borderRight: 'none',
                //   },
                // },
                '& .MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 600,
                  color: '#3e3e3e'
                },
                
              }}
            />
          </Box>
        </Box>
      </Card>
      {selectedBookingDetails && <Viewdetails selectedBookingDetails={selectedBookingDetails} setSelectedBookingDetails={setSelectedBookingDetails}/>}
    </>
  );
}

export default BookingList;


const generateSearchableDateFormats = (date) => {
  if (!date) return '';
  
  const dateObj = new Date(date);
  if (isNaN(dateObj.getTime())) return '';

  const monthNames = [
    'january', 'february', 'march', 'april', 'may', 'june',
    'july', 'august', 'september', 'october', 'november', 'december'
  ];
  const monthAbbrev = monthNames.map(m => m.substring(0, 3));
  
  const day = dateObj.getDate();
  const month = dateObj.getMonth();
  const year = dateObj.getFullYear();
  
  // Generate all possible date format combinations
  const formats = [
    // Original date string
    date,
    // ISO format
    dateObj.toISOString(),
    // DD-MM-YYYY formats
    `${day}-${month + 1}-${year}`,
    `${day.toString().padStart(2, '0')}-${(month + 1).toString().padStart(2, '0')}-${year}`,
    // DD/MM/YYYY formats
    `${day}/${month + 1}/${year}`,
    `${day.toString().padStart(2, '0')}/${(month + 1).toString().padStart(2, '0')}/${year}`,
    // Month name formats
    `${day} ${monthNames[month]}`,
    `${day} ${monthAbbrev[month]}`,
    `${monthNames[month]} ${day}`,
    `${monthAbbrev[month]} ${day}`,
    `${day} ${monthNames[month]} ${year}`,
    `${day} ${monthAbbrev[month]} ${year}`,
    // Year variations
    `${day}-${month + 1}-${year.toString().slice(-2)}`,
    `${day}/${month + 1}/${year.toString().slice(-2)}`,
  ].join(' ').toLowerCase();

  return formats;
};