import { commonRequest } from "../../services/ApiCall";
import { Helpers } from "../../services/Helpers";

export const CrudServices = {
    categoryList : async (data)=>{
        return await commonRequest("get",  `${Helpers.URL()}/api/admin/service-category/getCategories`,{}, Helpers.token());
    },

    subCategoryList : async (data)=>{
        return await commonRequest("get",  `${Helpers.URL()}/api/admin/service-category/getSubCategories/${data.categoryId}`,{}, Helpers.token());
    },

    allServices : async (data) =>{
        return await commonRequest("get",  `${Helpers.URL()}/api/admin/service-master/list?page=${data.page}&limit=${data.limit}&searchKey=${data.filters.searchKey}&sortBy=createdAt&sortType=DESC`,{}, Helpers.token());
    },

    addService : async (data) =>{
        return await commonRequest("POST", `${Helpers.URL()}/api/admin/service-master`,data, Helpers.token());
    },

    updateService : async (id, data) =>{
        return await commonRequest("PUT",  `${Helpers.URL()}/api/admin/service-master/${id}`, data, Helpers.token());
    },

    serviceListBySubCategory : async (subCategoryId)=>{
        return await commonRequest("get",  `${Helpers.URL()}/api/admin/service-master/getServicesBySubcategory/${subCategoryId}`,{}, Helpers.token());
    },
    // uploadImage : async (data) =>{
    //     return await commonRequest("POST",  `${Helpers.URL()}/api/upload/uploadSingleImage`, data, Helpers.token());
    // },
    // updateServiceImage : async (id,data) =>{
    //     return await commonRequest("PUT",  `${Helpers.URL()}/api/service-category/${id}`, data, Helpers.token());
    // },
    // deleteService : async (id) =>{
    //     return await commonRequest("DELETE",  `${Helpers.URL()}/category/${id}`, {}, Helpers.token());
    // },

   
}
