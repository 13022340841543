import { useState, useEffect, useCallback } from 'react';
import { Card,Chip, Typography, Divider, Stack, TextField, Grid, Button, Box,Radio,RadioGroup,FormControlLabel, Toolbar, FormControl, InputLabel, Select, Tooltip, IconButton, Badge, Avatar, Popover } from '@mui/material';
import Swal from 'sweetalert2';
import Modal from '@mui/material/Modal';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { isMobile } from 'react-device-detect';
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarFilterButton, useGridApiRef } from '@mui/x-data-grid';
import { User } from './UserService';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useLocation, useNavigate } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import { formatDateTime } from '../../utils/dateUtils';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SearchIcon from '@mui/icons-material/Search';
import { ProviderServices } from './CrudServices';
import NoRowsLayout from '../../components/Layout/NoRowsLayout';
import { CustomerService } from '../Customer/Service';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import calculateAge from '../../utils/ageCalculation';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { PermissionGuard } from '../../components/PermissionGuard';
import { Helpers } from '../../services/Helpers';
import { CheckCircle, Warning, Block, Pending, KeyboardArrowUp, KeyboardArrowDown, FilterList } from '@mui/icons-material';
import Loading from '../../components/Loader/Loading';
import PageHeaderLayout from '../../components/Layout/PageHeaderLayout';
import AdvancedFilter from './AdvancedFilter';

const style = {
    position: 'absolute',
    top: (isMobile) ? '80%' : '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: (isMobile) ? "95%" : "25%",
    bgcolor: 'background.paper',
    border: '2px solid #ddd',
    boxShadow: 24,
    p: 2,
};

const statusConfigs = [
  // active: {
  //   label: 'Active',
  //   color: 'success',
  //   icon: <CheckCircle sx={{ fontSize: '0.25rem' }} />,
  //   tooltip: 'Account is active and verified'
  // },
  {
    label: 'Review Pending',
    value: '0',
    color: 'warning',
    colorCode:'#ed6c02',
    icon: <Pending sx={{ fontSize: '0.25rem' }} />,
    tooltip: 'Account is under review'
  },
  {
      label: 'Approved',
      value: '1',
      color: 'success',
      colorCode:'#2e7d32',
      icon: <CheckCircle sx={{ fontSize: '0.25rem' }} />,
      tooltip: 'Account has been Approved Already'
    },
  {
    label: 'Rejected',
    value: '2',
    color: 'error',
    colorCode:'#d32f2f',
    icon: <Warning sx={{ fontSize: '0.25rem' }} />,
    tooltip: 'Account has been rejected'
  },
  // blocked: {
  //   label: 'Blocked',
  //   color: 'error',
  //   icon: <Block sx={{ fontSize: '0.25rem' }} />,
  //   tooltip: 'Account has been blocked'
  // }
];

export default function UserList() {

  // Grid API Reference
  const apiRef = useGridApiRef();
  const navigate = useNavigate();

  // Form handling for filters
  const filterFormMethod = useForm({
    mode: "onChange",
    defaultValues: {
      filterOption: [{ fieldName: '', operator: 'contains', value: '' }]
    }
  });
  const { control, handleSubmit, reset, setValue, getValues, formState: { errors },watch }= filterFormMethod;

  /************************  Table Data State Manage **************************** */

  // Initialize controller state with potential localStorage backup
  const [controller, setController] = useState(() => {
    const savedState = localStorage.getItem('userListGridState');
    return savedState 
      ? JSON.parse(savedState) 
      : {
          page: 0,
          pageSize: 50,
          filters: {
            searchKey: "",
            status: 1,
          }
        };
  });

   // State management
   const [allRowCount, setAllRowCount] = useState(0);
   const [list, setList] = useState([]);
   const [loading, setLoading] = useState(false);

  // Effect to save grid state when controller changes
  useEffect(() => {
    if (apiRef.current) {
      const currentState = apiRef.current.exportState();
      localStorage.setItem('userListGridState', JSON.stringify({
        ...controller,
        gridState: currentState
      }));
    }
  }, [controller, apiRef]);

  const [isInitialLoad, setIsInitialLoad] = useState(true);

  // Effect to restore state when component mounts
  useEffect(() => {
    const savedState = localStorage.getItem('userListGridState');
    if (savedState && apiRef.current) {
      const parsedState = JSON.parse(savedState);
      
      // Safely restore state with error handling
      try {
        if (parsedState.gridState) {
          // Validate the grid state before restoring
          const currentColumns = apiRef.current.getAllColumns();
          const savedColumns = parsedState.gridState.columns;
          
          // Check if saved columns match current columns
          const columnsMatch = currentColumns.length === savedColumns.length && 
            currentColumns.every((col, index) => col.field === savedColumns[index].field);
          
          if (columnsMatch) {
            apiRef.current.restoreState(parsedState.gridState);
          } else {
            console.warn('Saved grid state does not match current column configuration');
          }
        }
      } catch (error) {
        console.error('Error restoring grid state:', error);
        // Optionally clear the saved state if it's corrupted
        localStorage.removeItem('userListGridState');
      }
      
      // Restore controller state
      setController({
        page: parsedState.page,
        pageSize: parsedState.pageSize,
        filters: parsedState.filters
      });

      //set searchfield value accordingly
      setValue('searchKey', parsedState.filters.searchKey);
    }
  }, [apiRef]);

  // Data fetching effect
  useEffect(() => {
    const getData = async () => {
      // Skip initial unnecessary fetch
      if (isInitialLoad) {
        setIsInitialLoad(false);
        return;
      }
  
      try {
        setLoading(true);
        const listResponse = await ProviderServices.fetchProviderList({
          page: controller.page + 1,
          limit: controller.pageSize,
          filters: {
            searchKey: controller.filters.searchKey,
            status: controller.filters.status,
          }
        });
  
        setValue('status', controller.filters.status);
        
        if (listResponse.success == '1') {
          setList(listResponse.data.list);
          setAllRowCount(listResponse.data.totalItems);
        } else {
          Swal.fire(listResponse.message, '', 'error');
        }
      } catch (error) {
        Swal.fire(error.message, '', 'error');
      }
      setLoading(false);
    };
    getData();
  }, [controller, isInitialLoad]);

  // Pagination change handler
  const handlePaginationChange = (newPagination) => {
    setController(prev => ({
      ...prev,
      page: newPagination.page,
      pageSize: newPagination.pageSize
    }));
  };

  // Filter submission handler
  function onSubmit(data) {
    const filtersData = getValues();
    setController(prev => ({
      ...prev,
      filters: {
        ...prev.filters,
        searchKey: filtersData.searchKey,
        status: filtersData.status
      }
    }));
  }

  // Clear filters handler
  const clearFilters = () => {
    reset();
    setController(prev => ({
      ...prev,
      filters: {
        searchKey: "",
        status: 1
      }
    }));
  };

    /************************  Table Column Manage **************************** */
   
    // State to track expanded rows
    const [expandedRowIds, setExpandedRowIds] = useState({});
            
    // Toggle expansion for expert categories of a specific row
    const toggleRowExpansion = (rowId) => {
      setExpandedRowIds(prev => ({
        ...prev,
        [rowId]: !prev[rowId]
      }));
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 ,headerAlign: 'center',},
        { 
          field: 'name', 
          headerName: 'Profile', 
          flex: 1.75,
          headerAlign: 'center',
          valueGetter: (params) => {
            const firstName = params.row.UserProfile?.firstName || '';
            const lastName = params.row.UserProfile?.lastName || '';
            const email = params.row.UserProfile?.email || '';
            const age = calculateAge(params.row.UserProfile?.dob) || '';
            const mobile = params.row.mobileNumber || '';
            
            // Combine all profile information into a single searchable string
            return `${firstName} ${lastName} ${email} ${age} ${mobile}`.trim();
          },
          renderCell: (params) => (
            
              
              <Box sx={{p: 1,display:'flex',justifyContent:'flex-start',width:'100%'}}>
                <Stack
                  direction="column" 
                  spacing={1} 
                  // alignItems="center" 
                  justifyContent="flex-start"
                  sx={{ mt:1,width:'100%'}}
                >
                  <Box sx={{display:'flex',gap:'5%' ,justifyContent:'flex-start',width:'100%'}}>
                    <Avatar src={`${Helpers.ImagePathUrl()}/profile/sm/${params.row.UserProfile?.profileImage}`} />
                    <Box>
                      <Typography variant="body2" sx={{fontSize:'.65rem',fontWeight:500 }} color={!params.row.UserProfile?.firstName && '#d32f2f'}>
                        {`${params.row.UserProfile?.firstName || ''} ${params.row.UserProfile?.middleName || ''} ${params.row.UserProfile?.lastName || ''}`}
                        {!params.row.UserProfile?.firstName && 'Incomplete Profile'}
                      </Typography>
                      {/* <Chip
                        icon={<CheckCircle sx={{fontSize:'.75rem !important'}}/>}
                        label={'Reviewed'}
                        color={'success'}
                        size="small"
                        variant="outlined"
                        sx={{fontSize:'xx-small',height:'fit-content'}}
                      /> */}
                      <Chip
                        icon={statusConfigs[params.row.reviewStatus]?.icon}
                        label={statusConfigs[params.row.reviewStatus]?.label}
                        color={statusConfigs[params.row.reviewStatus]?.color}
                        size="small"
                        variant="outlined"
                        sx={{fontSize:'0.5rem',height:'fit-content','& .MuiChip-icon':{fontSize:'0.65rem'}}}
                      />
                      <Box>
                        <Typography variant="caption" sx={{fontSize:'0.5rem'}}>{params.row.UserProfile?.email || 'N/A'}</Typography>
                        <Typography sx={{fontSize:'0.5rem'}}> <Box component={'span'} sx={{fontWeight:600}}>Mobile:</Box>{` ${params.row.mobileNumber}`}</Typography>
                        <Typography sx={{fontSize:'0.5rem'}}><Box component={'span'} sx={{fontWeight:600}}>Age: </Box>{`${calculateAge(params.row.UserProfile?.dob) || 'N/A'}`}</Typography>              
                      </Box>
                    </Box>
                    
                  </Box>
                </Stack> 
              </Box>
          ),
        },
        { 
          field: 'status', 
          headerName: 'Status', 
          headerAlign: 'center',
          filterable: false,
          flex:.6,
          renderCell: (params) => (
            <Box sx={{width:'max-content',m:'auto'}}>
              <Chip 
                label={params.row.isActive ? 'Active' : 'Inactive'} 
                color={params.row.isActive ? 'success' : 'error'} 
                size="small" 
                sx={{fontSize:'0.5rem',height:'fit-content',py:'5%'}}
            />
            </Box>
          ),
        },
        { 
          field: 'verification', 
          headerName: 'Verification', 
          headerAlign: 'center',
          filterable: false,
          flex:.75,
          renderCell: (params) => (
            <Box sx={{width:'60%',m:'auto'}}>
                {/*  */}
                {(params.row.UserBusinessDetail && params.row.UserBusinessDetail?.panNumber)&& 
                  <Box sx={{display:'flex',gap:1,my:'5%',alignItems:'center'}}>
                      <IconButton size='small' sx={{p:0}}><CheckCircleIcon color='success' sx={{fontSize:'0.65rem'}}/></IconButton>
                      <Typography variant="body2" sx={{fontSize:'0.5rem',width:'75%',fontWeight:'600',color:'#3e3e3e'}}>PAN</Typography>
                      {/* <Typography variant='body2' sx={{flex:1}}>:</Typography> */}
                      
                  </Box>
                }
                {(params.row.UserBusinessDetail && params.row.UserBusinessDetail?.aadharNumber) && <Box sx={{display:'flex',gap:1,my:'5%',alignItems:'center'}}>
                    <IconButton size='small' sx={{p:0}}><CheckCircleIcon color='success' sx={{fontSize:'0.65rem'}}/></IconButton>
                    <Typography variant="body2" sx={{fontSize:'0.5rem',width:'75%',fontWeight:'600',color:'#3e3e3e'}}>Aadhaar</Typography>
                    {/* <Typography variant='body2' sx={{flex:1}}>:</Typography> */}
                </Box>}
                {(!params.row.UserBusinessDetail || 
                      (params.row.UserBusinessDetail && 
                      (!params.row.UserBusinessDetail.panNumber || params.row.UserBusinessDetail.panNumber.trim() === '') && 
                      (!params.row.UserBusinessDetail.aadharNumber || params.row.UserBusinessDetail.aadharNumber.trim() === ''))
                    ) && 
                  <Box sx={{display:'flex',gap:1,my:'5%',alignItems:'center'}}>
                    <IconButton size='small' sx={{p:0}}><CancelIcon color='error' sx={{fontSize:'0.65rem'}}/></IconButton>
                    <Typography variant="body2" sx={{fontSize:'0.5rem',width:'75%',fontWeight:'600',color:'#3e3e3e'}}>KYC</Typography>
                    {/* <Typography variant='body2' sx={{flex:1}}>:</Typography> */}
                  </Box>
                
                }
            </Box>
          ),
        },
        { 
          field: 'categories', 
          headerName: 'Categories', 
          headerAlign: 'center',
          filterable: false,
          flex:1.25,
          renderCell: (params) => {
            
        
            // Check if this specific row is expanded
            const isRowExpanded = expandedRowIds[params.row.id];
        
            return (
              <Box sx={{width:'100%',display:'flex',alignItems:'center',flexWrap:'wrap',gap:'1%'}}>
                {params.row.providerCat.length > 0 && params.row.providerCat.map((cat, index) => (
                  <Box key={index} >
                    {/* Always show first 3 categories */}
                    {index < 3 && (
                      <>
                        <Chip label={cat.proServCat.title} size="small" sx={{my:'1%',fontSize:'0.5rem',height:'fit-content',py:'3%'}}/>
                        {/* <br/> */}
                      </>
                    )}
                    
                    {/* Show additional categories when row is expanded */}
                    {isRowExpanded && index >= 3 && (
                      <>
                        <Chip label={cat.proServCat.title} size="small" sx={{my:'1%',fontSize:'0.5rem',height:'fit-content',py:'3%'}}/>
                        {/* <br/> */}
                      </>
                    )}
                  </Box>
                ))}
                
                {/* Show more/less button if more than 4 categories */}
                {params.row.providerCat.length > 4 && (
                  <Box sx={{width:'100%',textAlign:'end'}}>
                    <Button 
                      size='small' 
                      sx={{fontSize:'.45rem'}} 
                      onClick={() => toggleRowExpansion(params.row.id)}
                    >
                      {isRowExpanded ? 'view less' : `view ${params.row.providerCat.length-3} more`}
                    </Button>
                  </Box>
                )}
                
                {/* Show N/A if no categories */}
                <Typography sx={{
                  fontSize:'0.5rem',
                  textAlign:'center',
                  width:'100%'
                }}>
                  {params.row.providerCat.length === 0 && 'N/A'}
                </Typography>
              </Box>
            );
          },
        },
        { 
          field: 'businessDetail', 
          headerName: 'Business Details',headerAlign: 'center',flex:1,filterable:false,
          renderCell: (params) => (
            <Box sx={{width:'95%',m:'auto'}}>
            {/* {params.row.UserBusinessDetail ? (
              
              // <Box sx={{textAlign:'center'}}>
                <Typography variant="body2" sx={{textAlign:'center',fontWeight:600}}>{params.row.UserBusinessDetail.businessName}</Typography>
                <Typography variant="caption" sx={{textAlign:'center'}}>{params.row.UserBusinessDetail.businessEmail}</Typography>*/}
                {/* <Typography variant="caption" sx={{textAlign:'center'}}>{params.row.Storefront? ''}</Typography> */}
              {/* </Box>
            // ) :  */}
            <Box sx={{display:'flex',gap:1,my:'5%',justifyContent:'flex-start'}}>
              <IconButton size='small' sx={{p:0}}>{(params.row.UserBusinessDetail?.panNumber || params.row.UserBusinessDetail?.aadharNumber)  ? <CheckCircleIcon color='success' sx={{fontSize:'0.65rem'}}/>:<CancelIcon color='error' sx={{fontSize:'0.65rem'}}/>}</IconButton>
              <Typography variant="body2" sx={{fontWeight:600,color:'#3e3e3e',fontSize:'0.5rem'}}>Business Profile</Typography>
              {/* <Typography variant='body2' sx={{flex:1}}>:</Typography> */}
              
            </Box>
            {/* // } */}
             <Box sx={{display:'flex',gap:1,my:'5%',alignItems:'center',}}>
              <IconButton size='small' sx={{p:0}}>{params.row.ProviderServices.length>0?<CheckCircleIcon color='success' sx={{fontSize:'0.65rem'}}/>:<CancelIcon color='error' sx={{fontSize:'0.65rem'}}/>}</IconButton>
              <Typography variant="body2" sx={{fontWeight:600,color:'#3e3e3e',fontSize:'0.5rem'}}>Services</Typography>
              {/* <Typography variant='body2' sx={{}}>:</Typography> */}
             </Box>
             <Box sx={{display:'flex',gap:1,my:'5%',alignItems:'center',}}>
              <IconButton size='small' sx={{p:0}}>{params.row.providerSlots.length>0 ?<CheckCircleIcon color='success' sx={{fontSize:'0.65rem'}}/>:<CancelIcon color='error' sx={{fontSize:'0.65rem'}}/>}</IconButton>
              <Typography variant="body2" sx={{fontWeight:600,color:'#3e3e3e',fontSize:'0.5rem'}}>Availability</Typography>
              {/* <Typography variant='body2' sx={{}}>:</Typography> */}
             </Box>
            <Box sx={{display:'flex',gap:1,my:'5%',alignItems:'center',}}>
              <IconButton size='small' sx={{p:0}}>{params.row.Storefront?<CheckCircleIcon color='success' sx={{fontSize:'0.65rem'}}/>:<CancelIcon color='error' sx={{fontSize:'0.65rem'}}/>}</IconButton>
              <Typography variant="body2" sx={{fontWeight:600,color:'#3e3e3e',fontSize:'0.5rem'}}>Storefront</Typography>
              {/* <Typography variant='body2' sx={{}}>:</Typography> */}
             </Box>
          </Box>
          ),
        },
        {
          field: 'createdAt',headerName: 'Created At',headerAlign: 'center',flex:1,filterable:false,
          renderCell: (params) => (
            <Typography variant="body2" sx={{fontSize:'0.5rem',fontWeight:600}}>{formatDateTime(params.row.createdAt)}</Typography>
          ),
        },
        {
            field: "actions", headerName: "Action",filterable: false,  flex:1, renderCell: (params) => {

                return (
                    <Stack spacing={2} direction="row" justifyContent={'center'} width={'100%'}>
                        <RemoveRedEyeIcon
                            style={{
                                fontSize: "20px",
                                color: "blue",
                                cursor: "pointer",
                            }}
                            className="cursor-pointer"
                            // onClick={(e) => navigate(`/admin/expert-detail/${params.row.id}`)}
                            onClick={(e) => window.open(`/admin/expert-detail/${params.row.id}`, '_blank')}
                        />
                        <PermissionGuard feature='service-expert' permission='canEdit'>
                          <BorderColorOutlinedIcon
                              style={{
                                  fontSize: "20px",
                                  color: "#ff3700",
                                  cursor: "pointer",
                              }}
                              className="cursor-pointer"
                              // onClick={(e) => navigate(`/admin/userProfile/${params.row.id}`)}
                              onClick={(e) => window.open(`/admin/expert-detail/${params.row.id}`, '_blank')}
                          />
                        </PermissionGuard>
                        {/* <DeleteIcon
                            style={{
                                fontSize: "20px",
                                color: "red",
                                cursor: "pointer",
                            }}
                            className="cursor-pointer"
                            onClick={(e) => deleteRow(params.row.id)}
                        /> */}
                    </Stack>
                );
            },
            headerAlign: 'center',
        },
    ];


    /************************  Advance Filter **************************** */
    const [anchorEl, setAnchorEl] = useState(null);
    const filterableColumns = [
      'reviewStatus', 
      'isActive' ,
      'name', 
      'email', 
      'isKycVerified', 
      'isPanVerified', 
      'isAadhharVerified', 
      'isVerified',
    ];

    const [sortKey, setSortkey] = useState('');
    const [sortDirection, setSortDirection] = useState('desc');

    const filterOption = watch('filterOption');

    // Calculate badge content - count of filters with both fieldName and value
    const activeBadgeContent = filterOption.filter(
      row => row.fieldName && row.value
    ).length;

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const openAdvancedFilter = Boolean(anchorEl);

  return (
      <>
        {loading && <Loading/>}
        <PageHeaderLayout headerTitle={'Manage Service Experts'}>
          <PermissionGuard feature='service-expert' permission='canCreate'>
            <Button variant='contained' onClick={() => navigate(`/admin/userProfile/0`)} sx={{ height: "30px", marginTop: "10px" }} endIcon={<AddCircleIcon />}>
                Add
            </Button>
          </PermissionGuard>
        </PageHeaderLayout>
          <Card sx={{ minWidth: "100%", marginBottom: ".5%" }}>
            <FormProvider {...filterFormMethod}>
              <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid container spacing={1} sx={{ p: '.5%' }}>
                      <Grid item xs={12} lg={2}>
                        <Controller
                          name="searchKey"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                          <TextField
                              {...field}
                              placeholder="Search by name,mobile,email "
                              // label="Search"
                              size="small"
                              fullWidth
                              sx={{
                                '& .MuiOutlinedInput-root': {                              
                                  paddingLeft: '0px',                              
                                }
                              }}
                              InputProps={{  // Changed from slotProps to InputProps
                                inputProps:{
                                  style:{
                                      height:'0.6rem',
                                      textTransform:'uppercase',   
                                      fontSize:'x-small'                       
                                  },
                                },
                                // startAdornment: (
                                //     // <InputAdornment position="start">
                                //     //   <IconButton>
                                //     //     <Search sx={{fontSize:'x-small'}}/>
                                //     //   </IconButton>
                                //     // </InputAdornment>
                                // ),
                              }}
                          />
                          )}
                        />
                      </Grid>

                      {/* ************ advance filter  *********** */}
                      
                      {/* <Grid item xs={12} lg={1.15}sx={{}}>
                        <Button
                          // variant="outlined"
                          onClick={handleClick}
                          endIcon={openAdvancedFilter?<KeyboardArrowUp/> :<KeyboardArrowDown />}
                          sx={{
                          textTransform: 'none',
                          // color: 'inherit',
                          // borderColor: 'grey.300',
                          // '&:hover': {
                          //   borderColor: 'grey.400',
                          //   backgroundColor: 'grey.50'
                          // }
                            fontSize:'x-small',
                            p:'3% 10%'
                          }}
                          startIcon={ 
                            <Badge 
                              sx={{
                                '& .MuiBadge-badge': {
                                  // Customize badge content
                                  fontSize: '0.5rem',  // Smaller font size
                                  fontWeight: '600',  // Bold font
                                  minWidth: '0',    // Minimum width
                                  height: 'auto',      // Fixed height
                                  padding: '10% 17%',    // Padding
                                  borderRadius: '50%'  // Circular shape
                                },
                                width: 'fit-content'
                              }}  
                              badgeContent={activeBadgeContent} 
                              color="primary"
                              // Only show badge if there are active filters
                              invisible={activeBadgeContent === 0}
                            >
                              <FilterList />
                            </Badge>
                          }
                        >
                          Filters
                        </Button>
                        <Popover
                          open={openAdvancedFilter}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                          PaperProps={{
                            sx: { 
                                width: '50%',
                                p: 3,
                                mt: 1
                            }
                          }}
                        >
                          <Typography
                            sx={{
                                fontSize: '1rem',
                                fontWeight: 500,
                                mb: 1
                            }}
                          >
                            Filter Options:
                          </Typography>

                          <AdvancedFilter onClose={handleClose} filterableColumns={filterableColumns} />
                        </Popover>
                      
                      </Grid> */}

                      {/* <Grid item xs={12} lg={2}>
                        <FormControl sx={{ minWidth: "100%" }} size="small">
                          <InputLabel id="demo-simple-select-autowidth-label">Select Status</InputLabel>
                          <Controller
                            name="status"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <Select
                                {...field}
                                label="Select Status"
                              >
                                <MenuItem value="">All</MenuItem>
                                <MenuItem value="0">Under Review</MenuItem>
                                <MenuItem value="1">Reviewed</MenuItem>
                                <MenuItem value="2">Rejected</MenuItem>
                              </Select>
                            )}
                          />
                        </FormControl>
                      </Grid> */}
                      
                      <Grid item xs={12} lg={2}>
                        <Tooltip title="Search" sx={{ml:1,p:0}}>
                            <IconButton
                              size='small'
                              color="primary"
                              type="submit"
                              sx={{ border: "1px solid", borderRadius: "7px", padding: "1%" ,ml:1}}
                              >
                              <SearchIcon sx={{fontSize:'.85rem'}} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Clear All Filters">
                            <IconButton
                              size='small'
                              color="primary"
                              sx={{ marginLeft: "10px", border: "1px solid", color: "#f00", borderRadius: "7px", padding: "1%" }}
                              onClick={clearFilters}
                            >
                            <RestartAltIcon sx={{fontSize:'.85rem'}}/>
                            </IconButton>
                        </Tooltip>
                      </Grid>
                  </Grid>
              </form>
            </FormProvider> 
          </Card>

        <Box sx={{ overflow: "auto" }}>
          <Box sx={{ width: "100%", display: "table", tableLayout: "fixed", backgroundColor: "#fff", borderRadius: '.5rem' }}>
            <DataGrid
              apiRef={apiRef}
              rows={list}
              columns={columns}
              rowCount={allRowCount}
              pageSizeOptions={[5, 10, 20, 50, 100]}
              paginationModel={controller}
              paginationMode="server"
              onPaginationModelChange={handlePaginationChange}
              loading={loading}
              disableColumnMenu
              disableRowSelectionOnClick
              slots={{
                toolbar: GridToolbar,   
                loadingOverlay: Loading,
                noRowsOverlay: NoRowsLayout,
              }}
              autoHeight
              getRowHeight={() => 'auto'}
              sx={{
                lineHeight:1,
                '& .MuiDataGrid-cell': {
                  border: 1,
                  borderRight: 0,
                  borderTop: 0,
                  borderColor: 'lightgrey',
                  display: 'flex',
                  alignItems: 'center',
                  // justifyContent: 'center',
                },
                '& .MuiDataGrid-columnHeaders': {
                  borderBottom: '2px solid rgba(224, 224, 224, 1)',
                  borderTop: '2px solid rgba(224, 224, 224, 1)',
                  fontWeight: 600,
                  fontSize: '0.75rem',
                  p:0,
                  height: 'fit-content',
                  minHeight:'0 !important',
                  height:'2rem'
                },
                '& .MuiDataGrid-columnHeader': {
                  borderLeft: '1px solid rgba(224, 224, 224, 1)',
                  '&:last-child': {
                    borderRight: 'none',
                  },
                },
                '& .MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 600,
                  color: '#3e3e3e'
                },
                '& .MuiDataGrid-row': {
                  '&:last-child .MuiDataGrid-cell': {
                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                  },
                  '& .MuiDataGrid-cell:last-child': {
                    borderRight: 'none',
                  },
                },
                '& .MuiDataGrid-columnSeparator': {
                  display: 'none',
                },
              }}
            />
          </Box>
        </Box>
      </>
  )
}