import { Close } from '@mui/icons-material';
import { Box, Card, Dialog, DialogContent, IconButton, Paper, Typography } from '@mui/material';
import React, { useState } from 'react';
import DefaultMale from '../../assets/images/default_customer_male.jpg'
import DefaultFemale from '../../assets/images/default_customer_female.jpg'
import { Helpers } from '../../services/Helpers';

const BookingHistory = ({bookingList,onClose}) => {
  // console.log('customer details:',bookingList)
  return (
    <Dialog fullWidth open={bookingList}>
        <Card sx={{display:'flex',justifyContent:'space-between',alignItems:'center',p:'1% 2%',borderBottomLeftRadius:0,borderBottomRightRadius:0}}>
            <Typography sx={{fontWeight:600,fontSize:'larger',color:'#464166'}}>Booking History</Typography>
            <IconButton size='small' color='error' onClick={()=>onClose()}><Close/></IconButton>
        </Card>
      <DialogContent>     
        <Box sx={{my:'2%'}}>
          {/* <Typography sx={{fontWeight:'600',my:'1%'}}>Booking List <Box component={'span'} sx={{alignSelf:'right'}}>:</Box></Typography> */}
          <Paper sx={{p:'2%',textAlign:'center'}}>
              {/* <Box sx={{display:'flex',alignItems:'flex-end'}}>
                <Typography fontWeight={'bold'} sx={{width:'30%',color:'#778899',fontSize:'smaller'}}>Name</Typography>
                <Typography fontWeight={'bold'} sx={{mr:'1%'}}>:</Typography>
                <Typography fontWeight={'medium'} sx={{fontSize:'small',textTransform:'capitalize'}}>{bookingList.profile.firstName}{' '}{bookingList.profile.middleName}{' '}{bookingList.profile.lastName}</Typography>    
              </Box>
              <Box sx={{display:'flex',alignItems:'flex-end'}}>
                <Typography fontWeight={'bold'} sx={{width:'30%',color:'#778899',fontSize:'smaller'}}>Contact</Typography>
                <Typography fontWeight={'bold'} sx={{mr:'1%'}}>:</Typography>
                <Typography fontWeight={'medium'} sx={{fontSize:'small'}}>{bookingList.mobileNumber}</Typography>         
              </Box> */}
              <>{bookingList.length=='0'&&'No Booking History'}</>
          </Paper>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default BookingHistory;
