// import { commonRequest } from "../../services/ApiCall";
// import { Helpers } from "../../services/Helpers";

import { commonRequest } from "../../services/ApiCall";
import { Helpers } from "../../services/Helpers";

export const ProviderServices = {
    categoryList : async (data)=>{
        return await commonRequest("get",  `${Helpers.URL()}/api/admin/service-category/getCategories`,{}, Helpers.token());
    },
    
    subCategoryList : async (data)=>{
        return await commonRequest("get",  `${Helpers.URL()}/api/admin/service-category/getSubCategories/${data.categoryId}`,{}, Helpers.token());
    },

    serviceList: async (data)=>{
        return await commonRequest("get",  `${Helpers.URL()}/api/admin/service-master/getServicesBySubcategory/${data.subCategoryId}`,{}, Helpers.token());
    },

    createUserProfile: async(data) =>{
        return await commonRequest("POST",  `${Helpers.URL()}/api/admin/user/addUserProfile`,data, Helpers.token());
    },

    updateUserBusinessDetail: async(data) =>{
        return await commonRequest("POST",  `${Helpers.URL()}/api/admin/user/addUpdateBusinessDetails`,data, Helpers.token());
    },

    updateUserServiceDetail: async(data) =>{
        return await commonRequest("POST",  `${Helpers.URL()}/api/admin/provider-services/`,data, Helpers.token());
    },

    // updateUserBankDetail: async(data) =>{
    //     return await commonRequest("POST",  `${Helpers.URL()}/api/bank-detail`,data, Helpers.token());
    // },

    updateTimeSlot: async(data) =>{
        return await commonRequest("POST",  `${Helpers.URL()}/api/admin/provider-time-slots/`,data, Helpers.token());
    },

    fetchProviderServices: async(data) =>{
        return await commonRequest("GET",  `${Helpers.URL()}/api/admin/provider-services/list?page=${data.page}&limit=${data.pageSize}&providerId=${data.providerId}&searchKey=`,{}, Helpers.token());
    },
    fetchProviderServiceDetailsById: async(data) =>{
        return await commonRequest("GET",  `${Helpers.URL()}/api/admin/provider-services/providerServiceDetails/${data.serviceId}`,{}, Helpers.token());
    },
    updateProviderServices: async(data) =>{
        return await commonRequest("PUT",  `${Helpers.URL()}/api/admin/provider-services/1`,data, Helpers.token());
    },
    deleteProviderService: async(serviceId) =>{
        return await commonRequest("DELETE",  `${Helpers.URL()}/api/admin/provider-services/${serviceId}`,{}, Helpers.token());
    },
    fetchProviderTimeSlots: async(providerId) =>{
        return await commonRequest("GET",  `${Helpers.URL()}/api/admin/provider-time-slots/${providerId}`,{}, Helpers.token());
    },

    fetchProviderDetailsById: async(providerId) =>{
        return await commonRequest("GET",  `${Helpers.URL()}/api/admin/user?userId=${providerId}`,{}, Helpers.token());
    },

    fetchProviderList: async(data) =>{
        return await commonRequest("GET",  `${Helpers.URL()}/api/admin/user/list?page=${data.page}&limit=${data.limit}&searchKey=${data.filters.searchKey}&sortBy=createdAt&sortType=DESC`,{}, Helpers.token());
    },
    createStorefront: async(data) =>{
        return await commonRequest("POST",  `${Helpers.URL()}/api/admin/storefront/createNewStorefront`,data, Helpers.token());
    },   
    updateStorefrontData: async(data,id) =>{
        return await commonRequest("PUT",  `${Helpers.URL()}/api/admin/storefront/updateStorefront/${id}`,data, Helpers.token());
    },
    modifyReviewStatus: async(data) =>{
        return await commonRequest("POST",  `${Helpers.URL()}/api/admin/user/changeReviewStatus`,data, Helpers.token());
    },
    modifyAccountStatus: async(providerId) =>{
        return await commonRequest("GET",  `${Helpers.URL()}/api/admin/user/changeUserStatus/${providerId}`,{}, Helpers.token());
    },
}