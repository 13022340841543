import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Box, Card, Stack, Typography, Button, Modal, Grid, Divider, Paper, TextField } from '@mui/material';
import { isMobile } from 'react-device-detect';
// import AddCircleIcon from '@mui/icons-material/AddCircle';
// import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';
// import Swal from 'sweetalert2';
import { useDispatch } from "react-redux";
import PersonalDetails from './PersonalDetail';
// import BankDetails from './BankDetails';
// import AddressDetails from './AddressDetails';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomerService } from './Service';
import Swal from 'sweetalert2';
import Layout from '../../components/Layout/Layout';

const style = {
    position: 'absolute',
    top: (isMobile) ? '80%' : '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: (isMobile) ? "95%" : "25%",
    bgcolor: 'background.paper',
    border: '2px solid #ddd',
    boxShadow: 24,
    p: 2,
};

export default function CustomerProfile() {
    const { id } = useParams();
    const menuDispatch = useDispatch();
    const navigate =useNavigate();
    menuDispatch({
        type: 'menuSelect',
        payload: 'customerList'
    });

    const [personalDetails, setPersonalDetails] = useState({});

    const [loading, setLoading] = useState(false)
    const [userId, setUserId] = useState(0);

    const fetchUserDetails = async () => {
        try {
            setLoading(true)
            const resUser = await CustomerService.fetchCUstomerDetailById(id);
            if (resUser?.success) {
                const userData = resUser.data;
                setPersonalDetails({
                    id: userData.id,
                    first_name: userData.first_name,
                    last_name: userData.last_name,
                    gender: userData.gender,
                    mobile_number: userData.mobile_number,
                    email: userData.email,
                    gstIn: userData.gstIn,
                    profile_image: userData.profile_image,
                    role: userData.role
                });
                setUserId(userData.id)
            } else {
                Swal.fire('Failed to fetch userDetail','','error')
                navigate(-1)
            }
        } catch (error) {
            Swal.fire(error.message,'','error')
            navigate(-1)
        }
        setLoading(false)
    };

    useEffect(() => {
        if (id > 0) {
            setUserId(id);
            fetchUserDetails();
        }
    }, [id]);

    const handlePersonalSubmit = (data) => {
        setPersonalDetails(data);
    };

    return (
        <Layout pageName={userId>0 ? 'Edit Customer':'Add Customer'}>         
            <Box sx={{ marginTop: 1 }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} lg={12}>
                        <Paper elevation={3} style={{ padding: 16 }}>
                            <PersonalDetails userIdProp={id} personalDetails={personalDetails} onSubmitPersonal={handlePersonalSubmit} />
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </Layout>
    );
};



