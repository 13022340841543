import { Close } from '@mui/icons-material';
import { Box, Card, Dialog, DialogContent, IconButton, Paper, Typography } from '@mui/material';
import React, { useState } from 'react';
import DefaultMale from '../../assets/images/default_customer_male.jpg'
import DefaultFemale from '../../assets/images/default_customer_female.jpg'
import { Helpers } from '../../services/Helpers';

const ViewProfile = ({customerDetails,onClose}) => {
  // console.log('customer details:',customerDetails)
  return (
    <Dialog fullWidth open>
        <Card sx={{display:'flex',justifyContent:'space-between',alignItems:'center',p:'1% 2%',borderBottomLeftRadius:0,borderBottomRightRadius:0}}>
            <Typography sx={{fontWeight:600,fontSize:'larger',color:'#464166'}}>Customer Profile</Typography>
            <IconButton size='small' color='error' onClick={()=>onClose()}><Close/></IconButton>
        </Card>
      <DialogContent>
        <Box sx={{display:'flex',gap:'2%',}}>
          <Box sx={{width:'25%'}}>
            <img
              src={customerDetails.profile.profileImage?`${Helpers.ImagePathUrl()}/sm/${customerDetails.profile.profileImage}`
                : customerDetails.profile.gender == 'Male'?DefaultMale:DefaultFemale
              }
              // alt='customer_profile_image'
              style={{
                // width:'100%',
                borderRadius:'7rem',
                width: '8rem',
                height: '8rem',
              }}
            />
          </Box>
          <Box sx={{width:'75%'}}>
            <Typography sx={{fontWeight:'600',my:'1%'}}>Basic Detail's <Box component={'span'} sx={{alignSelf:'right'}}>:</Box></Typography>
            <Paper sx={{p:'2%',width:'100%'}}>
                <Box sx={{display:'flex',alignItems:'flex-end'}}>
                  <Typography fontWeight={'bold'} sx={{width:'30%',color:'#778899',fontSize:'smaller'}}>Name</Typography>
                  <Typography fontWeight={'bold'} sx={{mr:'1%'}}>:</Typography>
                  <Typography fontWeight={'medium'} sx={{fontSize:'small',textTransform:'capitalize'}}>{customerDetails.profile.firstName}{' '}{customerDetails.profile.middleName}{' '}{customerDetails.profile.lastName}</Typography>    
                </Box>
                <Box sx={{display:'flex',alignItems:'flex-end'}}>
                  <Typography fontWeight={'bold'} sx={{width:'30%',color:'#778899',fontSize:'smaller'}}>Gender</Typography>
                  <Typography fontWeight={'bold'} sx={{mr:'1%'}}>:</Typography>
                  <Typography fontWeight={'medium'} sx={{fontSize:'small'}}>{customerDetails.profile.gender}</Typography>         
                </Box>
                <Box sx={{display:'flex',alignItems:'flex-end'}}>
                  <Typography fontWeight={'bold'} sx={{width:'30%',color:'#778899',fontSize:'smaller'}}>Contact</Typography>
                  <Typography fontWeight={'bold'} sx={{mr:'1%'}}>:</Typography>
                  <Typography fontWeight={'medium'} sx={{fontSize:'small'}}>{customerDetails.mobileNumber}</Typography>         
                </Box>
            </Paper>
          </Box>
        </Box>
        
        <Box sx={{my:'2%'}}>
          <Typography sx={{fontWeight:'600',my:'1%'}}>Address Details <Box component={'span'} sx={{alignSelf:'right'}}>:</Box></Typography>
          <Paper sx={{p:'2%'}}>
          <Typography fontWeight={'medium'} sx={{fontSize:'small',textTransform:'capitalize'}}>{customerDetails?.customerAllAddress&&customerDetails.customerAllAddress.length>0 && customerDetails.customerAllAddress[0].address}</Typography>
              {/* <Box sx={{display:'flex',alignItems:'flex-end'}}>
                <Typography fontWeight={'bold'} sx={{width:'30%',color:'#778899',fontSize:'smaller'}}>Name</Typography>
                <Typography fontWeight={'bold'} sx={{mr:'1%'}}>:</Typography>
                <Typography fontWeight={'medium'} sx={{fontSize:'small',textTransform:'capitalize'}}>{customerDetails.profile.firstName}{' '}{customerDetails.profile.middleName}{' '}{customerDetails.profile.lastName}</Typography>    
              </Box>
              <Box sx={{display:'flex',alignItems:'flex-end'}}>
                <Typography fontWeight={'bold'} sx={{width:'30%',color:'#778899',fontSize:'smaller'}}>Contact</Typography>
                <Typography fontWeight={'bold'} sx={{mr:'1%'}}>:</Typography>
                <Typography fontWeight={'medium'} sx={{fontSize:'small'}}>{customerDetails.mobileNumber}</Typography>         
              </Box> */}
          </Paper>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default ViewProfile;
