import { Box, Card } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import BookingList from "./BookingList";
import Layout from "../../components/Layout/Layout";

export default function Booking() {
    const menuDispatch = useDispatch();
    // menuDispatch({
    //     type: 'menuSelect',
    //     payload: 'bookings'
    // });
    useEffect(() => {
        menuDispatch({
            type: 'menuSelect',
            payload: 'bookings'
        });
    }, []);
    return (
        <Layout pageName={'Manage Bookings'}>
            <BookingList/>
        </Layout>
    )
}
