// import React, { useEffect, useState, useMemo } from 'react';
// import { Autocomplete, Box, Button, CircularProgress, Grid, TextField, Typography } from '@mui/material';
// import { useFormContext, Controller } from 'react-hook-form';
// import LocationOnIcon from '@mui/icons-material/LocationOn';
// import parse from 'autosuggest-highlight/parse';
// import { debounce } from '@mui/material/utils';

// const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

// function loadScript(src, position, id) {
//   if (!position) {
//     return;
//   }

//   const script = document.createElement('script');
//   script.setAttribute('async', '');
//   script.setAttribute('id', id);
//   script.src = src;
//   position.appendChild(script);
// }

// const CoordinateDetails = ({ prefix }) => {
//   const { control, setValue } = useFormContext();
//   const [inputValue, setInputValue] = useState('');
//   const [options, setOptions] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [scriptLoaded, setScriptLoaded] = useState(false);
//   const loaded = React.useRef(false);

//   useEffect(() => {
//     if (typeof window !== 'undefined' && !loaded.current) {
//       if (!document.querySelector('#google-maps')) {
//         loadScript(
//           `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
//           document.querySelector('head'),
//           'google-maps',
//         );
//       }

//       loaded.current = true;
//     }
//   }, []);

//   useEffect(() => {
//     if (!window.google) {
//       const timer = setInterval(() => {
//         if (window.google) {
//           setScriptLoaded(true);
//           clearInterval(timer);
//         }
//       }, 100);

//       return () => clearInterval(timer);
//     } else {
//       setScriptLoaded(true);
//     }
//   }, []);

//   const autocompleteService = useMemo(() => {
//     if (scriptLoaded && window.google) {
//       return new window.google.maps.places.AutocompleteService();
//     }
//     return null;
//   }, [scriptLoaded]);

//   const placesService = useMemo(() => {
//     if (scriptLoaded && window.google) {
//       return new window.google.maps.places.PlacesService(document.createElement('div'));
//     }
//     return null;
//   }, [scriptLoaded]);

//   const fetch = useMemo(
//     () =>
//       debounce((request, callback) => {
//         if (autocompleteService) {
//           autocompleteService.getPlacePredictions(request, callback);
//         }
//       }, 400),
//     [autocompleteService],
//   );

//   useEffect(() => {
//     let active = true;

//     if (inputValue === '') {
//       setOptions([]);
//       return undefined;
//     }

//     setLoading(true);

//     fetch({ input: inputValue }, (results) => {
//       if (active) {
//         setOptions(results || []);
//         setLoading(false);
//       }
//     });

//     return () => {
//       active = false;
//     };
//   }, [inputValue, fetch]);

//   const handlePlaceSelect = (place) => {
//     if (!place || !place.place_id || !placesService) return;

//     placesService.getDetails(
//       { placeId: place.place_id, fields: ['address_components', 'geometry', 'formatted_address'] },
//       (placeResult, status) => {
//         if (status === window.google.maps.places.PlacesServiceStatus.OK) {
//           const addressComponents = placeResult.address_components;
//           const lat = placeResult.geometry.location.lat();
//           const lng = placeResult.geometry.location.lng();

//           setValue(`${prefix}.address`, placeResult.formatted_address);
//           setValue(`${prefix}.latitude`, lat.toString());
//           setValue(`${prefix}.longitude`, lng.toString());
//           setValue(`${prefix}.state`, addressComponents.find(c => c.types.includes('administrative_area_level_1'))?.long_name || '');
//           setValue(`${prefix}.city`, addressComponents.find(c => c.types.includes('locality'))?.long_name || '');
//           setValue(`${prefix}.pincode`, addressComponents.find(c => c.types.includes('postal_code'))?.long_name || '');
//         }
//       }
//     );
//   };


//   return (
//     <Box>
//       <Controller
//         name={`${prefix}.address`}
//         control={control}
//         render={({ field: { onChange, value }, fieldState: { error } }) => (
//           <Autocomplete
//             id={`${prefix}-address-autocomplete`}
//             sx={{ width: '100%' }}
//             size='small'
//             getOptionLabel={(option) =>
//               typeof option === 'string' ? option : option.description
//             }
//             filterOptions={(x) => x}
//             options={options}
//             autoComplete
//             includeInputInList
//             filterSelectedOptions
//             value={value}
//             onChange={(event, newValue) => {
//               onChange(newValue ? newValue.description : '');
//               handlePlaceSelect(newValue);
//             }}
//             onInputChange={(event, newInputValue) => {
//               setInputValue(newInputValue);
//             }}
//             renderInput={(params) => (
//               <TextField
//                 {...params}
//                 label="Address"
//                 fullWidth
//                 error={!!error}
//                 helperText={error?.message}
//               />
//             )}
//             renderOption={(props, option) => {
//               const matches = option.structured_formatting.main_text_matched_substrings || [];
//               const parts = parse(
//                 option.structured_formatting.main_text,
//                 matches.map((match) => [match.offset, match.offset + match.length]),
//               );

//               return (
//                 <li {...props}>
//                   <Grid container alignItems="center">
//                     <Grid item>
//                       <Box component={LocationOnIcon} sx={{ color: 'text.secondary', mr: 2 }} />
//                     </Grid>
//                     <Grid item xs>
//                       {parts.map((part, index) => (
//                         <span
//                           key={index}
//                           style={{
//                             fontWeight: part.highlight ? 700 : 400,
//                           }}
//                         >
//                           {part.text}
//                         </span>
//                       ))}
//                       <Typography variant="body2" color="text.secondary">
//                         {option.structured_formatting.secondary_text}
//                       </Typography>
//                     </Grid>
//                   </Grid>
//                 </li>
//               );
//             }}
//           />
//         )}
//       />
      
//       {/* Other fields (latitude, longitude, pincode) remain unchanged */}
//       <Box sx={{ display: 'flex', gap: '2%', my: '1%' }}>
//         <Box sx={{ flex: 1 }}>
//           <Typography sx={{ fontWeight: 'bold' }}>Pincode<span style={{color:'red'}}>*</span></Typography>
//           <Controller
//             name={`${prefix}.pincode`}
//             control={control}
//             render={({ field, fieldState: { error } }) => (
//               <TextField
//                 {...field}
//                 fullWidth
//                 type='text'
//                 size='small'
//                 InputProps={{ inputProps: { style: { textTransform: "uppercase" } } }}
//                 error={!!error}
//               />
//             )}
//           />
//         </Box>
//         <Box sx={{ flex: 1 }}>
//           <Typography sx={{ fontWeight: 'bold' }}>Latitude<span style={{color:'red'}}>*</span></Typography>
//           <Controller
//             name={`${prefix}.latitude`}
//             control={control}
//             render={({ field, fieldState: { error } }) => (
//               <TextField
//                 {...field}
//                 fullWidth
//                 type='number'
//                 size='small'
//                 InputProps={{ inputProps: { style: { textTransform: "uppercase" } } }}
//                 error={!!error}
//               />
//             )}
//           />
//         </Box>
//         <Box sx={{ flex: 1 }}>
//           <Typography sx={{ fontWeight: 'bold' }}>Longitude<span style={{color:'red'}}>*</span></Typography>
//           <Controller
//             name={`${prefix}.longitude`}
//             control={control}
//             render={({ field, fieldState: { error } }) => (
//               <TextField
//                 {...field}
//                 size='small'
//                 fullWidth
//                 type='number'
//                 error={!!error}
//               />
//             )}
//           />
//         </Box>
//       </Box>
//     </Box>
//   );
// }

// export default CoordinateDetails;

import React, { useEffect, useState, useMemo } from 'react';
import { Autocomplete, Box, TextField, Typography, Grid } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import parse from 'autosuggest-highlight/parse';
import { debounce } from '@mui/material/utils';

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const CoordinateDetails = ({ prefix }) => {
  const { control, setValue } = useFormContext();
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [googleServicesReady, setGoogleServicesReady] = useState(false);
  const loaded = React.useRef(false);

  // Load Google Maps script
  useEffect(() => {
    if (typeof window !== 'undefined' && !loaded.current) {
      if (!document.querySelector('#google-maps')) {
        loadScript(
          `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
          document.querySelector('head'),
          'google-maps',
        );
      }
      loaded.current = true;
    }
  }, []);

  // Check if Google services are ready
  useEffect(() => {
    const checkGoogleServices = setInterval(() => {
      if (window.google && window.google.maps && window.google.maps.places) {
        setGoogleServicesReady(true);
        clearInterval(checkGoogleServices);
      }
    }, 100);

    return () => clearInterval(checkGoogleServices);
  }, []);

  const services = useMemo(() => {
    if (!googleServicesReady) return { autocomplete: null, places: null };
    
    return {
      autocomplete: new window.google.maps.places.AutocompleteService(),
      places: new window.google.maps.places.PlacesService(document.createElement('div'))
    };
  }, [googleServicesReady]);

  const fetch = useMemo(
    () =>
      debounce((request, callback) => {
        if (services.autocomplete) {
          services.autocomplete.getPlacePredictions(request, callback);
        }
      }, 400),
    [services.autocomplete],
  );

  useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions([]);
      return undefined;
    }

    if (!services.autocomplete) {
      return undefined;
    }

    setLoading(true);

    fetch({ input: inputValue }, (results) => {
      if (active) {
        setOptions(results || []);
        setLoading(false);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetch, services.autocomplete]);

  const handlePlaceSelect = (place) => {
    if (!place || !place.place_id || !services.places) return;

    services.places.getDetails(
      { placeId: place.place_id, fields: ['address_components', 'geometry', 'formatted_address'] },
      (placeResult, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          const addressComponents = placeResult.address_components;
          const lat = placeResult.geometry.location.lat();
          const lng = placeResult.geometry.location.lng();

          setValue(`${prefix}.address`, placeResult.formatted_address,{shouldValidate: true,shouldDirty: true, });
          setValue(`${prefix}.latitude`, lat.toString(),{shouldValidate: true,shouldDirty: true, });
          setValue(`${prefix}.longitude`, lng.toString(),{shouldValidate: true,shouldDirty:true, });
          setValue(`${prefix}.state`, addressComponents.find(c => c.types.includes('administrative_area_level_1'))?.long_name || '',{shouldValidate: true,shouldDirty: true, });
          setValue(`${prefix}.city`, addressComponents.find(c => c.types.includes('locality'))?.long_name || '',{shouldValidate: true,shouldDirty: true, });
          setValue(`${prefix}.pincode`, addressComponents.find(c => c.types.includes('postal_code'))?.long_name || '',{shouldValidate: true,shouldDirty: true, });
        }
      }
    );
  };

  return (
    <Box>
  <Controller
    name={`${prefix}.address`}
    control={control}
    render={({ field: { onChange, value }, fieldState: { error } }) => (
      <Autocomplete
        id={`${prefix}-address-autocomplete`}
        sx={{ width: '100%' }}
        size='small'
        getOptionLabel={(option) =>
          typeof option === 'string' ? option : option.description
        }
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        loading={loading}
        onChange={(event, newValue) => {
          onChange(newValue ? newValue.description : '');
          handlePlaceSelect(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Address"
            fullWidth
            error={!!error}
            helperText={error?.message}
          />
        )}
        renderOption={(props, option) => {
          const matches = option.structured_formatting.main_text_matched_substrings || [];
          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match) => [match.offset, match.offset + match.length]),
          );

          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item>
                  <Box component={LocationOnIcon} sx={{ color: 'text.secondary', mr: 2 }} />
                </Grid>
                <Grid item xs>
                  {parts.map((part, index) => (
                    <span
                      key={index}
                      style={{
                        fontWeight: part.highlight ? 700 : 400,
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                  <Typography variant="body2" color="text.secondary">
                    {option.structured_formatting.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
    )}
  />
  
  <Box sx={{ display: 'flex', gap: '2%', my: '1%' }}>
    <Box sx={{ flex: 1 }}>
      <Typography sx={{ fontWeight: 'bold' }}>Pincode<span style={{color:'red'}}>*</span></Typography>
      <Controller
        name={`${prefix}.pincode`}
        control={control}
        rules={{ 
          required: 'Pincode is required',
          pattern: {
            value: /^[0-9]{6}$/,
            message: 'Invalid pincode'
          }
        }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            fullWidth
            size='small'
            type='number'
            InputProps={{ 
              inputProps: { 
                style: { 
                  textTransform: "uppercase" 
                },
                onWheel: (e) => e.target.blur(),
                onKeyDown: (e) => {
                  if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                      e.preventDefault();
                  }
                } 
              } 
            }}
            error={!!error}
            helperText={error?.message}
            onChange={(e) => {
              let finalValue = e.target.value;
              if (finalValue.length > 6) {
                finalValue = finalValue.slice(0, 6);                    
              } 
              field.onChange(finalValue);
            }}
          />
        )}
      />
    </Box>
    <Box sx={{ flex: 1 }}>
      <Typography sx={{ fontWeight: 'bold' }}>Latitude<span style={{color:'red'}}>*</span></Typography>
      <Controller
        name={`${prefix}.latitude`}
        control={control}
        rules={{
          required: 'Latitude is required',
          validate: {
            validLatitude: (value) => {
              const num = parseFloat(value);
              if (isNaN(num)) return 'Must be a number';
              if (num < -90 || num > 90) return 'Must be between -90 and 90';
              return true;
            }
          }
        }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            fullWidth
            type='number'
            size='small'
            InputProps={{ 
              inputProps: { 
                step: "0.000001",
                min: -90,
                max: 90
              } 
            }}
            error={!!error}
            helperText={error?.message}
          />
        )}
      />
    </Box>
    <Box sx={{ flex: 1 }}>
      <Typography sx={{ fontWeight: 'bold' }}>Longitude<span style={{color:'red'}}>*</span></Typography>
      <Controller
        name={`${prefix}.longitude`}
        control={control}
        rules={{
          required: 'Longitude is required',
          validate: {
            validLongitude: (value) => {
              const num = parseFloat(value);
              if (isNaN(num)) return 'Must be a number';
              if (num < -180 || num > 180) return 'Must be between -180 and 180';
              return true;
            }
          }
        }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            size='small'
            fullWidth
            type='number'
            InputProps={{ 
              inputProps: { 
                step: "0.000001",
                min: -180,
                max: 180
              } 
            }}
            error={!!error}
            helperText={error?.message}
          />
        )}
      />
    </Box>
  </Box>
</Box>
  );
};

export default CoordinateDetails;